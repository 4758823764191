import { Image } from 'react-bootstrap';
import logo from '../assets/img/runQL-logo.svg';
import Footer from '../components/Footer';
import { ThemeContext } from '../components/ThemeContext';
import { ReactNode, useContext, useEffect, useState } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';

const PublicPage = ({ children }: { children: ReactNode }): React.JSX.Element => {
   const themeContext = useContext(ThemeContext);
   const [isDarkMode, setIsDarkMode] = useState(themeContext?.mode === 'dark');

   useEffect(() => {
      setIsDarkMode(themeContext?.mode === 'dark');
   }, [themeContext]);

   const handleToggle = () => {
      if (themeContext && themeContext.toggleMode) {
         themeContext.toggleMode();
      }
      setIsDarkMode((prevState) => !prevState);
   };

   return (
      <>
         <header className="header navbar navbar-expand-lg bg-light border-bottom-line shadow-sm fixed-top">
            <div className="container-fluid fs-sm">
               <Link className="navbar-brand pe-3" to={'/'}>
                  <Image alt="runQL Logo" src={logo} width="70" />
               </Link>
               <div className="form-check form-switch mode-switch pe-lg-1 ms-4">
                  <input
                     checked={isDarkMode}
                     className="form-check-input"
                     id="theme-mode"
                     onChange={(e): void => {
                        handleToggle();
                        const html = document.getElementsByTagName('html')[0];
                        if (e.target.checked) {
                           html.classList.add('dark-mode');
                           window.localStorage.setItem('mode', 'dark');
                        } else {
                           html.classList.remove('dark-mode');
                           window.localStorage.setItem('mode', 'light');
                        }
                     }}
                     type="checkbox"
                  />
               </div>
            </div>
         </header>
         <section className="position-relative h-100 mt-5 p-2 p-md-5 d-flex justify-content-center align-items-start">
            <div>{children}</div>
         </section>
         <Footer />
      </>
   );
};

export default PublicPage;
