import { useEffect, useState } from 'react';
import { useInjection } from 'inversify-react';
import { useMutation } from 'react-query';
import { Button, Col, Spinner, Stack, Row } from 'react-bootstrap';

import { findParams, QueryVersion, QueryToken } from '../entities';
import { QueryService } from '../services';
import { TYPES } from '../types';

export const RunSource = ({
   queryVersion,
   readOnly,
}: {
   queryVersion: QueryVersion;
   readOnly?: boolean;
}) => {
   const queryService = useInjection<QueryService>(TYPES.queryService);
   const [token, setToken] = useState<QueryToken | undefined>(queryVersion.query?.token);

   useEffect(() => {
      setToken(queryVersion.query?.token);
   }, [queryVersion.query?.token]);

   const { isLoading, mutateAsync } = useMutation({
      mutationFn: async (query: QueryVersion) => {
         return await queryService.postToken(query);
      },
      onSuccess: async (ret) => {
         setToken(ret);
      },
   });

   const params = findParams(queryVersion?.steps ?? []);
   const host = `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? ':' + window.location.port : ''
   }`;
   const unsaved = !queryVersion?.query?.latestVersion?.version;

   return (
      <Stack className="fs-12p" gap={2}>
         <div>
            runSource is a virtual query service that acts as a bridge between your database(s) and
            services that need data (BI Dashboards, etc).{' '}
            <a className="text-decoration-none" href="/documentation#runSource" target="_blank">
               Read more
            </a>
            .
         </div>
         <div>
            {unsaved && <i>Save this query to enable runSource.</i>}
            {!readOnly && !unsaved && !token && (
               <Button disabled={isLoading} onClick={() => mutateAsync(queryVersion)} size="sm">
                  {isLoading ? (
                     <Spinner
                        animation="border"
                        aria-hidden="true"
                        as="span"
                        role="status"
                        size="sm"
                     />
                  ) : (
                     <span>Generate runSource</span>
                  )}
               </Button>
            )}
            {readOnly && !unsaved && !token && (
               <i>Ask someone with edit access to generate a runSource link.</i>
            )}
            {token && (
               <>
                  <code>
                     SELECT * FROM runQL WHERE runSource='{host}/source/{token.token}'
                     {params.map((p) => ` AND ${p.name}=?`)};
                  </code>
                  <Row>
                     <Col className="col-3">Host:</Col>
                     <Col>runsource.runql.com</Col>
                  </Row>
                  <Row>
                     <Col className="col-3">Type:</Col>
                     <Col>MySQL</Col>
                  </Row>
                  <Row>
                     <Col className="col-3">Database:</Col>
                     <Col>runQL</Col>
                  </Row>
                  <Row>
                     <Col className="col-3">Port:</Col>
                     <Col>3306</Col>
                  </Row>
               </>
            )}
         </div>
      </Stack>
   );
};

export default RunSource;
