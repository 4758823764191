import { useMemo } from 'react';
import {
   createBrowserRouter,
   createRoutesFromElements,
   useParams,
   Route,
   Navigate,
   RouterProvider,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { FeatureProvider } from './hooks/feature';
import Authed from './Authed';
import Layout from './Layout';
import {
   AccountsListPage,
   AuthRedirect,
   BusinessPage,
   ChangePasswordPage,
   CompliancePage,
   DataConnectionsPage,
   DocumentationPage,
   Error404,
   Error50x,
   Extension,
   LogListPage,
   OrgSettingsPage,
   OverviewPage,
   PasswordResetPage,
   QueryListPage,
   QueryReview,
   QueryUpdates,
   ResetPasswordPage,
   SignInPage,
   SignUpPage,
   SnippetsPage,
   SourceTokenRedirect,
   WorkspaceListPage,
   WorkspacePage,
} from './pages';
import { ExtensionContextProvider } from './hooks/extension';
import DataConnectionDetailsPage from './pages/DataConnections/DataConnectionDetailsPage';
import DataConnectionMetaPage from './pages/DataConnections/DataConnectionMetaPage';
import { useTheme } from './components/ThemeContext';

const redirects = {
   '/': '/home',
   '/queries': '/home/queries',
   '/snippets': '/home/snippets',
   '/logs': '/home/logs',
   '/workspace/:workspaceId/query/:queryId': '/workspaces/:workspaceId/query/:queryId',
   '/workspace/:workspaceId/:tabId': '/workspaces/:workspaceId',
   '/accounts': '/admin/accounts',
   '/settings': '/admin/settings',
   '/compliance': '/admin/compliance',
};

const Redirect = ({ to }: { to: string }) => {
   const params = useParams();
   return (
      <Navigate
         replace
         to={to.replace(/:([^/]+)/g, (match, param) => {
            return params[param] || match;
         })}
      />
   );
};

const App = (): JSX.Element => {
   const theme = useTheme();

   const extension = useMemo(
      () => ({
         extension: window !== window.parent || window.location.pathname === '/x',
         openTab: (url: string) => {
            window.open(url, '_blank');
         },
      }),
      []
   );

   const router = createBrowserRouter(
      createRoutesFromElements(
         <>
            <Route element={<Authed />} errorElement={<Error50x />}>
               <Route element={<Layout />} errorElement={<Error50x />}>
                  {/* Home */}
                  <Route element={<OverviewPage />} path="/home" />
                  <Route element={<QueryListPage />} path="/home/queries" />
                  <Route element={<SnippetsPage />} path="/home/snippets" />
                  <Route element={<LogListPage />} path="/home/logs" />

                  {/* Workspaces */}
                  <Route element={<WorkspaceListPage />} path="/workspaces" />
                  <Route element={<WorkspacePage />} path="/workspaces/:workspaceId" />
                  <Route
                     element={<WorkspacePage />}
                     path="/workspaces/:workspaceId/query/:queryId"
                  />
                  <Route
                     element={<WorkspacePage />}
                     path="/workspaces/:workspaceId/table/:tableSchemaId"
                  />

                  <Route element={<QueryReview />} path="/query-review" />

                  <Route element={<QueryUpdates />} path="/query-updates" />

                  {/* Data Sources */}
                  <Route element={<DataConnectionsPage />} path="/sources" />
                  <Route element={<DataConnectionMetaPage />} path="/sources/meta" />
                  <Route
                     element={<DataConnectionDetailsPage />}
                     path="/sources/:dataConnectionId"
                  />

                  <Route element={<DocumentationPage />} path="/documentation" />

                  {/* Admin */}
                  <Route element={<AccountsListPage />} path="/admin/accounts" />
                  <Route element={<OrgSettingsPage />} path="/admin/settings" />
                  <Route element={<CompliancePage />} path="/admin/compliance" />

                  {/* Other */}
                  <Route element={<ChangePasswordPage />} path="/change-password" />
                  <Route element={<BusinessPage />} path="/answers" />

                  {/* Redirects */}
                  <Route element={<SourceTokenRedirect />} path="/source/:token" />
                  {Object.entries(redirects).map(([from, to]) => (
                     <Route element={<Redirect to={to} />} key={from} path={from} />
                  ))}
               </Route>
               <Route element={<Extension />} path="/x" />
            </Route>
            <Route element={<SignInPage />} path="/signin" />
            <Route element={<SignUpPage />} path="/signup" />
            <Route element={<PasswordResetPage />} path="/reset" />
            <Route element={<ResetPasswordPage />} path="/p/:link" />
            <Route element={<SignUpPage />} path="/invite/:link" />
            <Route element={<AuthRedirect provider="google" />} path="/x/google" />
            <Route element={<AuthRedirect provider="ms" />} path="/x/ms" />
            <Route element={<Error404 />} path="*" />
         </>
      )
   );
   return (
      <>
         <ToastContainer
            pauseOnFocusLoss
            pauseOnHover
            position="bottom-right"
            stacked
            theme={theme}
         />
         <FeatureProvider>
            <ExtensionContextProvider value={extension}>
               <RouterProvider router={router} />
            </ExtensionContextProvider>
         </FeatureProvider>
      </>
   );
};

export default App;
