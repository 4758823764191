import React from 'react';

export const Error404 = (): JSX.Element => {
   return (
      <>
         <div className="pt-xl-5 pt-lg-4 pt-md-3 pb-lg-5 pb-md-4 pb-3 container-fluid">
            <div className="d-flex align-items-center row">
               <div className="col-12">
                  <div className="text-center pt-5 pb-3 mt-auto">
                     <div className="parallax parallax-custom-1 mx-auto">
                        <div
                           className="parallax-layer parallax-layer-custom-1"
                           data-depth="-0.15"
                        ></div>
                        <div
                           className="parallax-layer parallax-layer-custom-2"
                           data-depth="0.12"
                        ></div>
                        <div
                           className="parallax-layer parallax-layer-custom-3 zindex-5"
                           data-depth="-0.12"
                        ></div>
                     </div>

                     <h1 className="visually-hidden">404</h1>
                     <h2 className="display-5">Whoa!</h2>
                     <p className="pb-3 pb-md-0 mb-md-5">
                        It appears you've stumbled upon a digital mirage – a 404 oasis in the vast
                        desert of data!
                        <br />
                        Don't worry, it's just a pesky little hiccup in your data journey.
                     </p>
                     <a className="btn btn-lg btn-primary shadow-primary w-sm-auto w-100" href="/">
                        Go to Workspaces
                     </a>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
};

export default Error404;
