import { useInjection } from 'inversify-react';
import { useEffect, useState } from 'react';
import { Alert, Button, Card, Col, Row, Spinner, Stack } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { Navigate } from 'react-router-dom';
import AddAccountModal from '../../components/AddAccountModal';
import { AdminNav, Page } from '../../components';
import LoadingError from '../../components/UI/LoadingError';
import LoadingSpinner from '../../components/UI/LoadingSpinner';
import SearchInput from '../../components/UI/SearchInput';
import { Org, Person, planNames } from '../../entities';
import { ADMIN_ROLES, PersonRole, QueryKey } from '../../enums';
import { useListInviteQuery } from '../../hooks';
import { useGetAuthorizedExplorerQuery } from '../../hooks/accountHooks';
import { OrgService, PersonService, SubscriptionService } from '../../services';
import { TYPES } from '../../types';
import { handleError, useTitle } from '../../utilities';
import AccountCard from './AccountCard';
import UpgradeModal from './UpgradeModal';

export const AccountsListPage = (): JSX.Element => {
   useTitle('Accounts');

   const [searchText, setSearchText] = useState('');
   const [showAddModal, setShowAddModal] = useState(false);
   const [showUpgradeModal, setShowUpgradeModal] = useState(false);
   const [seats, setSeats] = useState(0);

   const orgService = useInjection<OrgService>(TYPES.orgService);
   const personService = useInjection<PersonService>(TYPES.personService);
   const subscriptionService = useInjection<SubscriptionService>(TYPES.subscriptionService);

   const orgs = useQuery<Org[]>([QueryKey.Org, 'list'], () =>
      orgService.list().catch((err) => {
         handleError(err);
         return [];
      })
   );
   const org = orgs.data?.[0];
   const plan = org?.plan;
   const freePlan = plan === 0;
   const authedExplorer = useGetAuthorizedExplorerQuery();
   const personFilters = {
      includeWorkspaces: true,
   };
   const accounts = useQuery<Person[]>(
      [QueryKey.Person, 'list', personFilters],
      () =>
         personService.listOptions(personFilters).catch((err) => {
            handleError(err);
            return [];
         }),
      {
         keepPreviousData: true,
         refetchOnWindowFocus: false,
         refetchOnMount: true,
         retry: false,
         onError(err) {
            handleError(err);
         },
      }
   );
   useEffect(() => {
      subscriptionService.getSeats().then((seats) => {
         setSeats(seats);
      });
   }, [subscriptionService]);
   const pendingInviteQuery = useListInviteQuery({});

   //Page Functions
   const filterConnectionsByText = (value: Person, index: number, array: Person[]) => {
      if (value.firstName && value.firstName.toLowerCase().includes(searchText)) {
         return true;
      }

      if (value.lastName && value.lastName.toLowerCase().includes(searchText)) {
         return true;
      }

      if (value.email && value.email.toLowerCase().includes(searchText)) {
         return true;
      }

      if (value.role && value.role.toLowerCase().includes(searchText)) {
         return true;
      }
   };

   //Page Markup
   if (authedExplorer.isLoading) {
      return <LoadingSpinner />;
   }

   if (authedExplorer.isError) {
      // redirect non admin users
      return <Navigate to="/" />;
   }
   let connectionMarkup: JSX.Element[] | JSX.Element | undefined;
   if (accounts.isLoading) {
      connectionMarkup = <LoadingSpinner />;
   } else if (accounts.isError) {
      connectionMarkup = LoadingError({});
   } else {
      connectionMarkup = accounts.data?.filter(filterConnectionsByText).map((account) => {
         return (
            <AccountCard
               account={account}
               key={account.id}
               readonly={
                  !ADMIN_ROLES.includes(authedExplorer.data?.person.role ?? PersonRole.ORG_EXPLORER)
               }
            />
         );
      });
   }
   const isAdmin = ADMIN_ROLES.includes(
      authedExplorer.data?.person.role ?? PersonRole.ORG_EXPLORER
   );

   return (
      <Page
         header={
            <Stack className="justify-content-between w-100" direction="horizontal" gap={2}>
               <Stack direction="horizontal" gap={2}>
                  <div className="flex-shrink-0">Admin</div>
               </Stack>

               <Button onClick={() => setShowAddModal(true)} size="sm">
                  Add Account
               </Button>
            </Stack>
         }
         nav={<AdminNav />}
         tabs={[
            {
               title: 'Accounts',
               content: (
                  <Stack gap={2}>
                     <Alert>
                        {plan === undefined && <Spinner className="spinner-border-sm" />}
                        {plan !== undefined && (
                           <>
                              <b>{planNames[plan]} Plan</b>
                              {seats === undefined ? '' : ` (${seats} seats)`}
                              {isAdmin && (
                                 <Button
                                    className={`ms-4 ${freePlan ? '' : 'btn-secondary'}`}
                                    onClick={() => setShowUpgradeModal(true)}
                                    size="sm"
                                 >
                                    {freePlan ? 'Upgrade' : 'Change Plan'}
                                 </Button>
                              )}
                           </>
                        )}
                     </Alert>
                     <SearchInput
                        entityName="accounts"
                        onTextChanged={(newText) => setSearchText(newText.toLowerCase())}
                     />
                     {/* Table headers */}
                     <Row>
                        <Col xs={12}>
                           <Card className="custom-card-transparent border-0 px-3 pb-1 pt-1 fs-10p">
                              <Row className="">
                                 <Col xs={2}>Name</Col>
                                 <Col xs={2}>Email</Col>
                                 <Col xs={1}>Role</Col>
                                 <Col xs={4}>Workspaces</Col>
                                 <Col xs={1}>Added</Col>
                              </Row>
                           </Card>
                        </Col>
                     </Row>
                     {connectionMarkup}
                  </Stack>
               ),
            },
            {
               title: 'Invitations',
               content: (
                  <Stack gap={2}>
                     <Card className="custom-card-transparent border-0 px-3 fs-10p">
                        <Row className="">
                           <Col xs={2}>Name</Col>
                           <Col xs={2}>Email</Col>
                           <Col xs={1}>Role</Col>
                           <Col xs={2}>Workspaces</Col>
                           <Col xs={2}>Invited By</Col>
                           <Col xs={1}>Invited</Col>
                           <Col xs={1}>Expires</Col>
                        </Row>
                     </Card>
                     {pendingInviteQuery.isLoading ? (
                        <LoadingSpinner />
                     ) : pendingInviteQuery.isError ? (
                        <LoadingError />
                     ) : (
                        pendingInviteQuery.data &&
                        pendingInviteQuery.data.items.map((invite) => (
                           <AccountCard
                              account={invite.person}
                              invite={invite}
                              key={invite.id}
                              readonly={
                                 !ADMIN_ROLES.includes(
                                    authedExplorer.data?.person.role ?? PersonRole.ORG_EXPLORER
                                 )
                              }
                           />
                        ))
                     )}
                  </Stack>
               ),
            },
         ]}
      >
         <AddAccountModal
            admin={ADMIN_ROLES.includes(
               authedExplorer.data?.person.role ?? PersonRole.ORG_EXPLORER
            )}
            onClose={() => setShowAddModal(false)}
            onSubmitSuccess={() => setShowAddModal(false)}
            show={showAddModal}
         />
         <UpgradeModal onClose={() => setShowUpgradeModal(false)} show={showUpgradeModal} />
      </Page>
   );
};

export default AccountsListPage;
