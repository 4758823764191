import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect } from 'react';
import { Form, OverlayTrigger } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import LoadingError from '../../../../components/UI/LoadingError';
import LoadingSpinner from '../../../../components/UI/LoadingSpinner';
import { ConnectionAccessType } from '../../../../entities';
import { ADMIN_ROLES, DBMS } from '../../../../enums';
import { useGetAuthorizedExplorerQuery, useGetDataConnectionQuery } from '../../../../hooks';
import { getErrorMessage, IconInformation } from '../../../../utilities';
import { connectionName, description } from '../validators';
import { tooltipSharedConnection } from './ConnectionDetailsForm';
import HideDetailsLabel from './HideDetailsLabel';

export const databricksDetailSchema = z.object({
   dbms: z.literal(DBMS.Databricks),
   connectionName: connectionName,
   description: description,
   host: z.string().min(1, 'Required'),
   path: z.string().min(1, 'Required'),
   authToken: z.string().min(1, 'Required'),
   sharedConnection: z.boolean().default(false),
   hideDetails: z.boolean().default(false),
});

export type DatabricksDetailFormData = z.infer<typeof databricksDetailSchema>;

function DatabricksDetailForm({
   editType,
   formId,
   onSaveStateChange,
   onSubmit,
   selectedConnectionId,
}: {
   editType: 'connection' | 'credential' | 'read-only';
   formId: string;
   onSaveStateChange?: (state: 'clean' | 'dirty') => void;
   onSubmit?: (data: DatabricksDetailFormData) => void;
   selectedConnectionId?: number;
}) {
   // register form
   const { handleSubmit, register, formState, reset } = useForm<DatabricksDetailFormData>({
      resolver: zodResolver(databricksDetailSchema),
      mode: 'onTouched',
   });

   const errors = formState.errors;
   const touchedFields = formState.touchedFields;

   // State variables
   const [explorerIsAdmin, setExplorerIsAdmin] = React.useState(false);

   // Queries
   const selectedConnectionQuery = useGetDataConnectionQuery({ id: selectedConnectionId });
   const authPersonQuery = useGetAuthorizedExplorerQuery();

   // Effects
   useEffect(() => {
      //User is adding credentials, load parent connection data
      if (selectedConnectionQuery.data) {
         const formData: DatabricksDetailFormData = {
            dbms: DBMS.Databricks,
            connectionName: selectedConnectionQuery.data.name ?? '',
            description: selectedConnectionQuery.data.description ?? '',
            host: selectedConnectionQuery.data.dbHost ?? '',
            path: selectedConnectionQuery.data.dbName ?? '',
            authToken: 'CURRENT',
            sharedConnection:
               selectedConnectionQuery.data.connectionAccessType === ConnectionAccessType.SHARED,
            hideDetails:
               (selectedConnectionQuery.data.hideDetails as unknown as number) === 1 ? true : false,
         };
         reset(formData);
      }
   }, [selectedConnectionQuery.data, reset]);

   useEffect(() => {
      if (
         authPersonQuery.data?.person.role &&
         ADMIN_ROLES.includes(authPersonQuery.data.person.role)
      ) {
         setExplorerIsAdmin(true);
      } else {
         setExplorerIsAdmin(false);
      }
   }, [authPersonQuery.data?.person.role]);

   useEffect(() => {
      const isDirtyAlt = !!Object.keys(formState.dirtyFields).length;
      if (isDirtyAlt) {
         onSaveStateChange?.('dirty');
      } else {
         onSaveStateChange?.('clean');
      }
   }, [formState, onSaveStateChange]);

   // Page functions
   const handleOnSubmit = (data: DatabricksDetailFormData) => {
      onSubmit?.(data);
   };

   // Render
   if (authPersonQuery.isLoading || selectedConnectionQuery.isLoading) return <LoadingSpinner />;
   if (authPersonQuery.isError)
      return <LoadingError message={getErrorMessage(authPersonQuery.error)} />;
   if (selectedConnectionQuery.isError)
      return <LoadingError message={getErrorMessage(selectedConnectionQuery.error)} />;

   return (
      <Form id={formId} onSubmit={handleSubmit(handleOnSubmit)}>
         <input type="hidden" {...register('dbms')} value={DBMS.Databricks} />
         <Form.Group className="mb-3">
            <Form.Label>
               Connection Name <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
               {...register('connectionName')}
               disabled={editType !== 'connection'}
               isInvalid={touchedFields.connectionName && !!errors.connectionName}
               isValid={touchedFields.connectionName && !errors.connectionName}
               placeholder="Connection Name"
               required
            />
            <Form.Control.Feedback type="invalid">
               {errors.connectionName?.message}
            </Form.Control.Feedback>
         </Form.Group>
         <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
               {...register('description')}
               as="textarea"
               disabled={editType !== 'connection'}
               isInvalid={touchedFields.description && !!errors.description}
               isValid={touchedFields.description && !errors.description}
               placeholder="Description"
               rows={3}
            />
            <Form.Control.Feedback type="invalid">
               {errors.description?.message}
            </Form.Control.Feedback>
         </Form.Group>
         <Form.Group className="mb-3">
            <Form.Label>
               Host <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
               {...register('host')}
               disabled={editType !== 'connection'}
               isInvalid={touchedFields.host && !!errors.host}
               isValid={touchedFields.host && !errors.host}
               placeholder="Host"
               required
            />
            <Form.Control.Feedback type="invalid">{errors.host?.message}</Form.Control.Feedback>
         </Form.Group>
         <Form.Group className="mb-3">
            <Form.Label>
               Path <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
               {...register('path')}
               disabled={editType !== 'connection'}
               isInvalid={touchedFields.path && !!errors.path}
               isValid={touchedFields.path && !errors.path}
               placeholder="Path"
               required
            />
            <Form.Control.Feedback type="invalid">{errors.path?.message}</Form.Control.Feedback>
         </Form.Group>
         <Form.Group className="mb-3">
            <Form.Label>
               Auth Token <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
               {...register('authToken')}
               disabled={editType === 'read-only'}
               isInvalid={touchedFields.authToken && !!errors.authToken}
               isValid={touchedFields.authToken && !errors.authToken}
               placeholder="Auth Token"
               required
            />
            <Form.Control.Feedback type="invalid">
               {errors.authToken?.message}
            </Form.Control.Feedback>
         </Form.Group>
         {explorerIsAdmin && (
            <>
               <Form.Group>
                  <Form.Label>
                     Shared Connection
                     <OverlayTrigger overlay={tooltipSharedConnection} placement="auto">
                        <span>
                           <IconInformation size={16} />
                        </span>
                     </OverlayTrigger>
                  </Form.Label>
                  <Form.Check
                     {...register('sharedConnection')}
                     disabled={editType !== 'connection'}
                     type="switch"
                  />
               </Form.Group>
               <Form.Group className="mb-s">
                  <HideDetailsLabel />
                  <Form.Check
                     {...register('hideDetails')}
                     disabled={editType !== 'connection'}
                     type="switch"
                  />
               </Form.Group>
            </>
         )}
      </Form>
   );
}

export default DatabricksDetailForm;
