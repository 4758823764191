import { z } from 'zod';
import { TEXT_MAX_LENGTH } from '../../../utilities';

export const connectionName = z
   .string()
   .min(1, 'Required')
   .min(3, 'Must be at least 3 characters')
   .max(255, 'Must be less than 256 characters')
   .trim();

export const description = z
   .string()
   .max(TEXT_MAX_LENGTH, `Description must be less than ${TEXT_MAX_LENGTH + 1} characters`)
   .trim()
   .optional();

export const host = z.string().min(1, 'Required').max(500, 'Must be less than 500 characters');

export const port = z
   .number({
      errorMap: (issue, ctx) => {
         if (issue.code === 'invalid_type') {
            if (ctx.data.toString().length === 0) {
               return { message: 'Required' };
            } else {
               return {
                  message: 'Must be a number',
               };
            }
         }
         return { message: ctx.defaultError };
      },
   })
   .min(0, 'Must be positivie');

export const username = z.string().min(1, 'Required').max(255, 'Must be less than 256 characters');

export const password = z.string().min(1, 'Required');
