import { inject, injectable } from 'inversify';
import { PaginatedResult, QueryLog } from '../entities';
import { TYPES } from '../types';
import { ApiService } from './ApiService';
import { ApiServiceInterface } from './ApiServiceInterface';

const path = '/v1/queryLog';

export interface ListOptionsQueryLog {
   exploreTabId?: number;
   includeCreatedPerson?: boolean;
   includeDataSource?: boolean;
   myQueries?: boolean;
   newestFirst?: boolean;
   page?: number;
   pageSize?: number;
   query?: string;
   queryId?: number;
   skip?: number;
   sort?: string;
   take?: number;
   workspaceId?: number;
}
export interface GetOptionsQueryLog {}

@injectable()
export class QueryLogService implements ApiServiceInterface<QueryLog> {
   private apiService: ApiService;

   constructor(@inject(TYPES.apiService) apiService: ApiService) {
      this.apiService = apiService;
   }

   listOptions(options?: ListOptionsQueryLog): Promise<PaginatedResult<QueryLog>> {
      const params: Record<string, string> = {};
      if (options !== undefined) {
         for (const k in options) {
            const v = options[k as keyof typeof options];
            if (v) {
               params[k] = v.toString();
            }
         }
      }
      return this.list(params);
   }

   patch(
      id: string | number,
      body: QueryLog,
      params?: Record<string, string> | undefined
   ): Promise<QueryLog | undefined> {
      throw new Error('Method not implemented.');
   }

   post(body: QueryLog, params?: unknown): Promise<QueryLog | undefined> {
      throw new Error('Method not implemented.');
   }

   async put(
      _body: QueryLog,
      _params?: Record<string, string> | undefined
   ): Promise<QueryLog | undefined> {
      throw new Error('Method not implemented.');
   }

   delete(id: string | number): Promise<null> {
      throw new Error('Method not implemented.');
   }

   get(id: string | number): Promise<QueryLog | undefined> {
      throw new Error('Method not implemented.');
   }

   async list(params: Record<string, string>): Promise<PaginatedResult<QueryLog>> {
      const result = await this.apiService.get<PaginatedResult<QueryLog>>(path, params);
      if (!result) {
         return { items: [], totalItems: 0 };
      }
      return result;
   }

   // Whether the current account has ever run a query in this workspace.
   async hasRun(workspaceIdNum: number): Promise<boolean> {
      const workspaceId = workspaceIdNum.toString();
      try {
         return (await this.apiService.get<boolean>(`${path}/hasRun`, { workspaceId })) ?? false;
      } catch (e: any) {
         return false;
      }
   }
}
