import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, Row, Stack } from 'react-bootstrap';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { z } from 'zod';
import { LoadingSpinner } from '../../../../components';
import LoadingError from '../../../../components/UI/LoadingError';
import {
   useExploreTab,
   useGetTableDataQuery,
   useRunSystemQuery,
   useUpdateSchemaMutation,
} from '../../../../hooks';
import { SelectOption } from '../../../../interfaces';
import {
   buildChangeQueries,
   ClientDataChange,
   getDataTypesAsOptions,
   handleError,
   IconCheck,
   MySQLIndexModify,
} from '../../../../utilities';
import { ConfirmChangesModal } from './TableTabContent';
import { TypeSelect } from '../../../../components/TypeSelect';
import { DBMS } from '@runql/util';

const tableIndexSchema = z.object({
   id: z.number(),
   tableName: z.string(),
   indexes: z.array(
      z.object({
         runqlId: z.number().optional(), // Optional if not present during creation
         catalogName: z.string().nullable().optional(),
         columnNames: z.string().min(1),
         dataConnectionId: z.number(),
         indexName: z.string().min(1),
         indexUnique: z.boolean(),
         indexType: z.string().optional(),
         isNew: z.boolean().default(false),
         isPrimary: z.boolean().default(false),
         markDeleted: z.boolean().default(false),
         schemaName: z.string().min(1),
         tableName: z.string().min(1),
         type: z.literal('index'),
         visible: z.boolean().optional(),
      })
   ),
});

export type TableIndexFormData = z.infer<typeof tableIndexSchema>;

export function SchemaIndexTab({
   onStatusChange,
   readOnly = false,
}: {
   onStatusChange?: (isDirty: boolean) => void;
   readOnly?: boolean;
}) {
   const [changeCount, setChangeCount] = useState(0);
   const [showConfirmModal, setShowConfirmModal] = useState(false);
   const [changeQueries, setChangeQueries] = useState<string[]>([]);
   const { run, isRunning } = useRunSystemQuery();
   const [, setOptions] = useState<SelectOption[]>();
   const {
      handleSubmit,
      register,
      control,
      reset,
      formState: { isDirty, dirtyFields, isSubmitted, errors },
   } = useForm<TableIndexFormData>({
      resolver: zodResolver(tableIndexSchema),
      mode: 'onTouched',
      defaultValues: {},
   });

   const { fields, append, update, remove } = useFieldArray({
      control,
      name: 'indexes',
   });

   const previousIsDirty = useRef(isDirty);

   //Context
   const exploreTab = useExploreTab();

   // Queries
   const { tableDataQuery, modals, refresh } = useGetTableDataQuery({
      tableSchemaId: exploreTab?.tableSchemaId,
      workspaceId: exploreTab?.workspaceId,
      exploreTabId: exploreTab?.id,
   });
   const dbms = tableDataQuery.data?.tableCache.dataConnection.dbms;
   const updateSchemaMutator = useUpdateSchemaMutation({
      onSuccessCallback(data, dataConnectionId, context) {
         refresh();
      },
   });

   // Effects
   useEffect(() => {
      if (!tableDataQuery.data) return;

      // set options
      setOptions(getDataTypesAsOptions(tableDataQuery.data.tableCache.dataConnection.dbms));

      // load form data
      const formData: TableIndexFormData = {
         id: tableDataQuery.data.tableCache.id!,
         tableName: tableDataQuery.data.tableCache.tableName,
         indexes:
            tableDataQuery.data.indexes?.map((index) => {
               return {
                  catalogName: index.catalogName,
                  columnNames: JSON.stringify(index?.columnName.split(',')),
                  dataConnectionId: index.dataConnectionId,
                  indexName: index.indexName,
                  indexUnique: !index.indexNonUnique,
                  indexType: index.indexType?.toUpperCase(),
                  isPrimary: index.columnIsPrimaryKey ?? false,
                  schemaName: index.schemaName,
                  tableName: index.tableName,
                  type: 'index',
                  visible: Boolean(index?.visible),
                  runqlId: index.id,
                  markDeleted: false,
                  isNew: false,
               };
            }) ?? [],
      };
      reset(formData);
   }, [reset, tableDataQuery.data]);

   useEffect(() => {
      if (previousIsDirty.current !== isDirty) {
         previousIsDirty.current = isDirty;
         onStatusChange?.(isDirty);
      }
   }, [isDirty, onStatusChange]);

   function parseStringToArray(str: string): string[] | undefined {
      try {
         const parsed = JSON.parse(str);
         if (Array.isArray(parsed) && parsed.every((item) => typeof item === 'string')) {
            return parsed;
         } else {
            return undefined;
         }
      } catch (e) {
         return undefined;
      }
   }
   // Handlers
   function handleSave(data: TableIndexFormData) {
      if (dirtyFields.indexes === undefined) {
         handleError('no indexes');
         return;
      }
      if (tableDataQuery.data?.tableCache.tableName === undefined) {
         handleError('Table name is missing');
         return;
      }
      const tableChanges: ClientDataChange = {
         type: 'modifyIndex',
         catalog: tableDataQuery.data?.tableCache.catalogName,
         table: tableDataQuery.data?.tableCache.tableName,
         schema: tableDataQuery.data?.tableCache.schemaName ?? '',
         indexes: dirtyFields.indexes.reduce<Record<string, MySQLIndexModify>>((acc, value, id) => {
            const index = data.indexes[id];
            const originalIndex = tableDataQuery.data?.indexes?.find(
               (col) => col.id === index.runqlId
            );
            if (!index) {
               handleError('Index not found');
               return acc;
            }
            if (!index.columnNames || index.columnNames.length === 0) {
               handleError('IndexColumns cannot be empty.');
               return acc;
            }
            const indexName = originalIndex?.indexName ?? index.indexName;
            const modify: MySQLIndexModify = {
               columnNames: parseStringToArray(index.columnNames) ?? [],
            };
            let hasChanges = false;
            if (value.indexName && originalIndex?.indexName !== index.indexName) {
               modify.rename = index.indexName;
               hasChanges = true;
            }
            if (value.indexType && originalIndex?.indexType !== index.indexType) {
               modify.indexType = index.indexType;
               hasChanges = true;
            }
            if (
               value.columnNames &&
               JSON.stringify(
                  originalIndex?.columnName?.split(',').map((item) => item.trim()) ?? []
               ) !== JSON.stringify(index.columnNames ?? '')
            ) {
               // Allows for us to compare the two arrays
               // Stringify works well on indexes since usually small number of cols
               // Order also matters
               modify.columnNames = parseStringToArray(index.columnNames) ?? [];
               hasChanges = true;
            }
            if (index.isNew) {
               modify.isNew = true;
               hasChanges = true;
            }
            if (index.markDeleted) {
               modify.delete = true;
               hasChanges = true;
            }
            modify.unique = index.indexUnique;
            modify.primary = index.isPrimary;
            if (hasChanges) {
               acc[indexName] = modify;
            }
            return acc;
         }, {}),
      };

      if (!tableDataQuery.data?.tableCache.dataConnection.dbms) {
         handleError('No DBMS found');
         return;
      }
      const queries = buildChangeQueries(
         [tableChanges],
         [],
         tableDataQuery.data?.tableCache.dataConnection.dbms
      );
      setChangeQueries(queries);
      setChangeCount(queries.length);
      setShowConfirmModal(true);
   }

   async function handleConfirmSave(): Promise<void> {
      if (changeQueries.length === 0) return;
      if (exploreTab?.tableSchema?.dataConnectionId === undefined) {
         handleError('No data connection found');
         return;
      }
      try {
         await run({
            query: changeQueries.join('\n'),
            dataConnectionId: exploreTab.tableSchema.dataConnectionId,
            exploreTabId: exploreTab.id,
            workspaceId: exploreTab.workspaceId,
            updateSchema: true,
         });
         await refresh();
         setShowConfirmModal(false);
      } catch (error) {
         handleError(error);
         return;
      }

      toast.success('Changes saved successfully');
   }

   function handleIndexRemove(index: number): void {
      if (fields[index].isNew) {
         remove(index);
      } else {
         update(index, {
            ...fields[index],
            markDeleted: !fields[index].markDeleted,
         });
      }
   }

   // Render
   if (!exploreTab) return <LoadingError />;
   if (tableDataQuery.isLoading || updateSchemaMutator.isLoading) return <LoadingSpinner />;
   if (tableDataQuery.data === undefined) return <div>No data</div>;

   return (
      <div className="container h-100">
         <ConfirmChangesModal
            changeCount={changeCount}
            onClose={() => {
               setShowConfirmModal(false);
            }}
            onConfirm={handleConfirmSave}
            query={changeQueries}
            running={isRunning}
            show={showConfirmModal}
         />
         <Form className="h-100" onSubmit={handleSubmit(handleSave)}>
            <Stack className="h-100">
               <Stack
                  className="container-fluid mt-3 mb-3 justify-content-end"
                  direction="horizontal"
                  gap={1}
               >
                  <Button
                     className="btn btn-sm btn-secondary"
                     onClick={async () => {
                        const dataConnectiohId = tableDataQuery.data?.tableCache.dataConnectionId;
                        if (!dataConnectiohId) {
                           handleError('No data connection found');
                           return;
                        }
                        await updateSchemaMutator.mutateAsync({
                           dataConnectionId: dataConnectiohId,
                        });
                     }}
                  >
                     Refresh
                  </Button>
                  {!readOnly && (
                     <>
                        <Button
                           className="btn btn-sm btn-secondary"
                           onClick={() => {
                              append({
                                 runqlId: 0,
                                 catalogName: '',
                                 columnNames: '',
                                 dataConnectionId:
                                    tableDataQuery.data?.indexes[0]?.dataConnectionId ??
                                    exploreTab?.tableSchema?.dataConnectionId ??
                                    0,
                                 indexName: `new_index_${fields.length}`,
                                 indexUnique: true,
                                 indexType: dbms === DBMS.MySQL ? 'BTREE' : undefined,
                                 isNew: true,
                                 isPrimary: false,
                                 markDeleted: false,
                                 schemaName:
                                    tableDataQuery.data?.indexes[0]?.schemaName ??
                                    exploreTab?.tableSchema?.schemaName ??
                                    '',
                                 tableName:
                                    tableDataQuery.data?.indexes[0]?.tableName ??
                                    exploreTab?.tableSchema?.tableName ??
                                    '',
                                 type: 'index',
                                 visible: true,
                              });
                           }}
                        >
                           Add
                        </Button>
                        <Button
                           className="btn btn-sm btn-secondary"
                           disabled={!isDirty}
                           onClick={() => {
                              reset();
                           }}
                        >
                           Cancel
                        </Button>
                        <Button
                           className="btn btn-sm btn-primary"
                           disabled={!isDirty}
                           type="submit"
                        >
                           Apply Changes
                        </Button>
                     </>
                  )}
               </Stack>
               {/* Print column headers */}
               {fields.length > 0 && (
                  <div className="p-3 pb-0 pt-1 fs-10p">
                     <Row className="justify-content-center">
                        <Col xs={1}>
                           <span>Id</span>
                        </Col>
                        <Col xs={2}>
                           <span>Name</span>
                        </Col>
                        {dbms === DBMS.MySQL && (
                           <Col xs={1}>
                              <span>Type</span>
                           </Col>
                        )}
                        <Col xs={1}>Unique?</Col>
                        <Col xs={1}>
                           <span>Primary?</span>
                        </Col>
                        <Col xs={5}>
                           <span>Columns</span>
                        </Col>
                        <Col xs={1}>{/* Actions */}</Col>
                     </Row>
                  </div>
               )}

               <div className="flex-grow-1 overflow-auto">
                  {fields.map((index, id) => (
                     <Card
                        className={`border-1 mt-2 mb-2 p-3 shadow-sm fs-14p ${
                           index.markDeleted ? 'bg-danger text-white' : ''
                        }`}
                        key={index.id}
                     >
                        <Row className="justify-content-center">
                           <Col className="pt-2" xs={1}>
                              {id}
                           </Col>
                           <Col xs={2}>
                              <Form.Control
                                 {...register(`indexes.${id}.indexName`)}
                                 isInvalid={isSubmitted && !!errors?.indexes?.[id]?.indexName}
                                 isValid={isSubmitted && !errors?.indexes?.[id]?.indexName}
                                 readOnly={readOnly}
                              />
                           </Col>
                           {dbms === DBMS.MySQL && (
                              <Col xs={1}>
                                 <Form.Control {...register(`indexes.${id}.indexType`)} readOnly />
                              </Col>
                           )}
                           <Col className="position-relative" xs={1}>
                              {index.isNew ? (
                                 <Form.Check
                                    {...register(`indexes.${id}.indexUnique`)}
                                    type="switch"
                                 />
                              ) : index.indexUnique ? (
                                 <div
                                    style={{
                                       position: 'absolute',
                                       left: '25%',
                                       top: '50%',
                                       transform: 'translateY(-50%)',
                                    }}
                                 >
                                    <IconCheck />
                                 </div>
                              ) : (
                                 <></>
                              )}
                           </Col>
                           <Col className="position-relative" xs={1}>
                              {index.isPrimary && (
                                 <div
                                    style={{
                                       position: 'absolute',
                                       left: '25%',
                                       top: '50%',
                                       transform: 'translateY(-50%)',
                                    }}
                                 >
                                    <IconCheck />
                                 </div>
                              )}
                           </Col>
                           <Col xs={5}>
                              <Controller
                                 control={control}
                                 name={`indexes.${id}.columnNames`}
                                 render={({ field }) => (
                                    <TypeSelect
                                       isInvalid={
                                          isSubmitted && !!errors?.indexes?.[id]?.columnNames
                                       }
                                       onChange={field.onChange}
                                       onSelect={(value) => {
                                          field.onChange(`["${value}"]`);
                                       }}
                                       options={
                                          tableDataQuery?.data?.columns?.map(
                                             (column) => column.columnName
                                          ) ?? []
                                       }
                                       placeHolder="Add index columns..."
                                       value={field.value}
                                    />
                                 )}
                              />
                           </Col>
                           <Col className="d-flex align-items-center" xs={1}>
                              {!readOnly && (
                                 <Button
                                    className="btn btn-sm"
                                    onClick={() => handleIndexRemove(id)}
                                    variant="danger"
                                 >
                                    X
                                 </Button>
                              )}
                           </Col>
                        </Row>
                     </Card>
                  ))}
                  {fields.length === 0 && (
                     <div className="align-self-center flex">
                        No indexes to display. Click Add to create a new index.
                     </div>
                  )}
               </div>
            </Stack>
         </Form>
         {modals}
      </div>
   );
}
