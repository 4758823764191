import React, { useState } from 'react';
import { BiXCircle } from 'react-icons/bi';

export const TypeSelect = ({
   allowCustom,
   disabled,
   isInvalid,
   onChange,
   onSelect,
   options,
   placeHolder,
   value,
}: {
   allowCustom?: boolean;
   disabled?: boolean;
   isInvalid?: boolean;
   onChange?: (value: string) => void;
   onSelect?: (value: string) => void;
   options: string[];
   placeHolder?: string;
   value?: string | undefined;
}): JSX.Element => {
   allowCustom = allowCustom ?? false;
   value = value ?? '';
   const [showDropdown, setShowDropdown] = useState(false);
   const [isDropdownClicked, setIsDropdownClicked] = useState(false);

   const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(e.target.value);
      setShowDropdown(true);
   };

   const handleOptionClick = (option: string) => {
      if (onSelect) onSelect(option);
      else onChange?.(option);
      setShowDropdown(false);
   };

   const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      if (isDropdownClicked) {
         setIsDropdownClicked(false);
         return;
      }

      setShowDropdown(false);
   };

   return (
      <div className="d-flex flex-column h-100 position-relative" style={{ fontSize: '12px' }}>
         <div className="d-flex align-items-center position-relative">
            <div className="position-relative w-100">
               <input
                  className={`typeselect-input-field ${isInvalid ? 'error' : 'normal'}`}
                  disabled={disabled}
                  onBlur={handleBlur}
                  onChange={handleInputChange}
                  onFocus={() => setShowDropdown(true)}
                  placeholder={placeHolder}
                  readOnly={!allowCustom}
                  type="text"
                  value={value}
               />
               {isInvalid && <BiXCircle className="typeselect-error-icon" size="20px" />}
            </div>
         </div>
         {showDropdown && options.length > 1 && (
            <div>
               <div className="typeselect-dropdown">
                  {options.map((option) => (
                     <div
                        className="p-2"
                        key={option}
                        onMouseDown={() => {
                           setIsDropdownClicked(true);
                           handleOptionClick(option);
                        }} // Use onMouseDown instead of onClick
                        style={{ cursor: 'pointer' }}
                     >
                        {option}
                     </div>
                  ))}
               </div>
            </div>
         )}
      </div>
   );
};

export default TypeSelect;
