import { Stack, Button, Spinner } from 'react-bootstrap';
import { HiSparkles } from 'react-icons/hi2';

import { AiPulse, AiSparkles, InlineInput } from '../components';
import { QueryVersion } from '../entities';

const ButtonSpinner = () => (
   <>
      &nbsp;
      <Spinner animation="border" aria-hidden="true" as="span" role="status" size="sm" />
      &nbsp;
   </>
);

export const QueryDocs = ({
   generating,
   onChangeDocumentation,
   onClickGenerateDocumentation,
   queryVersion,
   readOnly,
}: {
   generating?: boolean;
   onChangeDocumentation?: (params: {
      description?: string;
      question?: string;
      title?: string;
   }) => void;
   onClickGenerateDocumentation?: React.MouseEventHandler<HTMLButtonElement>;
   queryVersion: QueryVersion;
   readOnly?: boolean;
}) => {
   return (
      <Stack className="h-100 overflow-auto px-2 fs-12p" gap={4}>
         {readOnly ? null : (
            <div>
               <Button
                  onClick={onClickGenerateDocumentation}
                  size="sm"
                  style={{ minWidth: '174px' }}
                  variant="secondary"
               >
                  {generating ? (
                     <ButtonSpinner />
                  ) : (
                     <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <HiSparkles className="me-2" size={15} />
                        <p style={{ alignSelf: 'center', marginBottom: 0 }}>
                           Generate Documentation
                        </p>
                     </div>
                  )}
               </Button>
            </div>
         )}
         <div>
            <div>
               <div className="fs-12p fw-500">Title</div>
            </div>
            <div className="flex-grow-1">
               <AiPulse on={generating}>
                  <AiSparkles
                     resetOnChange={queryVersion?.queryId}
                     trigger={
                        !!queryVersion?.generatedTitle &&
                        !!queryVersion?.title &&
                        queryVersion?.title !== 'New query'
                     }
                     triggerOnChange={queryVersion?.title}
                  >
                     {(targetRef) => (
                        <div ref={targetRef}>
                           <InlineInput
                              onChange={(title) => {
                                 onChangeDocumentation?.({ title });
                              }}
                              placeholder="Add a title…"
                              readOnly={readOnly}
                              value={queryVersion?.title ?? ''}
                           />
                        </div>
                     )}
                  </AiSparkles>
               </AiPulse>
            </div>
         </div>
         <div>
            <div>
               <div className="fs-12p fw-500">Description</div>
            </div>
            <div className="flex-grow-1">
               <AiPulse on={generating}>
                  <AiSparkles
                     resetOnChange={queryVersion?.queryId}
                     trigger={!!queryVersion?.description && !!queryVersion?.generatedDescription}
                     triggerOnChange={queryVersion?.description}
                  >
                     {(targetRef) => (
                        <div ref={targetRef}>
                           <InlineInput
                              multiLine
                              onChange={(description) => {
                                 onChangeDocumentation?.({ description });
                              }}
                              placeholder="Add a description…"
                              readOnly={readOnly}
                              value={queryVersion?.description ?? ''}
                           />
                        </div>
                     )}
                  </AiSparkles>
               </AiPulse>
            </div>
         </div>
         <div>
            <div>
               <div className="fs-12p fw-500">Question</div>
            </div>
            <div className="flex-grow-1">
               <AiPulse on={generating}>
                  <AiSparkles
                     resetOnChange={queryVersion?.queryId}
                     trigger={!!queryVersion?.question && !!queryVersion?.generatedQuestion}
                     triggerOnChange={queryVersion?.question}
                  >
                     {(targetRef) => (
                        <div ref={targetRef}>
                           <InlineInput
                              multiLine
                              onChange={(question) => {
                                 onChangeDocumentation?.({ question });
                              }}
                              placeholder="Add the question this query answers…"
                              readOnly={readOnly}
                              value={queryVersion?.question ?? ''}
                           />
                        </div>
                     )}
                  </AiSparkles>
               </AiPulse>
            </div>
         </div>
      </Stack>
   );
};

export default QueryDocs;
