import { zodResolver } from '@hookform/resolvers/zod';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { Badge, Button, Form, InputGroup, Stack } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { format } from 'sql-formatter';
import { z } from 'zod';
import { StepType, useOpenQuery } from '../entities';
import {
   useAskRuna,
   useExplorer,
   useExploreTab,
   useExtension,
   useListWorkspaceConnectionsQuery,
} from '../hooks';
import { SQL_TEMPLATE } from '../pages';
import { AskRunaResponse } from '../services';
import { getShortDateTimeString, IconUse } from '../utilities';
import { LoadingSpinner } from './UI';
import CodeViewer from './UI/CodeViewer';
import LoadingError from './UI/LoadingError';

const askRunaSchema = z.object({
   prompt: z.string().min(1),
});

export type AskRunaFormData = z.infer<typeof askRunaSchema>;

const RunaResponse = ({
   explorerName,
   onClick,
   response,
}: {
   explorerName?: string;
   onClick?: () => void;
   response: AskRunaResponse;
}) => {
   return (
      <Stack
         className={classNames('border-0 query-card card pt-3 pb-2 px-2 query-card', {
            'query-card-hover': response.query,
         })}
         gap={0}
         onClick={onClick}
      >
         <Stack>
            {response.query && (
               <div className="query-card-top-right">
                  <Button className="query-card-action border-0 fs-10p" size="sm" variant="white">
                     Use&nbsp;
                     <IconUse size={10} />
                  </Button>
               </div>
            )}

            <div className="d-flex">
               <div className="flex-grow-1">
                  <Stack className="flex-wrap" direction="horizontal" gap={1}>
                     Q: {response.prompt}
                     <Badge bg="info" pill>
                        <div>{response.label}</div>
                     </Badge>
                  </Stack>
                  <div className="justify-content-between">
                     <Stack className="align-items-start" direction="horizontal" gap={1}>
                        <div className="fs-9p text-muted">
                           {getShortDateTimeString(response.timestamp)}
                        </div>
                        <div className="fs-9p text-muted fw-500 potential-badge">
                           {explorerName}
                        </div>
                     </Stack>
                  </div>
               </div>
            </div>
            <div className="fs-11p fw-normal">{response.answer}</div>
            <div className="queryFontSmall cm-editor">
               <div className="card border-0 queryCardCode" style={{ position: 'relative' }}>
                  <CodeViewer query={response.query} />
               </div>
            </div>
         </Stack>
      </Stack>
   );
};

const AskRuna: React.FC = () => {
   const [conversation, setConversation] = React.useState<AskRunaResponse[]>([]);
   const {
      register,
      handleSubmit,
      reset,
      formState: { isValid },
   } = useForm<AskRunaFormData>({
      resolver: zodResolver(askRunaSchema),
      defaultValues: {
         prompt: '',
      },
   });
   const askRuna = useAskRuna();
   const exploreTab = useExploreTab();
   const extension = useExtension();
   const explorer = useExplorer();
   const openQuery = useOpenQuery(extension ? { handler: extension.openTab } : {});

   const workspaceConnectionList = useListWorkspaceConnectionsQuery({
      workspaceId: exploreTab?.workspaceId,
      includeConnectionDetails: true, // only including to match query key from WorkspaceConnectionSelector
   });

   const dataConnectionId = useMemo(() => {
      return (
         exploreTab?.queryVersion?.steps[0]?.dataConnectionId ??
         (workspaceConnectionList.isLoading
            ? undefined
            : workspaceConnectionList?.data?.[0]?.dataConnectionId ?? undefined)
      );
   }, [exploreTab?.queryVersion?.steps, workspaceConnectionList]);

   let explorerName = 'UNKNOWN';
   if (explorer) explorerName = explorer.firstName + ' ' + explorer.lastName;

   // Handlers
   const onSubmit = async (data: AskRunaFormData) => {
      const prompt = data.prompt;
      const currentQuery =
         exploreTab?.queryVersion?.steps[0]?.queryText !== SQL_TEMPLATE
            ? exploreTab?.queryVersion?.steps[0]?.queryText
            : undefined;
      reset();

      const response = await askRuna.mutateAsync({
         prompt,
         previous: conversation,
         dataConnectionId,
         currentQuery,
      });
      if (!response) return;
      setConversation([...conversation, response]);
   };

   const handleUse = (response: AskRunaResponse) => {
      openQuery({
         newTab: false,
         queryVersion: {
            steps: [
               {
                  dataConnectionId: dataConnectionId,
                  queryText: response.query && format(response.query),
                  order: 0,
                  type: StepType.DATA_CONNECTION,
               },
            ],
         },
         source: 'askRuna',
         workspaceId: exploreTab?.workspaceId,
      });
   };

   // Render
   if ((exploreTab?.queryVersion?.steps.length ?? 0) > 1)
      return (
         <div>
            <LoadingError message="Ask Runa is not supported for workflows" />
         </div>
      );
   if (exploreTab?.queryVersion?.steps[0]?.type === StepType.PYTHON) {
      return (
         <div>
            <LoadingError message="Ask Runa is not supported for Python" />
         </div>
      );
   }

   return (
      <Stack className="ask-runa-container" gap={3}>
         <div className="runa-responses">
            <Stack gap={2}>
               {conversation.map((response, index) => (
                  <RunaResponse
                     explorerName={explorerName}
                     key={index}
                     onClick={() => handleUse(response)}
                     response={response}
                  />
               ))}
            </Stack>
         </div>

         <Form onSubmit={handleSubmit(onSubmit)}>
            <InputGroup>
               <Form.Control
                  {...register('prompt')}
                  autoComplete="off"
                  className="small-form-control-input"
                  placeholder="Ask Runa a Question"
               />
               <button
                  className="btn btn-xs btn-primary plausible-event-name--askRuna"
                  disabled={askRuna.isLoading || !isValid}
                  type="submit"
               >
                  {askRuna.isLoading ? <LoadingSpinner centered /> : 'Ask Runa'}
               </button>
            </InputGroup>
         </Form>
      </Stack>
   );
};

export default AskRuna;
