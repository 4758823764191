import { useInjection } from 'inversify-react';
import { useCallback, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { DataCredentialsModal, RunButton } from '../components';
import { QueryRunOptions, QueryVersion, StepType, SystemQueryRun } from '../entities';

import { QueryKey, QueryKeyType } from '../enums';
import { QueryReturn } from '../interfaces';
import {
   GetTableDataResponse,
   QueryService,
   SchemaCacheService,
   hasQueryLocal,
   runQueryLocal,
} from '../services';
import { TYPES } from '../types';
import { handleError, queryIsDangerous } from '../utilities';
import { getQueryLogQueryKey, useFetchListWorkspaceConnections } from './';
import { useCurrentQuery } from '.';

export type RunStatus = {
   isRunning?: boolean;
   results?: QueryReturn[];
};

export const useRunQueries = ({
   exploreTabId,
   onStatusChange,
}: {
   exploreTabId?: number;
   onStatusChange?: (queryVersion: QueryVersion, status: RunStatus) => void;
} = {}) => {
   const [_runStatus, _setRunStatus] = useState<Record<number, RunStatus>>({});
   const { paramOverrides } = useCurrentQuery();

   const runStatus = useCallback(
      (queryVersion: QueryVersion) => _runStatus[queryVersion.query?.id ?? queryVersion.id!],
      [_runStatus]
   );
   const setRunStatus = useCallback(
      (queryVersion: QueryVersion, status: RunStatus) => {
         const id = queryVersion.query?.id ?? queryVersion.id;
         if (!id) return;
         _setRunStatus((runStatus) => ({
            ...runStatus,
            [id]: status,
         }));
         onStatusChange && onStatusChange(queryVersion, status);
      },
      [onStatusChange]
   );
   const [ignoreDangerous, setIgnoreDangerous] = useState(false);
   const [promptDangerousQuery, setPromptDangerousQuery] = useState<{
      queryVersion: QueryVersion;
      step?: number;
      stopAfterStep?: number;
   }>();
   const [showAddCredentials, setShowAddCredentials] = useState<{
      connectionId: number;
      workspaceId: number;
   }>();

   const service = useInjection<QueryService>(TYPES.queryService);
   const queryClient = useQueryClient();
   const runQuery = useMutation({
      mutationFn: async ({
         queryVersion,
         options,
      }: {
         options: QueryRunOptions;
         queryVersion: QueryVersion;
      }) => {
         options = {
            ...options,
            params: paramOverrides,
         };
         if (hasQueryLocal()) {
            return await runQueryLocal(queryVersion.steps, options);
         } else {
            return await service.runQuery(queryVersion.id!, options);
         }
      },
   });

   const fetchWorkspaceConnections = useFetchListWorkspaceConnections();

   const run = useCallback(
      async (
         queryVersion: QueryVersion,
         {
            step: onlyStep,
            stopAfterStep,
            suppressDangerousWarning,
         }: { step?: number; stopAfterStep?: number; suppressDangerousWarning?: boolean } = {}
      ) => {
         if (!queryVersion.id) throw new Error('Query version must be saved before running');
         if (!queryVersion.query?.workspaceId) throw new Error('Query must have a workspace');
         if (runStatus(queryVersion)?.isRunning) return;

         try {
            if (
               !ignoreDangerous &&
               !suppressDangerousWarning &&
               queryVersion.steps.some(
                  (step) => suppressDangerousWarning || queryIsDangerous(step.queryText ?? '')
               )
            ) {
               setPromptDangerousQuery({
                  queryVersion,
                  step: queryVersion.steps.find(
                     (step) => suppressDangerousWarning || queryIsDangerous(step.queryText ?? '')
                  )?.order,
                  stopAfterStep,
               });
               return;
            }

            for (const step of queryVersion.steps) {
               if (onlyStep !== undefined && step.order !== onlyStep) continue;
               if (stopAfterStep !== undefined && step.order > stopAfterStep) break;

               const connections = await fetchWorkspaceConnections({
                  workspaceId: queryVersion.query.workspaceId,
                  includeConnectionDetails: true,
               });

               const connection = connections?.find(
                  (connection) => connection.dataConnection?.id === step.dataConnectionId
               )?.dataConnection;

               if (
                  step.type === StepType.DATA_CONNECTION &&
                  (connection?.dataCredentials === undefined ||
                     (connection?.dataCredentials && connection?.dataCredentials.length === 0))
               ) {
                  if (!step.dataConnectionId) throw new Error('Step must have a data connection');
                  setShowAddCredentials({
                     connectionId: step.dataConnectionId,
                     workspaceId: queryVersion.query.workspaceId,
                  });
                  return;
               }
            }

            setRunStatus(queryVersion, {
               isRunning: true,
            });

            const results = await runQuery.mutateAsync({
               queryVersion,
               options: {
                  exploreTabId,
                  step: onlyStep,
                  stopAfterStep,
               },
            });
            if (results !== undefined) {
               setRunStatus(queryVersion, {
                  results: results ?? undefined,
               });
               queryClient.invalidateQueries(getQueryLogQueryKey({ type: QueryKeyType.LIST }));
            } else {
               setRunStatus(queryVersion, {});
            }
         } catch (err) {
            handleError(err);
            setRunStatus(queryVersion, {});
         }
      },
      [
         queryClient,
         fetchWorkspaceConnections,
         runQuery,
         exploreTabId,
         ignoreDangerous,
         runStatus,
         setRunStatus,
      ]
   );

   const runButton = (queryVersion: QueryVersion) => (
      <RunButton
         disabled={!queryVersion?.steps?.[0]?.queryText}
         key="run"
         onClick={() => run(queryVersion)}
         running={!!queryVersion.id && runStatus(queryVersion)?.isRunning}
      >
         Run
      </RunButton>
   );

   const modals = (
      <>
         <Modal show={!!promptDangerousQuery}>
            <Modal.Header>
               <Modal.Title className="fs-14p">Dangerous Query</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <div>
                  This query will modify the database.
                  <br />
                  Do you want to continue?
               </div>
               <Form>
                  <Form.Check
                     checked={ignoreDangerous}
                     label="Don't warn for this query again"
                     onChange={(event) => setIgnoreDangerous(event.target.checked)}
                     type="checkbox"
                  />
                  <div className="d-flex justify-content-end mt-2">
                     <Button
                        className={'py-1 btn-secondary'}
                        onClick={() => setPromptDangerousQuery(undefined)}
                     >
                        Cancel
                     </Button>
                     <RunButton
                        onClick={() => {
                           if (!promptDangerousQuery) return;
                           run(promptDangerousQuery?.queryVersion, {
                              suppressDangerousWarning: true,
                              step: promptDangerousQuery?.step,
                              stopAfterStep: promptDangerousQuery?.stopAfterStep,
                           });
                           setPromptDangerousQuery(undefined);
                        }}
                        running={
                           !!promptDangerousQuery?.queryVersion.id &&
                           runStatus(promptDangerousQuery.queryVersion)?.isRunning
                        }
                     />
                  </div>
               </Form>
            </Modal.Body>
         </Modal>
         <DataCredentialsModal
            connectionId={showAddCredentials?.connectionId}
            handleClose={() => setShowAddCredentials(undefined)}
            show={!!showAddCredentials}
            workspaceId={showAddCredentials?.workspaceId}
         ></DataCredentialsModal>
      </>
   );

   return { run, runButton, runStatus, modals, paramOverrides };
};

export const useRunQuery = (
   qv?: QueryVersion,
   {
      exploreTabId,
   }: {
      exploreTabId?: number;
   } = {}
) => {
   const { run, runButton, runStatus, modals } = useRunQueries({ exploreTabId });
   const runThis = useCallback(
      ({
         queryVersion,
         step,
         stopAfterStep,
      }: { queryVersion?: QueryVersion; step?: number; stopAfterStep?: number } = {}) =>
         qv && run(queryVersion ?? qv, { step, stopAfterStep }),
      [qv, run]
   );
   return {
      run: runThis,
      runButton: qv && runButton(qv),
      modals,
      ...(qv?.id ? runStatus(qv) : {}),
   };
};

export const useRunSystemQuery = () => {
   const service = useInjection<QueryService>(TYPES.queryService);
   const fetchWorkspaceConnections = useFetchListWorkspaceConnections();
   const [isRunning, setIsRunning] = useState(false);

   const run = useCallback(
      async ({
         dataConnectionId,
         query,
         exploreTabId,
         workspaceId,
         updateSchema,
      }: SystemQueryRun) => {
         if (!workspaceId) throw new Error('Workspace ID is required');

         try {
            const connections = await fetchWorkspaceConnections({
               workspaceId,
               includeConnectionDetails: true,
            });

            const connection = connections?.find(
               (connection) => connection.dataConnection?.id === dataConnectionId
            )?.dataConnection;

            if (
               connection?.dataCredentials === undefined ||
               (connection?.dataCredentials && connection?.dataCredentials.length === 0)
            ) {
               setIsRunning(false);
               handleError('Data connection credentials are required');
            }

            setIsRunning(true);
            const result = await service.runSystemQuery({
               dataConnectionId,
               query,
               exploreTabId,
               workspaceId,
               updateSchema,
            });
            setIsRunning(false);
            return result;
         } catch (err) {
            setIsRunning(false);
            throw err;
         }
      },
      [fetchWorkspaceConnections, service]
   );

   return { run, isRunning };
};

export const useGetTableDataQuery = ({
   callbacks,
   exploreTabId,
   tableSchemaId,
   workspaceId,
}: {
   callbacks?: {
      onError?: (err: unknown) => void;
      onSuccess?: (data: GetTableDataResponse | undefined) => void;
   };
   exploreTabId?: number;
   tableSchemaId: number | undefined;
   workspaceId?: number;
}) => {
   const schemaCacheService = useInjection<SchemaCacheService>(TYPES.schemaCacheService);
   const queryClient = useQueryClient();

   const [showAddCredentials, setShowAddCredentials] = useState<boolean>(false);

   const refresh = () => {
      return queryClient.invalidateQueries([QueryKey.DataForTableId, tableSchemaId]);
   };

   const tableDataQuery = useQuery<GetTableDataResponse | undefined>(
      [QueryKey.DataForTableId, tableSchemaId],
      async () => {
         if (!tableSchemaId || !workspaceId) {
            return undefined;
         }
         return schemaCacheService.getTableData(tableSchemaId, workspaceId, exploreTabId);
      },
      {
         keepPreviousData: false,
         refetchOnWindowFocus: false,
         refetchOnMount: true,
         retry: false,
         onSuccess(data) {
            if (data?.missing_cred) setShowAddCredentials(true);
            return callbacks?.onSuccess?.(data);
         },
         onError: callbacks?.onError,
      }
   );

   const modals = (
      <DataCredentialsModal
         connectionId={tableDataQuery.data?.tableCache.dataConnectionId}
         handleClose={async () => {
            await refresh();
            setShowAddCredentials(false);
         }}
         show={!!showAddCredentials}
         workspaceId={workspaceId}
      />
   );

   return { tableDataQuery, refresh, modals };
};
