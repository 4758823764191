import { inject, injectable } from 'inversify';
import { TreeNode } from '../components/UI/TreeView';
import { DataConnection, SchemaCache } from '../entities';
import { TYPES } from '../types';
import { ApiService } from './ApiService';
import { ApiServiceInterface } from './ApiServiceInterface';

const path = '/v1/dataConnection';

export enum DataConnectionListType {
   ALL = 1,
   MINE = 2,
   AVAILABLE = 3,
}

export interface DataConnectionListOptions {
   listType: DataConnectionListType;
}

export interface DataConnectionGetOptions {
   includeCredentials?: boolean;
   includeSchemaCache?: boolean;
}

export interface DataConnectionSchemaGetOptions {
   viewAll?: boolean;
}

export interface UpdateSchemaVisibilityPayload {
   schemas: {
      catalog?: string;
      schema: string;
      visible: boolean;
   }[];
}

export type EditorSchema = {
   // Full-qualified table names to column names
   schema: Record<string, string[]>;
};

export type PostResult = {
   connection: DataConnection;
   schemaGenError?: string;
};

@injectable()
export class DataConnectionService implements ApiServiceInterface<DataConnection> {
   private apiService: ApiService;

   constructor(@inject(TYPES.apiService) apiService: ApiService) {
      this.apiService = apiService;
   }

   async delete(id: string | number): Promise<null> {
      return await this.apiService.delete(`${path}/${id}`);
   }

   async get(
      id: string | number,
      params?: Record<string, string>
   ): Promise<DataConnection | undefined> {
      const result = await this.apiService.get<DataConnection>(`${path}/${id}`, params);
      if (!result) {
         return undefined;
      }

      return result as DataConnection;
   }

   getOptions(
      id: number | undefined,
      options: DataConnectionGetOptions | undefined
   ): Promise<DataConnection | undefined> | undefined {
      if (id === undefined) {
         return undefined;
      }
      const params: Record<string, string> = {};
      if (options !== undefined) {
         for (const k in options) {
            const v = options[k as keyof typeof options];
            if (v) {
               params[k] = v.toString();
            }
         }
      }

      return this.get(id, params);
   }

   async list(params?: Record<string, string>): Promise<DataConnection[]> {
      const result = await this.apiService.get<DataConnection[]>(path, params);
      if (!result) {
         return [];
      }

      return result as DataConnection[];
   }

   listOptions(filter?: DataConnectionListOptions): Promise<DataConnection[]> {
      const params: Record<string, string> = {};

      if (filter?.listType !== undefined) {
         params['listType'] = filter.listType.toString();
      }

      return this.list(params);
   }

   async patch(
      id: string | number,
      body: DataConnection,
      params?: Record<string, string>
   ): Promise<DataConnection | undefined> {
      const formData = this.apiService.toFormData({
         ...body,
         // Clear this field if it's not longer set
         sslCaCertId: body.sslCaCertId ?? null,
      });
      const result = await this.apiService.patch(`${path}/${id}`, formData, params);
      if (!result) {
         return undefined;
      }
      return result;
   }

   async post(
      body: DataConnection,
      params?: Record<string, string>
   ): Promise<DataConnection | undefined> {
      const formData = this.apiService.toFormData(body);
      const result = await this.apiService.post<PostResult>(`${path}`, formData, params);
      if (!result) {
         return undefined;
      }
      return { ...result.connection, schemaGenError: result.schemaGenError };
   }

   async put(
      _body: DataConnection,
      _params?: Record<string, string> | undefined
   ): Promise<DataConnection | undefined> {
      throw new Error('Method not implemented.');
   }

   /**
    * @deprecated replaced with post
    */
   async postFromEntity(entity: DataConnection) {
      return this.post(entity);
   }

   async testConnection(
      body: DataConnection,
      params?: Record<string, string>
   ): Promise<{ message?: string; success: Boolean }> {
      const formData = this.apiService.toFormData(body);
      const result = await this.apiService.post<{ message?: string; success: Boolean }>(
         `${path}/testConnection`,
         formData,
         params
      );
      if (!result) {
         return { success: false, message: 'No response from server' };
      }

      return result;
   }

   async retestConnection(
      body: DataConnection,
      params?: Record<string, string>
   ): Promise<{ message?: string; success: Boolean }> {
      const formData = this.apiService.toFormData({
         ...body,
         // Clear this field if it's not longer set
         sslCaCertId: body.sslCaCertId ?? null,
      });
      const result = await this.apiService.post<{ message?: string; success: Boolean }>(
         `${path}/${body.id}/testConnection`,
         formData,
         params
      );
      if (!result) {
         return { success: false, message: 'No response from server' };
      }
      return result;
   }

   async getTreeSchema(id: number | string | undefined, viewAll?: boolean): Promise<TreeNode[]> {
      if (id === undefined) {
         return [];
      }
      if (typeof id === 'string') {
         id = +id;
      }
      if (id > 0) {
         const params: Record<string, string> = {};
         if (viewAll) {
            params['viewAll'] = 'true';
         }
         const result = await this.apiService.get<TreeNode[]>(`${path}/${id}/treeSchema`, params);
         if (!result) {
            return [];
         }
         return result;
      } else {
         return [];
      }
   }

   async getEditorSchema(id: number | string): Promise<EditorSchema | undefined> {
      const result = await this.apiService.get<EditorSchema>(`${path}/${id}/editorSchema`);
      if (!result) {
         return undefined;
      }
      return result;
   }

   async updateSchema(
      id: number,
      catalogName?: string,
      tableName?: string,
      schemaName?: string
   ): Promise<TreeNode[] | undefined> {
      if (id > 0) {
         const result = await this.apiService.get(`${path}/${id}/updateSchema`, {
            asTreeView: true.toString(),
            catalogName: catalogName?.toString() ?? 'undefined',
            tableName: tableName?.toString() ?? 'undefined',
            schemaName: schemaName?.toString() ?? 'undefined',
         });
         if (!result) {
            return undefined;
         }
         const data = result as TreeNode[];
         return data;
      } else {
         return undefined;
      }
   }

   async getSchemaCache(
      dataConnectionId?: number,
      options?: DataConnectionSchemaGetOptions
   ): Promise<SchemaCache[]> {
      if (dataConnectionId === undefined) {
         return [];
      }

      const params: Record<string, string> = {};
      if (options) {
         for (const k in options) {
            const v = options[k as keyof typeof options];
            if (v) {
               params[k] = v.toString();
            }
         }
      }

      const result = await this.apiService.get<SchemaCache[]>(
         `${path}/${dataConnectionId}/schema`,
         params
      );
      if (!result) {
         return [];
      }

      return result;
   }

   async updateSchemaVisibility(
      dataConnectionId: number,
      body: UpdateSchemaVisibilityPayload
   ): Promise<void> {
      await this.apiService.patch(`${path}/${dataConnectionId}/updateSchemaVisibility`, body);

      return;
   }
}
