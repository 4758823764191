import { useInjection } from 'inversify-react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Form, InputGroup, Stack } from 'react-bootstrap';
import Loader from 'react-spinners/PulseLoader';

import { QueryVersion, QueryStep, QuerySuggestion, StepType } from '../entities';
import QueryWidget from './QueryWidget';
import AiSparkles, { AiPulse } from './AiSparkles';
import { useListQueryQuery } from '../hooks';
import { useDebounce } from '../hooks/debounce';
import { SearchService, AskService } from '../services';
import { TYPES } from '../types';
import { getErrorMessage, handleError } from '../utilities';
import LoadingSpinner from './UI/LoadingSpinner';
import { useWalkthroughStep } from '../hooks/walkthrough';
import { walkthroughStep } from '../entities/Walkthrough';

const START_SEARCH_CHAR_COUNT: number = 5;
// Restrict suggestions to within 30% of the top score
const SCORE_THRESHOLD = 0.7;

function queryLongEnough(query?: string) {
   if (query === undefined) return false;
   return query.replace(/select|from/gi, '').replace(/\s/g, '').length >= START_SEARCH_CHAR_COUNT;
}

export const QuerySuggestions = ({
   query,
   step,
   workspaceId,
}: {
   query?: QueryVersion;
   step?: QueryStep;
   workspaceId: number;
}): JSX.Element => {
   const searchService = useInjection<SearchService>(TYPES.searchService);
   const promptService = useInjection<AskService>(TYPES.askService);

   const [suggestionsLoading, setSuggestionsLoading] = useState(false);
   const [suggestions, setSuggestions] = useState<QuerySuggestion[]>([]);

   const queryDebounced = useDebounce(step?.queryText, 1000);
   const currentQueryForSuggestions = useRef<string>();

   const [search, setSearch] = useState('');
   const searchDebounced = useDebounce(search, 750);
   const [searchLoading, setSearchLoading] = useState(false);
   const [searchResults, setSearchResults] = useState<QuerySuggestion[]>([]);

   const [prompt, setPrompt] = useState('');
   const [promptLoading, setPromptLoading] = useState(false);
   const [promptResponse, setPromptResponse] = useState<string[]>();

   const [stepThree, setStepThree] = useWalkthroughStep(walkthroughStep.SUGGESTED_QUERY);

   const queryText = step?.queryText;
   useEffect(() => {
      if (!queryLongEnough(queryText)) {
         setSuggestionsLoading(false);
         setSuggestions([]);
         currentQueryForSuggestions.current = '';
         return;
      }
      setSuggestionsLoading(queryText !== currentQueryForSuggestions.current);
   }, [queryText]);
   useEffect(() => {
      if (!queryDebounced || !queryLongEnough(queryDebounced)) {
         setSuggestions([]);
         currentQueryForSuggestions.current = queryDebounced;
         return;
      }
      searchService.querySuggest(workspaceId, queryDebounced).then((val) => {
         currentQueryForSuggestions.current = queryDebounced;
         // Remove suggestions that match the current query or that are for the same saved query
         let suggestions = val.filter(
            (s) =>
               s.query.queryId !== query?.id &&
               (s.query.steps.length > 1 || s.query.steps[0]?.queryText !== queryDebounced)
         );
         const scoreThreshold = (suggestions[0]?.score ?? 0) * SCORE_THRESHOLD;
         // Always include the top 3 unless their score is below 1 (poor match)
         suggestions = suggestions.filter(
            (s, i) => s.score && s.score > 1 && (i < 3 || s.score >= scoreThreshold)
         );
         setSuggestions(suggestions);
         setSuggestionsLoading(false);
      });
   }, [query?.id, queryDebounced, workspaceId, searchService]);

   useEffect(() => {
      if (searchDebounced.replace(/\s/g, '').length === 0) {
         setSearchLoading(false);
         setSearchResults([]);
         return;
      }
      searchService
         .search(workspaceId, searchDebounced)
         .then((val) => {
            setSearchResults(val);
         })
         .finally(() => {
            setSearchLoading(false);
         });
   }, [searchDebounced, workspaceId, searchService]);
   const onSearchChange = (value: string) => {
      setSearch(value);
      if (value !== searchDebounced && value.replace(/\s/g, '').length > 0) {
         setSearchLoading(true);
      } else {
         setSearchLoading(false);
      }
   };

   const queriesQuery = useListQueryQuery({
      listOptions: {
         version: 'all',
         expandedPersonData: true,
         includeLatestRun: true,
         includeDataConnectionDetails: true,
         workspaceId: workspaceId,
         take: 10,
      },
   });

   const promptRunAi = (prompt: string) => {
      // Write a query to select all the names from the feature table
      setPromptLoading(true);
      promptService
         .suggestion({ prompt, dataConnectionId: step?.dataConnectionId })
         .then((response) => {
            if (response) {
               setPromptResponse([response.trim()]);
            }
         })
         .catch((err) => {
            const message = getErrorMessage(err);
            if (message === 'RunAI limit exceeded') {
               setPromptResponse([message]);
            } else {
               handleError(message);
            }
         })
         .finally(() => {
            setPromptLoading(false);
         });
   };

   const savedQueries = useMemo(
      () =>
         queriesQuery.data?.items
            ?.filter((q) => q.id !== query?.id)
            ?.filter((q) => {
               if (!q.version) return false;
               const promptLc = search.toLowerCase();
               if (q.title?.toLocaleLowerCase?.()?.includes(promptLc)) {
                  return true;
               }
               if (q.steps?.some((s) => s.queryText?.toLowerCase().includes(promptLc))) {
                  return true;
               }
               return false;
            }) ?? [],
      [queriesQuery.data, query?.id, search]
   );
   let results = <></>;
   let promptQuery: QueryVersion | undefined;
   if (promptResponse && promptResponse.length > 0) {
      promptQuery = {
         createdByPerson: {
            firstName: 'runAI',
         },
         steps: promptResponse.map((query, i) => ({
            order: i,
            dataConnectionId: step?.dataConnectionId,
            type: step?.type || StepType.DATA_CONNECTION,
            queryText: query,
         })),
         title: search,
      };
   }
   if (searchLoading && !prompt) {
      results = <LoadingSpinner />;
   } else if (search.length > 0) {
      if (searchResults && searchResults.length > 0) {
         results = (
            <>
               {searchResults && searchResults.length > 0 && (
                  <>
                     {searchResults.map((r) => (
                        <AiPulse
                           key={r.query.id}
                           on={stepThree && r === searchResults[0]}
                           onClick={() => {
                              if (stepThree) {
                                 setStepThree();
                              }
                           }}
                           sparkleAfter
                        >
                           <QueryWidget
                              action="use"
                              collapse
                              key={r.query.id}
                              queryVersion={r.query}
                              source="saved"
                           />{' '}
                        </AiPulse>
                     ))}
                  </>
               )}
            </>
         );
      } else if (!prompt) {
         results = <div className="fs-12p">No matches</div>;
      }
   } else {
      results = (
         <>
            {suggestionsLoading && <Loader color="#6366f1" size={6} />}
            {suggestions.length > 0 &&
               suggestions.map((s) => (
                  <AiPulse
                     key={s.query.id}
                     on={stepThree && s === suggestions[0]}
                     onClick={() => {
                        if (stepThree) {
                           setStepThree();
                        }
                     }}
                     sparkleAfter
                  >
                     <QueryWidget
                        action="use"
                        collapse
                        key={s.query.id}
                        queryVersion={s.query}
                        source="saved"
                     />{' '}
                  </AiPulse>
               ))}
            {suggestions.length === 0 &&
               savedQueries.map(
                  (q) =>
                     q.version && (
                        <QueryWidget
                           action="use"
                           collapse
                           key={q.id}
                           queryVersion={q}
                           source="saved"
                        />
                     )
               )}
         </>
      );
   }

   return (
      <Stack gap={3}>
         <InputGroup>
            <Form.Control
               className="small-form-control-input"
               onChange={(e) => {
                  setPrompt(e.currentTarget.value);
                  onSearchChange(e.currentTarget.value);
               }}
               onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                     promptRunAi(prompt);
                  }
               }}
               placeholder="Ask runAI to generate a query…"
               value={prompt}
            />
            <button
               className="btn btn-xs btn-primary plausible-event-name--askAI"
               disabled={prompt.length === 0}
               onClick={() => promptRunAi(prompt)}
            >
               Ask AI
            </button>
         </InputGroup>

         {promptLoading && <Loader color="#6366f1" size={6} />}
         {promptQuery && !promptLoading && (
            <AiSparkles>
               {(targetRef) => (
                  <QueryWidget
                     action="use"
                     collapse
                     highlight
                     queryVersion={promptQuery!}
                     ref={targetRef}
                     source="runai"
                  />
               )}
            </AiSparkles>
         )}

         <Stack className="dimmed-queries" gap={3}>
            {results}
         </Stack>
      </Stack>
   );
};
export default QuerySuggestions;
