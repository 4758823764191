import { useContext, createContext } from 'react';
import { useInjection } from 'inversify-react';
import { useQuery } from 'react-query';

import { QueryStep, QueryVersion, Workspace } from '../entities';
import { WorkspaceService } from '../services';
import { TYPES } from '../types';
import { QueryKey } from '../enums';
import { handleError } from '../utilities';

export type CurrentStep = {
   insert: (snippet: string) => void;
   queryStep: QueryStep;
   queryVersion: QueryVersion;
   replaceAll: (steps: QueryStep[]) => void;
   selection?: string;
};

export type WorkspaceContext = {
   currentStep?: CurrentStep;
   setCurrentStep: (step?: CurrentStep) => void;
   workspace: Workspace | undefined;
};

const ctx = createContext<WorkspaceContext | undefined>(undefined);
export const WorkspaceContextProvider = ctx.Provider;

export const useWorkspace = (): Workspace | undefined => {
   return useContext(ctx)?.workspace;
};

export const useCurrentStep = (): CurrentStep | undefined => {
   return useContext(ctx)?.currentStep;
};

export const useSetCurrentStep = () => {
   const noop = (step?: CurrentStep) => {};
   return useContext(ctx)?.setCurrentStep ?? noop;
};

export const useWorkspaces = (params?: Record<string, string>): Workspace[] | undefined => {
   const workspaceService = useInjection<WorkspaceService>(TYPES.workspaceService);
   const workspaceFilters = {
      savedQueryCount: true.toString(),
      includeCollaborators: true.toString(),
      includeDataSources: true.toString(),
      ...params,
   };
   const workspaceQuery = useQuery<Workspace[]>(
      [QueryKey.Workspace, 'list', workspaceFilters],
      () =>
         workspaceService.list(workspaceFilters).catch((err) => {
            handleError(err);
            return [];
         }),
      {
         keepPreviousData: true,
         refetchOnWindowFocus: false,
         refetchOnMount: true,
         refetchOnReconnect: false,
         retry: false,
         onError(err) {
            handleError(err);
         },
      }
   );
   return workspaceQuery.data;
};

export const useRecentWorkspaces = (): Workspace[] | undefined => {
   const workspaceService = useInjection<WorkspaceService>(TYPES.workspaceService);
   const workspaceQuery = useQuery<Workspace[]>(
      [QueryKey.Workspace, 'recent'],
      () =>
         workspaceService.recent().catch((err) => {
            handleError(err);
            return [];
         }),
      {
         keepPreviousData: true,
         refetchOnWindowFocus: false,
         refetchOnMount: true,
         refetchOnReconnect: false,
         retry: false,
         onError(err) {
            handleError(err);
         },
      }
   );
   return workspaceQuery.data;
};
