import { Button, Spinner, Stack } from 'react-bootstrap';
import { NavLink, To } from 'react-router-dom';
import { HomeNav, Page } from '../components';
import { Workspace } from '../entities';
import { IconForward, useTitle } from '../utilities';
import { useOrgStats, useRecentWorkspaces } from '../hooks';
import { useWalkthroughStep } from '../hooks/walkthrough';
import { walkthroughStep } from '../entities/Walkthrough';
import { AiPulse } from '../components';
const ViewLink = ({ label, to }: { label: string; to: To }) => (
   <Button
      // @ts-ignore
      as={NavLink}
      className="align-self-end text-primary"
      size="sm"
      to={to}
      variant="link"
   >
      {label} <IconForward />
   </Button>
);

const ActivitySection = ({
   children,
   title,
   action,
}: {
   action?: React.ReactNode;
   children?: React.ReactNode;
   title: string;
}): React.ReactNode => {
   return (
      <Stack gap={2}>
         <Stack className="justify-content-between" direction="horizontal" gap={2}>
            <div className="fs-18p fw-500">{title}</div>
            {action}
         </Stack>
         {children}
      </Stack>
   );
};

type Stat = {
   label: string;
   number?: number;
};

const ActivityCard = ({
   stats,
   link,
}: {
   link: React.ReactNode;
   stats: Stat[];
}): React.ReactNode => {
   return (
      <div className="card bg-secondary border-0 p-3">
         <Stack gap={3}>
            {stats.map((stat) => (
               <Stack key={stat.label}>
                  <div className="fs-18p fw-500">
                     {stat.number === undefined ? <Spinner size="sm" /> : stat.number}
                  </div>
                  <div style={{ whiteSpace: 'preserve' }}>{stat.label}</div>
               </Stack>
            ))}
            {link}
         </Stack>
      </div>
   );
};

const WorkspaceLink = ({ workspace }: { workspace: Workspace }) => {
   const [stepOne, setStepOne] = useWalkthroughStep(walkthroughStep.PERSONAL_DEMO_WORKSPACE);
   return (
      <AiPulse key={workspace.id} on={stepOne && workspace.name === 'Demo Playground'} sparkleAfter>
         <div key={workspace.id}>
            <div className="card bg-secondary border-0 p-3">
               <div className="fs-18p fw-500">{workspace.name}</div>
               <div>
                  <Button
                     // @ts-ignore
                     as={NavLink}
                     bsPrefix="custom-buttom-link-no-spacing"
                     className="text-decoration-none text-primary"
                     onClick={(e) => {
                        if (stepOne) {
                           setStepOne();
                        }
                     }}
                     to={`/workspaces/${workspace.id}`}
                  >
                     View Workspace <IconForward />
                  </Button>
               </div>
            </div>
         </div>
      </AiPulse>
   );
};

const Activity = (): React.ReactNode => {
   const workspaces = useRecentWorkspaces();
   const stats = useOrgStats();
   const extraWorkspaces = stats && stats?.collaboratedWorkspaces - stats?.createdWorkspaces;
   const extraDataSources = stats && stats?.availableDataSources - stats?.createdDataSources;

   return (
      <Stack gap={2}>
         <div className="mb-3 border-top-line"></div>
         <ActivitySection
            action={<ViewLink label="View All" to="/home/queries" />}
            title={`You have ${stats?.mySavedQueries} Saved Queries`}
         />
         <div className="mb-3 mt-3 border-top-line"></div>
         <ActivitySection
            action={<ViewLink label="View All" to="/workspaces" />}
            title="Your Recent Workspaces"
         >
            {workspaces === undefined && <Spinner />}
            {workspaces?.map((workspace) => (
               <WorkspaceLink key={workspace.id} workspace={workspace} />
            ))}
         </ActivitySection>
         <div className="mb-3 mt-3 border-top-line"></div>
         <ActivitySection title="Activity">
            <div className="activity-cards">
               <ActivityCard
                  link={<ViewLink label="View Saved" to="/home/queries" />}
                  stats={[
                     { label: 'Total saved queries', number: stats?.savedQueries },
                     { label: 'Your saved queries', number: stats?.mySavedQueries },
                  ]}
               />
               <ActivityCard
                  link={<ViewLink label="View History" to="/home/logs" />}
                  stats={[
                     { label: 'Total queries run', number: stats?.queryRunCount },
                     { label: 'Queries run by you', number: stats?.myQueryRunCount },
                  ]}
               />
               <ActivityCard
                  link={<ViewLink label="View Snippets" to="/home/snippets" />}
                  stats={[
                     { label: 'Total snippets', number: stats?.totalSnippets },
                     { label: 'Your snippets', number: stats?.mySnippets },
                  ]}
               />
               <ActivityCard
                  link={<ViewLink label="View Workspaces" to="/workspaces" />}
                  stats={[
                     { label: 'Total workspaces', number: stats?.totalWorkspaces },
                     {
                        label: `Your workspaces${
                           extraWorkspaces ? `\n+ ${extraWorkspaces} you are a member in` : ''
                        }`,
                        number: stats?.createdWorkspaces,
                     },
                  ]}
               />
               <ActivityCard
                  link={<ViewLink label="View Data Sources" to="/sources" />}
                  stats={[
                     { label: 'Total data sources', number: stats?.totalDataSources },
                     {
                        label: `Your data sources${
                           extraDataSources ? `\n+ ${extraDataSources} shared data sources` : ''
                        }`,
                        number: stats?.createdDataSources,
                     },
                  ]}
               />
               <ActivityCard
                  link={<ViewLink label="View Certification Requests" to="/query-review" />}
                  stats={[
                     { label: 'Total certified queries', number: stats?.totalCertifiedQueries },
                     {
                        label: 'Waiting for your review',
                        number: stats?.totalQueriesAwaitingReview,
                     },
                  ]}
               />
            </div>
         </ActivitySection>
      </Stack>
   );
};

export const OverviewPage = (): React.ReactNode => {
   useTitle('Home');
   return (
      <Page
         header="Home"
         nav={<HomeNav />}
         tabs={[
            {
               title: 'My Activity',
               content: <Activity />,
            },
         ]}
      />
   );
};

export default OverviewPage;
