import { QueryBuilder } from './QueryBuilder';

export class SnowflakeQueryBuilder extends QueryBuilder {
   public beginTransaction(): string {
      return '';
   }

   public endTransaction(): string {
      return '';
   }
}
