import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { DBMS } from '../../../../enums';
import { useGetDataConnectionQuery } from '../../../../hooks';
import { connectionName, description, password, username } from '../validators';

const snowflakeSchema = z.object({
   dbms: z.literal(DBMS.Snowflake),
   connectionName: connectionName,
   description: description,
   snowflakeAccount: z.string().min(1, 'Required'),
   accountName: username,
   password: password,
   warehouse: z.string(),
   database: z.string(),
});

export type SnowflakeDetailFormData = z.infer<typeof snowflakeSchema>;

function SnowflakeDetailForm({
   editType,
   formId,
   onSaveStateChange,
   onSubmit,
   selectedConnectionId,
}: {
   editType: 'connection' | 'credential' | 'read-only';
   formId: string;
   onSaveStateChange?: (state: 'clean' | 'dirty') => void;
   onSubmit?: (data: SnowflakeDetailFormData) => void;
   selectedConnectionId?: number;
}) {
   // register form
   const { register, handleSubmit, formState, reset } = useForm<SnowflakeDetailFormData>({
      resolver: zodResolver(snowflakeSchema),
   });
   const errors = formState.errors;
   const touchedFields = formState.touchedFields;

   // Queries
   const selectedConnectionQuery = useGetDataConnectionQuery({
      id: selectedConnectionId,
      getOptions: { includeCredentials: true },
   });

   // Effects
   useEffect(() => {
      //User is adding credentials, load parent connection data
      if (selectedConnectionQuery.data) {
         const formData: SnowflakeDetailFormData = {
            dbms: DBMS.Snowflake,
            connectionName: selectedConnectionQuery.data.name ?? '',
            description: selectedConnectionQuery.data.description ?? '',
            snowflakeAccount: selectedConnectionQuery.data.snowflakeAccount ?? '',
            warehouse: selectedConnectionQuery.data.snowflakeWarehouse ?? '',
            database: selectedConnectionQuery.data.dbName ?? '',
            accountName: selectedConnectionQuery.data.dataCredentials?.[0].accountName ?? '',
            password: 'CURRENT',
         };
         reset(formData);
      }
   }, [selectedConnectionQuery.data, reset]);

   useEffect(() => {
      const isDirtyAlt = !!Object.keys(formState.dirtyFields).length;
      if (isDirtyAlt) {
         onSaveStateChange?.('dirty');
      } else {
         onSaveStateChange?.('clean');
      }
   }, [formState, onSaveStateChange]);

   // Page functions
   const handleOnSubmit = (data: SnowflakeDetailFormData) => {
      if (onSubmit) {
         onSubmit(data);
      }
   };

   return (
      <Form id={formId} onSubmit={handleSubmit(handleOnSubmit)}>
         <input type="hidden" {...register('dbms')} value={DBMS.Snowflake} />
         <Form.Group className="mb-3">
            <Form.Label>
               Connection Name <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
               {...register('connectionName')}
               disabled={editType !== 'connection'}
               isInvalid={touchedFields.connectionName && !!errors.connectionName}
               isValid={touchedFields.connectionName && !errors.connectionName}
               placeholder="Connection Name"
               required
            />
            <Form.Control.Feedback type="invalid">
               {errors.connectionName?.message}
            </Form.Control.Feedback>
         </Form.Group>
         <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
               {...register('description')}
               as="textarea"
               disabled={editType !== 'connection'}
               isInvalid={touchedFields.description && !!errors.description}
               isValid={touchedFields.description && !errors.description}
               placeholder="Description"
               rows={3}
            />
            <Form.Control.Feedback type="invalid">
               {errors.description?.message}
            </Form.Control.Feedback>
         </Form.Group>
         <Form.Group className="mb-3">
            <Form.Label>
               Snowflake Account <span className="text-danger">*</span>
            </Form.Label>
            <Row>
               <span className="fs-10p">
                  Click{' '}
                  <a
                     href="https://docs.snowflake.com/en/user-guide/admin-account-identifier#format-1-preferred-account-name-in-your-organization"
                     rel="noreferrer"
                     target={'_blank'}
                  >
                     here
                  </a>{' '}
                  for help finding your account indentifier
               </span>
            </Row>
            <Form.Control
               {...register('snowflakeAccount')}
               disabled={editType !== 'connection'}
               isInvalid={touchedFields.snowflakeAccount && !!errors.snowflakeAccount}
               isValid={touchedFields.snowflakeAccount && !errors.snowflakeAccount}
               placeholder="<org_name>.<account_name>"
               required
            />
            <Form.Control.Feedback type="invalid">
               {errors.snowflakeAccount?.message}
            </Form.Control.Feedback>
         </Form.Group>
         <Form.Group className="mb-3">
            <Form.Label>
               Username <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
               {...register('accountName')}
               disabled={editType === 'read-only'}
               isInvalid={touchedFields.accountName && !!errors.accountName}
               isValid={touchedFields.accountName && !errors.accountName}
               placeholder="User Name"
               required
            />
            <Form.Control.Feedback type="invalid">
               {errors.accountName?.message}
            </Form.Control.Feedback>
         </Form.Group>
         <Form.Group className="mb-3">
            <Form.Label>
               Password <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
               {...register('password')}
               disabled={editType === 'read-only'}
               isInvalid={touchedFields.password && !!errors.password}
               isValid={touchedFields.password && !errors.password}
               placeholder="Password"
               required
               type="password"
            />
            <Form.Control.Feedback type="invalid">{errors.password?.message}</Form.Control.Feedback>
         </Form.Group>
         <Form.Group className="mb-3">
            <Form.Label>Warehouse <span className="text-danger">*</span></Form.Label>
            <Form.Control
               {...register('warehouse')}
               disabled={editType !== 'connection'}
               isInvalid={touchedFields.warehouse && !!errors.warehouse}
               isValid={touchedFields.warehouse && !errors.warehouse}
               placeholder="Warehouse"
               required
            />
            <Form.Control.Feedback type="invalid">
               {errors.warehouse?.message}
            </Form.Control.Feedback>
         </Form.Group>
         <Form.Group className="mb-3">
            <Form.Label>Database</Form.Label>
            <Form.Control
               {...register('database')}
               disabled={editType !== 'connection'}
               isInvalid={touchedFields.database && !!errors.database}
               isValid={touchedFields.database && !errors.database}
               placeholder="Database"
            />
            <Form.Control.Feedback type="invalid">{errors.database?.message}</Form.Control.Feedback>
         </Form.Group>
      </Form>
   );
}

export default SnowflakeDetailForm;
