import { Nav } from 'react-bootstrap';

import Page from '../components/Page';
import { IconMenu, useTitle } from '../utilities';

const locationText = '{{location}}';

type Section = {
   answer: JSX.Element;
   id?: string;
   question: JSX.Element;
};

const sections: Section[] = [
   {
      question: <>What is the difference between Query History and Saved Queries?</>,
      answer: (
         <>
            <p>
               Query History captures every query you have successfully run whereas Saved Queries
               captures and versions every query you save.
            </p>
         </>
      ),
   },
   {
      question: <>Adding Database Hosts & LLM Connections</>,
      answer: (
         <>
            <p>
               When you add a DB or LLM connection in your runQL organization the connection name,
               host/ip and port are made available for your team members within your organization
               when they go to add a connection. This makes it easier for other team members to
               connect or your databases.
            </p>
            <p>
               They will always have to enter their own credentials (unless it is a Shared
               Connection)
            </p>
         </>
      ),
   },
   {
      question: <>What is a Shared Database or LLM Connection?</>,
      answer: (
         <>
            <p>
               A Shared Connection is for your databases or LLMs that use one set of credentials.
               This connection cannot be edited by others and the credentials for this connection
               cannot be viewed by others.
            </p>
            <p>
               Additionally an admin can setup a Shared Connection with the "hide" option enabled
               which also hides the server hostname/IP and Port from being viewed by others in your
               organization.
            </p>
         </>
      ),
   },
   {
      question: <>How can I import existing queries from git or elsewhere?</>,
      answer: (
         <>
            <p>
               SQL files containing queries can be imported into a workspace. First, gather your
               queries to import:
            </p>
            <ul>
               <li>
                  Place queries in .sql files. If your queries are in git, you can likely simply
                  download your git archive.
               </li>
               <li>The name of the .sql file will be used as the saved query title.</li>
               <li>
                  Each query (separated by a semi-colon) will be imported as a separate saved query.
               </li>
               <li>
                  If the query is preceded by a comment, the comment will be used as the saved query
                  description.
               </li>
               <li>To import a set of .sql files, place them in a .zip archive.</li>
            </ul>
            <p>To import your file(s):</p>
            <ol>
               <li>Open the workspace or create a new one.</li>
               <li>Ensure your workspace has at least one selected database.</li>
               <li>
                  Open the <IconMenu /> menu.
               </li>
               <li>
                  Select <span className="fw-bold">Import SQL files</span>.
               </li>
               <li>Select the file to import.</li>
               <li>
                  Click <span className="fw-bold">Import</span>.
               </li>
            </ol>
         </>
      ),
   },
   {
      id: 'runSource',
      question: <>What is runQL&apos;s Virtual Query Service (AKA runSource)?</>,
      answer: (
         <>
            <p>
               runSource is a Virtual Query Service that acts as a bridge between your database(s)
               and your external services that need data. This can be used for external services
               like Metabase, PowerBi, etc. With our Virtual Query Service you can:
            </p>
            <ol>
               <li>
                  Have one source of truth for your queries that can be used in external services.
               </li>
               <li>Shield your external services from changes to your underlying query.</li>
               <li>
                  Provide secured, logged access to your data while hiding your real database
                  credentials from your external services.
               </li>
            </ol>
            <p>
               Example
               <br />
               Sara is an Data Analyst at ACME Inc. and they have one hundred+ dashboards in their
               BI tool. Twenty of the dashboards have one area that displays the top ten
               invoiceAmounts for the region that is selected on the BI dashboard. The executives
               check it every Monday at 3pm.
               <br />
               Sara's query for the top ten invoiceAmounts is:
               <br />
               <span className="fsBadge">
                  SELECT <span className="badge bg-secondary">invoiceId</span>,{' '}
                  <span className="badge bg-secondary">invoiceAmount</span>,{' '}
                  <span className="badge bg-secondary">customerName</span> FROM{' '}
                  <span className="badge bg-secondary">customers</span> WHERE{' '}
                  <span className="badge bg-secondary">region</span> = {locationText} ORDER BY
                  invoiceAmount DESC LIMIT 10;
               </span>
               <br />
               <br />
               The Virtual Query generated by runQL for the above query is:
               <br />
               <span className="badge bg-primary">
                  SELECT * FROM runQL WHERE runSource='[generatedToken]' AND location=?
               </span>
               <br />
               Sara pastes this virtual query into ACME's BI tool for the twenty dashboards.
               <br />
               <br />
               It's Monday at 2:30pm and Sara was just informed by ACME's Data Engineering team that
               they made the following schema changes:
               <br />
            </p>
            <ul>
               <li>
                  The <span className="badge bg-secondary">customers</span> table has been changed
                  to <span className="badge bg-dark">customerInvoices</span>
               </li>
               <li>
                  The <span className="badge bg-secondary">region</span> column was changed to{' '}
                  <span className="badge bg-dark">customerRegion</span>
               </li>
            </ul>
            <p>
               At this point Sara goes into runQL and changes the source query:
               <span className="fsBadge">
                  SELECT <span className="badge bg-secondary">invoiceId</span>,{' '}
                  <span className="badge bg-secondary">invoiceAmount</span>,{' '}
                  <span className="badge bg-secondary">customerName</span> FROM{' '}
                  <span className="badge bg-dark">customerInvoices</span> WHERE{' '}
                  <span className="badge bg-dark">customerRegion</span> = {locationText} ORDER BY
                  invoiceAmount DESC LIMIT 10;
               </span>{' '}
               and clicks save.
               <br />
               <br />
               Sara is done after making the change to the one source of truth in runQL whereas in
               the past she would have had to find the twenty dashboards and make changes in each
               one of them!
            </p>
         </>
      ),
   },
];

export const DocumentationPage = (): JSX.Element => {
   useTitle('Documentation');
   return (
      <Page
         header="Documentation"
         nav={
            <Nav className="flex-column">
               {sections.map((section, index) => (
                  <Nav.Link href={`#${section.id ?? index}`} key={index}>
                     {section.question}
                  </Nav.Link>
               ))}
            </Nav>
         }
      >
         {sections.map((section, index) => (
            <div id={section.id ?? index.toString()} key={index}>
               <h2>{section.question}</h2>
               <div>{section.answer}</div>
            </div>
         ))}
      </Page>
   );
};
