import { forwardRef, memo, useState } from 'react';
import classNames from 'classnames';
import { Button, Collapse, OverlayTrigger, Popover, Stack } from 'react-bootstrap';
import { BiCheck, BiCopy } from 'react-icons/bi';
import { useParams, useSearchParams } from 'react-router-dom';
import { useInjection } from 'inversify-react';

import {
   PersonUtilities,
   QueryLog,
   QueryState,
   QueryVersion,
   createQueryVersionFromLogs,
   useOpenQuery,
} from '../entities';
import { QUERY_SOURCE } from '../enums';
import { useExploreTabContext, useExtension } from '../hooks';
import { StatsService } from '../services';
import { TYPES } from '../types';
import { copyToClipboard } from '../utilities/clipboard';
import { getShortDateTimeString } from '../utilities/formatters';
import { QueryComments } from './QueryComments';
import CodeViewer from './UI/CodeViewer';
import DiffViewer from './UI/DiffViewer';
import { IconPrivate, IconShared, IconOpen, IconUse } from '../utilities/icons';
import { QueryVersionBadge } from '.';

const MAX_QUERY_LENGTH = 180;

export const QueryHeader = ({
   queryVersion,
   showDescription,
   collapse,
   basedOnVersion,
   setShared,
}: {
   basedOnVersion?: boolean;
   collapse?: boolean;
   queryVersion: QueryVersion;
   setShared?: (queryVersion: QueryVersion, shared: boolean) => void;
   showDescription?: boolean;
}): JSX.Element => {
   collapse ??= false;
   let title = queryVersion?.title ?? getShortDateTimeString(queryVersion?.modified);
   showDescription ??= false;
   const shared = queryVersion?.query?.state === QueryState.SHARED;
   const approved = queryVersion.query?.approvedVersionId === queryVersion.id;

   return (
      <Stack>
         <div className="d-flex">
            <div className="flex-grow-1">
               <Stack className="flex-wrap" direction="horizontal" gap={1}>
                  {basedOnVersion && <i>Based on&nbsp;</i>}
                  {title}
                  <QueryVersionBadge approved={approved} queryVersion={queryVersion} />
                  {queryVersion?.id &&
                     (setShared ? (
                        <Button
                           key="share"
                           onClick={
                              queryVersion?.query
                                 ? () => setShared(queryVersion, !shared)
                                 : undefined
                           }
                           size="sm"
                           title={
                              shared
                                 ? 'Make the query private to you'
                                 : 'Make query visible to everyone in the workspace'
                           }
                           variant="secondary"
                        >
                           <Stack direction="horizontal" gap={1}>
                              {shared ? (
                                 <IconShared className="opacity-75" size="12" />
                              ) : (
                                 <IconPrivate className="opacity-75" size="12" />
                              )}
                              <div>{shared ? 'Shared' : 'Share'}</div>
                           </Stack>
                        </Button>
                     ) : (
                        <>
                           {shared ? (
                              <IconShared className="opacity-75" size="12" />
                           ) : (
                              <IconPrivate className="opacity-75" size="12" />
                           )}
                        </>
                     ))}
               </Stack>
            </div>
         </div>
         {showDescription && queryVersion?.description && (
            <div className="fs-11p fw-normal">{queryVersion.description}</div>
         )}
         {showDescription && queryVersion?.question && (
            <div
               className="fs-11p fw-normal"
               data-bs-toggle="tooltip"
               title={queryVersion.question}
            >
               <i>Question</i>: {queryVersion.question}
            </div>
         )}
      </Stack>
   );
};

export const QueryFooter = ({
   queryVersion,
   runtime,
}: {
   queryVersion: QueryVersion;
   runtime?: number;
}): JSX.Element => {
   return (
      <Stack className="align-items-start" direction="horizontal" gap={1}>
         {queryVersion?.title && queryVersion?.modified && (
            <div className="fs-9p text-muted">
               {getShortDateTimeString(queryVersion?.modified)}
               {runtime && runtime > 0 && <>&nbsp;({runtime}ms)</>}
            </div>
         )}
         {queryVersion?.createdByPerson && (
            <div className="fs-9p text-muted fw-500 potential-badge">
               {PersonUtilities.getFullName(
                  queryVersion?.aiSuggestion
                     ? {
                          firstName: 'runAI',
                       }
                     : queryVersion?.createdByPerson
               )}
            </div>
         )}
      </Stack>
   );
};

export const QueryWidget = memo(
   forwardRef(
      (
         {
            children,
            collapse,
            diffSide,
            diffVersion,
            extraActions,
            highlight,
            action,
            queryVersion,
            runtime,
            showComments,
            source,
            workspaceId,
            basedOnVersion,
            popoverPlacement,
            noHover,
         }: {
            action?: 'open' | 'use' | 'useNewTab' | 'copy' | 'none';
            basedOnVersion?: boolean;
            children?: React.ReactNode;
            // collapses the code view and also hides the description when in the starting block.
            collapse?: boolean;
            // Whether queryVersion should be shown as the new or old query.
            // If not provided, it will be determined by the modified date.
            diffSide?: 'new' | 'old';
            diffVersion?: QueryVersion;
            extraActions?: React.ReactNode[];
            highlight?: boolean;
            noHover?: boolean;
            popoverPlacement?: 'right' | 'left' | 'auto-start';
            queryVersion: QueryVersion;
            runtime?: number;
            showComments?: boolean;
            source: QUERY_SOURCE;
            workspaceId?: number;
         },
         ref: React.ForwardedRef<HTMLDivElement>
      ): JSX.Element => {
         const [searchParams] = useSearchParams();
         const tabId = searchParams.has('t') ? Number(searchParams.get('t')) : undefined;
         const exploreTab = useExploreTabContext();
         const params = useParams();
         const extension = useExtension();
         const statsService = useInjection<StatsService>(TYPES.statsService);

         action ??= extension ? 'copy' : 'open';
         popoverPlacement ??= extension ? 'left' : 'auto-start';
         if (action === 'use' && !tabId) action = 'open';
         showComments ??= false;
         if (diffVersion && !diffSide) {
            diffSide =
               diffVersion.modified &&
               queryVersion.modified &&
               diffVersion.modified < queryVersion.modified
                  ? 'new'
                  : 'old';
         }
         workspaceId ??= params.workspaceId ? Number(params.workspaceId) : undefined;
         collapse ??= !!exploreTab.exploreTab;

         const openQuery = useOpenQuery(extension ? { handler: extension.openTab } : {});
         const [showCopySuccess, setShowCopySuccess] = useState(false);
         const onOpen = () => {
            openQuery({
               newTab: true,
               queryVersion,
               source,
               workspaceId,
            });
         };

         if (extension) {
            extraActions = [
               <Button onClick={onOpen} size="sm" variant="secondary">
                  <IconOpen size={10} />
                  &nbsp;Open
               </Button>,
            ];
         }

         let label, icon, onAction;
         switch (action) {
            case 'open':
               label = 'Open';
               onAction = onOpen;
               icon = <IconOpen size={10} />;
               break;
            case 'use':
               label = 'Use';
               onAction = () => {
                  openQuery({
                     queryVersion,
                     source,
                     workspaceId,
                  });
               };
               icon = <IconUse size={10} />;
               break;
            case 'useNewTab':
               label = 'Use';
               onAction = () => {
                  openQuery({
                     queryVersion,
                     source,
                     workspaceId,
                     newTab: true,
                  });
               };
               icon = <IconUse size={10} />;
               break;
         }

         const copyAction = () => {
            const query = queryVersion?.steps?.flatMap((s) => s.queryText ?? null).join(';\n');
            copyToClipboard(query);
            setShowCopySuccess(true);
            setTimeout(() => {
               setShowCopySuccess(false);
            }, 2000);

            statsService.addUseQueryClick({
               destination: extension ? 'extension' : 'app',
               query: queryVersion.steps?.map((s) => s.queryText)?.join(';\n') ?? '',
               workspaceId,
               queryVersionId: queryVersion.id,
               source,
            });
         };

         return (
            <OverlayTrigger
               overlay={
                  queryVersion.description && !noHover ? (
                     <Popover>
                        <div className="fs-11p fw-normal p-2">{queryVersion.description}</div>
                     </Popover>
                  ) : (
                     <></>
                  )
               }
               placement={popoverPlacement}
            >
               <Stack
                  className={classNames('border-0 query-card card pt-3 pb-2 px-2', {
                     'query-card-highlight': highlight,
                     'query-card-hover': action !== 'none',
                  })}
                  gap={0}
                  onClick={onAction}
                  ref={ref}
               >
                  <QueryHeader
                     basedOnVersion={basedOnVersion}
                     collapse={collapse === true}
                     queryVersion={queryVersion}
                  />

                  {(extraActions || queryVersion.modified || queryVersion.createdByPerson) && (
                     <div className={`d-flex ${diffVersion ? '' : 'justify-content-between'}`}>
                        <div className={`${diffVersion ? 'w-50' : ''}`}>
                           <QueryFooter
                              queryVersion={
                                 !diffVersion
                                    ? queryVersion
                                    : diffVersion.modified! < queryVersion.modified!
                                    ? diffVersion
                                    : queryVersion
                              }
                              runtime={runtime}
                           />
                        </div>
                        <div className={`${diffVersion ? 'w-50' : ''}`}>
                           <Stack
                              className={
                                 diffVersion ? 'justify-content-between' : 'justify-content-end'
                              }
                              direction="horizontal"
                              gap={2}
                           >
                              {diffVersion && (
                                 <QueryFooter
                                    queryVersion={
                                       diffVersion.modified! < queryVersion.modified!
                                          ? queryVersion
                                          : diffVersion
                                    }
                                 />
                              )}
                           </Stack>
                        </div>
                     </div>
                  )}

                  <div className="query-card-top-right">
                     <Button
                        className="query-card-action border-0 fs-10p"
                        size="sm"
                        variant="white"
                     >
                        {label}&nbsp;{icon}
                     </Button>
                  </div>
                  {queryVersion?.steps?.map((step, index) => (
                     <div className="queryFontSmall cm-editor" key={step.id ?? index}>
                        <div
                           className="card border-0 queryCardCode"
                           style={{ position: 'relative' }}
                        >
                           {diffVersion &&
                              (diffSide === 'new' ? (
                                 <DiffViewer
                                    newCode={step.queryText ?? ''}
                                    oldCode={diffVersion.steps[index]?.queryText ?? ''}
                                 />
                              ) : (
                                 <DiffViewer
                                    newCode={diffVersion.steps[index]?.queryText ?? ''}
                                    oldCode={step.queryText ?? ''}
                                 />
                              ))}
                           {!diffVersion && (
                              <CodeViewer
                                 collapseTo={collapse ? MAX_QUERY_LENGTH : undefined}
                                 comments={step.comments}
                                 dialect={step.dataConnection?.dbms}
                                 query={step.queryText}
                              />
                           )}
                        </div>
                     </div>
                  ))}

                  {(extraActions || queryVersion.modified || queryVersion.createdByPerson) && (
                     <div className={`d-flex ${diffVersion ? '' : 'justify-content-between'}`}>
                        <div className={`${diffVersion ? 'w-50' : ''}`}></div>
                        <div className={`${diffVersion ? 'w-50' : ''}`}>
                           <Stack
                              className={
                                 diffVersion ? 'justify-content-between' : 'justify-content-end'
                              }
                              direction="horizontal"
                              gap={2}
                           >
                              {diffVersion && <div></div>}
                              {extraActions && (
                                 <Stack
                                    direction="horizontal"
                                    gap={2}
                                    onClick={(e) => e.stopPropagation()}
                                 >
                                    <button
                                       className={`btn btn-sm ${
                                          action === 'copy' ? 'action-copy-button' : 'copy-button'
                                       } ${showCopySuccess ? 'btn-success' : 'btn-dark'}`}
                                       onClick={(e) => {
                                          e.stopPropagation();
                                          copyAction();
                                       }}
                                       title="Copy"
                                    >
                                       {showCopySuccess ? (
                                          <div style={{ display: 'flex', gap: '0.25rem' }}>
                                             <BiCheck />
                                             Copied!
                                          </div>
                                       ) : (
                                          <div style={{ display: 'flex', gap: '0.25rem' }}>
                                             <BiCopy />
                                             Copy
                                          </div>
                                       )}
                                    </button>
                                    {extraActions}
                                 </Stack>
                              )}
                           </Stack>
                        </div>
                     </div>
                  )}
                  {children}
                  <Collapse in={showComments}>
                     <div>
                        {showComments && (
                           <QueryComments canPost={false} queryVersion={queryVersion} />
                        )}
                     </div>
                  </Collapse>
               </Stack>
            </OverlayTrigger>
         );
      }
   )
);

export const QueryLogWidget = memo(
   ({
      queryLogs,
      runtime,
      collapse,
      extraActions,
      diffLogs,
   }: {
      collapse?: boolean;
      currentVersion?: QueryVersion;
      diffLogs?: QueryLog[];
      extraActions?: React.ReactNode[];
      previousLogs?: QueryLog[];
      queryLogs?: QueryLog[];
      runtime?: number;
   }): JSX.Element => {
      const queryVersion = queryLogs ? createQueryVersionFromLogs(queryLogs) : undefined;
      const diffVersion = diffLogs ? createQueryVersionFromLogs(diffLogs) : undefined;
      if (!queryVersion) return <></>;
      return (
         <QueryWidget
            collapse={collapse}
            diffVersion={diffVersion}
            extraActions={extraActions}
            queryVersion={queryVersion}
            runtime={runtime}
            source="log"
            workspaceId={queryLogs?.[0]?.workspaceId}
         />
      );
   }
);

export default QueryWidget;
