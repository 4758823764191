import { useState } from 'react';
import { Button, Stack } from 'react-bootstrap';
import { SourceNav, Page } from '../../components';
import LoadingError from '../../components/UI/LoadingError';
import LoadingSpinner from '../../components/UI/LoadingSpinner';
import SearchInput from '../../components/UI/SearchInput';
import { DataConnection } from '../../entities';
import { useListDataConnectionsQuery } from '../../hooks/entities/dataConnectionHooks';
import { DataConnectionListType } from '../../services';
import AddConnectionWizard from './AddConnectionWizard/AddConnectionWizard';
import { DataConnectionCard } from './DataConnectionCard/DataConnectionCard';
import { useTitle } from '../../utilities';

export const DataConnectionsPage = (): JSX.Element => {
   useTitle('Data Sources');

   //State variables
   const [searchText, setSearchText] = useState('');
   const [showAddModal, setShowAddModal] = useState(false);

   //Queries
   const dataConnections = useListDataConnectionsQuery({ listType: DataConnectionListType.MINE });

   //Page Functions
   const filterConnectionsByText = (
      value: DataConnection,
      index: number,
      array: DataConnection[]
   ) => {
      //check the name
      if (value.name && value.name.toLowerCase().includes(searchText)) {
         return true;
      }

      //Check the description
      if (value.description?.toLowerCase().includes(searchText)) {
         return true;
      }
   };

   if (dataConnections.isLoading) {
      return <LoadingSpinner />;
   } else if (dataConnections.isError) {
      return LoadingError({});
   }

   return (
      <Page
         header={
            <Stack className="justify-content-between w-100" direction="horizontal">
               <Stack className="flex-grow-0 flex-shrink-0" direction="horizontal" gap={2}>
                  <div className="text-nowrap">Data Sources & Metadata</div>
               </Stack>
               <Button className="fs-10p" onClick={() => setShowAddModal(true)} size="sm">
                  Add Data Source
               </Button>
            </Stack>
         }
         nav={<SourceNav />}
      >
         <Stack gap={2}>
            <SearchInput
               entityName="data sources"
               onTextChanged={(newText) => setSearchText(newText.toLowerCase())}
            />
            {dataConnections.data
               ?.filter(filterConnectionsByText)
               .map((connection) =>
                  connection.id ? (
                     <DataConnectionCard dataConnectionId={connection.id} key={connection.id} />
                  ) : (
                     <></>
                  )
               )}
         </Stack>
         <AddConnectionWizard onClose={() => setShowAddModal(false)} show={showAddModal} />
      </Page>
   );
};

export default DataConnectionsPage;
