import { useEffect, useState, useRef } from 'react';
import { Nav, NavItem, NavLink, TabContainer, TabContent, TabPane } from 'react-bootstrap';

import {
   QueryDocs,
   QueryLogList,
   QueryVersionList,
   QuerySuggestions,
   QueryAdmin,
   RunSource,
   AiPulse,
} from '../components';
import { QueryParameter, QueryVersion, StepType } from '../entities';
import { QueryReturn } from '../interfaces';
import { QueryComments } from './QueryComments';
import QueryParams from './QueryParams';
import QueryResultsTabs from './QueryResultsTabs';
import { QueryChart } from './QueryChart';
import { useCurrentStep } from '../hooks';
import { useWalkthroughStep } from '../hooks/walkthrough';
import { walkthroughStep } from '../entities/Walkthrough';
import { useQueryPanelContext } from '../hooks/QueryPanelContext';
import { RiExpandUpDownLine } from 'react-icons/ri';
import { BiCollapseVertical } from 'react-icons/bi';
import AskRuna from './AskRuna';

const tabs = {
   comments: 'Comments',
   docs: 'Documentation',
   runSource: 'runSource',
   versions: 'Versions',
   logs: 'Logs',
   admin: 'Admin',
   gap: null,
   runa: 'Ask Runa',
   suggestions: 'Suggestions',
   params: 'Parameters',
   results: 'Data Results',
   chart: 'Chart',
};

export type Tab = {
   body: React.ReactNode;
   title: string;
};

export const QueryPanel = ({
   collapsePanel,
   defaultTab,
   expandPanel,
   extraTabs,
   generating,
   hideTabs,
   onChangeDocumentation,
   onClickGenerateDocumentation,
   queryResults,
   queryVersion,
   saveDraft,
   readOnly,
}: {
   collapsePanel?: () => void;
   defaultTab?: keyof typeof tabs;
   expandPanel?: () => void;
   extraTabs?: Tab[];
   generating?: boolean;
   hideTabs?: {
      [K in keyof typeof tabs]?: boolean;
   };
   onChangeDocumentation?: (params: {
      description?: string;
      question?: string;
      title?: string;
   }) => void;
   onClickGenerateDocumentation?: React.MouseEventHandler<HTMLButtonElement>;
   queryResults?: QueryReturn[];
   queryVersion: QueryVersion;
   readOnly?: boolean;
   saveDraft?: ({
      debounce,
      parameters,
   }: {
      debounce?: boolean;
      parameters?: QueryParameter[];
   }) => Promise<QueryVersion | undefined>;
}): JSX.Element => {
   const containsPython = queryVersion?.steps
      ? queryVersion.steps.some((step) => step.type === StepType.PYTHON)
      : false;
   const activeTabs = {
      ...Object.fromEntries((extraTabs ?? []).map((t) => [t.title, t.title])),
      ...Object.fromEntries(
         Object.entries(tabs).filter(([id]) => !(hideTabs?.[id as keyof typeof tabs] === true))
      ),
   };

   const [tab, setTab] = useState<string>(
      defaultTab && Object.keys(activeTabs).includes(defaultTab)
         ? defaultTab
         : Object.keys(activeTabs)[0]
   );
   const currentStep = useCurrentStep();
   const [stepThree] = useWalkthroughStep(walkthroughStep.SUGGESTED_QUERY);
   const [stepFive, setStepFive] = useWalkthroughStep(walkthroughStep.CHART_TAB);
   const [stepTen, setStepTen] = useWalkthroughStep(walkthroughStep.VERSIONS);
   const { expanded, setExpanded } = useQueryPanelContext();

   const setSteps = () => {
      if (stepFive) {
         setStepFive();
      } else if (stepTen) {
         setStepTen();
      }
   };

   // if query result changes, focus results tab
   useEffect(() => {
      if (queryResults) setTab('results');
   }, [queryResults]);

   // If we add the second workflow step, focus to params
   const lastStepCount = useRef(queryVersion.steps.length);
   useEffect(() => {
      if (lastStepCount.current === 1 && queryVersion.steps.length === 2) {
         setTab('params');
      }
      lastStepCount.current = queryVersion.steps.length;
   }, [queryVersion.steps.length]);

   // If the default tab changes - Suggestions
   defaultTab ??= Object.keys(activeTabs)[0] as keyof typeof tabs;
   useEffect(() => {
      if (defaultTab) {
         setTab(defaultTab);
      }
   }, [defaultTab]);

   // Set active tab to docs when docs are generated.
   const lastQueryVersion = useRef(queryVersion);
   useEffect(() => {
      if (generating) {
         setTab('docs');
      }
      if (
         (queryVersion.generatedTitle && lastQueryVersion.current.title !== queryVersion.title) ||
         (queryVersion.generatedDescription &&
            lastQueryVersion.current.description !== queryVersion.description) ||
         (queryVersion.generatedQuestion && lastQueryVersion.current.query !== queryVersion.query)
      ) {
         lastQueryVersion.current = queryVersion;
      }
   }, [queryVersion, generating]);
   const isWalkthroughTab = (name: string) => {
      return (
         (name === 'Chart' && stepFive) ||
         (name === 'Versions' && stepTen) ||
         (name === 'Suggestions' && stepThree)
      );
   };

   if (!queryVersion.query?.id) return <></>;

   const triggerExpand = (e: React.MouseEvent) => {
      e.preventDefault();
      if (setExpanded !== undefined && expandPanel !== undefined && collapsePanel !== undefined) {
         if (expanded) {
            collapsePanel();
            setExpanded(false);
         } else {
            expandPanel();
            setExpanded(true);
         }
      }
   };

   return (
      <div className="d-flex flex-column h-100">
         <TabContainer activeKey={tab} onSelect={(t) => t && setTab(t)} transition={false}>
            <Nav
               className="bottom-explore-tabs explore-tabs bg-secondary"
               id="exploreTabNavQuery"
               role="tablist"
            >
               {Object.entries(activeTabs).map(([id, name]) =>
                  name ? (
                     <AiPulse
                        key={id}
                        on={isWalkthroughTab(name) ? stepFive || stepTen || stepThree : false}
                        onClick={isWalkthroughTab(name) ? () => setSteps() : undefined}
                     >
                        <NavItem
                           bsPrefix="explore-tab"
                           className={`bottom-explore-tab ${tab === id ? 'active' : ''}`}
                        >
                           <NavLink
                              className={`position-relative override-active-pointer plausible-event-name--queryPanel${name}`}
                              eventKey={id}
                              role="tab"
                           >
                              {name}
                           </NavLink>
                        </NavItem>
                     </AiPulse>
                  ) : (
                     <div className="flex-grow-1" key={id} />
                  )
               )}
               <div className="bottom-explore-tab  explore-tab">
                  <button
                     className="expand-button position-relative override-active-pointer plausible-event-name--queryPanelChart nav-link"
                     onClick={triggerExpand}
                  >
                     {expanded ? (
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '0.25rem' }}>
                           <BiCollapseVertical />
                        </div>
                     ) : (
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '0.25rem' }}>
                           <RiExpandUpDownLine />
                        </div>
                     )}
                  </button>
               </div>
            </Nav>
            <TabContent className="flex-grow-1 overflow-hidden pt-3 bottom-query-panel-tab-content">
               {(extraTabs ?? []).map((t, i) => (
                  <TabPane className="h-100 overflow-auto p-2" eventKey={t.title} key={i}>
                     {t.body}
                  </TabPane>
               ))}
               <TabPane className="h-100 overflow-auto p-2" eventKey="runSource">
                  <RunSource queryVersion={queryVersion} readOnly={readOnly} />
               </TabPane>
               <TabPane className="h-100 overflow-auto p-2" eventKey="admin">
                  <QueryAdmin queryVersion={queryVersion} />
               </TabPane>
               <TabPane className="h-100 overflow-auto p-2" eventKey="versions">
                  <QueryVersionList
                     collapse={false}
                     currentVersion={queryVersion}
                     enabled={tab === 'versions'}
                     forkedFromVersion={queryVersion.query?.forkedFromVersion}
                     pageSize={10}
                     queryId={queryVersion.query?.id}
                  />
               </TabPane>{' '}
               <TabPane className="h-100 overflow-auto p-2" eventKey="logs">
                  <QueryLogList
                     collapse={false}
                     currentVersion={queryVersion}
                     enabled={tab === 'logs'}
                     pageSize={10}
                     queryId={queryVersion.query.id}
                  />
               </TabPane>
               <TabPane className="h-100 overflow-auto p-2" eventKey="params">
                  <QueryParams />
               </TabPane>
               <TabPane className="h-100 overflow-hidden" eventKey="results" key="results">
                  <QueryResultsTabs queryResults={queryResults} />
               </TabPane>
               <TabPane className="h-100 overflow-auto p-2" eventKey="chart" key="chart">
                  <AiPulse on={stepFive}>
                     <QueryChart
                        allowChartGeneration={!readOnly}
                        containsPython={containsPython}
                        isCurrentPanel={tab === 'chart'}
                        queryReturn={
                           queryResults?.length ? queryResults[queryResults.length - 1] : undefined
                        }
                        queryVersion={queryVersion}
                     />
                  </AiPulse>
               </TabPane>
               <TabPane className="h-100 overflow-auto p-2" eventKey="comments">
                  <QueryComments queryVersion={queryVersion} />
               </TabPane>
               <TabPane className="h-100 overflow-auto p-2" eventKey="docs">
                  <QueryDocs
                     generating={generating}
                     onChangeDocumentation={onChangeDocumentation}
                     onClickGenerateDocumentation={onClickGenerateDocumentation}
                     queryVersion={queryVersion}
                     readOnly={readOnly}
                  />
               </TabPane>
               <TabPane className="h-100 overflow-auto p-2 " eventKey="runa">
                  <div className="d-flex h-100">
                     <AskRuna />
                  </div>
               </TabPane>
               <TabPane className="p-2 h-100 overflow-auto" eventKey="suggestions">
                  <QuerySuggestions
                     query={queryVersion}
                     step={currentStep?.queryStep}
                     workspaceId={queryVersion.query.workspaceId}
                  />
               </TabPane>
            </TabContent>
         </TabContainer>
      </div>
   );
};

export default QueryPanel;
