import classNames from 'classnames';

import { GlobalNavRail, Navbar, PageHeader, PageTab, PageTabs } from '../components';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';

export const Page = ({
   bodyPadding,
   children,
   header,
   headerPadding,
   nav,
   tabs,
}: {
   bodyPadding?: boolean;
   children?: React.ReactNode;
   header?: React.ReactNode;
   headerPadding?: boolean;
   nav?: React.ReactNode;
   tabs?: PageTab[];
}) => {
   bodyPadding ??= tabs ? false : true;
   headerPadding ??= true;
   return (
      <div className="d-flex flex-column h-100 overflow-hidden">
         <div className="d-flex flex-row w-100 flex-grow-1 overflow-hidden">
            <GlobalNavRail />
            <div className="flex-grow-1 overflow-hidden d-flex flex-column">
               {header && <PageHeader>{header}</PageHeader>}
               <PanelGroup autoSaveId="page" direction="horizontal">
                  {nav && (
                     <>
                        <Panel
                           className="h-100 starting-block-panel"
                           defaultSize={15}
                           id="secondary"
                           minSize={5}
                        >
                           <Navbar>{nav}</Navbar>
                        </Panel>
                        <PanelResizeHandle className="panelHandle vertical">
                           <div className="panelHandleLine" />
                        </PanelResizeHandle>
                     </>
                  )}
                  <Panel defaultSize={85} id="primary" minSize={45}>
                     <div
                        className={classNames('h-100  w-100 overflow-auto query-page-background', {
                           'p-3': bodyPadding,
                        })}
                     >
                        {tabs && <PageTabs tabs={tabs} />}
                        {children}
                     </div>
                  </Panel>
               </PanelGroup>
            </div>
         </div>
      </div>
   );
};

export default Page;
