import { useInjection } from 'inversify-react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { DataCredential } from '../../entities/DataCredentials';
import { QueryKey, QueryKeyType } from '../../enums';
import {
   DataCredentialService,
   listOptionsDataCredential,
} from '../../services/DataCredentialService';
import { TYPES } from '../../types';
import { handleError, notUndefined } from '../../utilities';
import { getWorkspaceQueryKey } from './workspaceHooks';

export const useListCredentials = (
   filters?: listOptionsDataCredential,
   callbacks?: {
      errorCallback?: (error: unknown) => void;
      successCallback?: (data: DataCredential[]) => void;
   }
) => {
   const dataCredentialService = useInjection<DataCredentialService>(TYPES.dataCredentialService);
   return useQuery<DataCredential[]>(
      getQueryKey({ type: QueryKeyType.LIST, filters: filters }),
      () => dataCredentialService.listOptions(filters),
      {
         keepPreviousData: true,
         refetchOnWindowFocus: false,
         refetchOnMount: true,
         retry: false,
         onSuccess: callbacks?.successCallback,
         onError: callbacks?.errorCallback ?? handleError,
      }
   );
};

export const useUpdateDataCredential = (callbacks?: {
   onSettledCallback?: (
      data: DataCredential | undefined,
      error: unknown,
      variables: DataCredential,
      context: unknown
   ) => void;
}) => {
   const queryClient = useQueryClient();
   const dataCredentialService = useInjection<DataCredentialService>(TYPES.dataCredentialService);

   return useMutation({
      mutationFn: async (updatedCredential: DataCredential) => {
         return dataCredentialService.patch(notUndefined(updatedCredential.id), updatedCredential);
      },
      onSuccess: async (updatedConnection) => {
         if (updatedConnection !== undefined) {
            const promises = [
               queryClient.invalidateQueries(
                  getQueryKey({
                     type: QueryKeyType.GET,
                     id: notUndefined(updatedConnection?.id),
                  })
               ),
               queryClient.invalidateQueries(getQueryKey({ type: QueryKeyType.LIST })),
            ];

            await Promise.all(promises);
         }
      },
      onSettled(data, error, variables, context) {
         if (callbacks?.onSettledCallback)
            callbacks.onSettledCallback(data, error, variables, context);
      },
   });
};

interface NewDataCredentialParameters {
   newCredential: DataCredential;
   querySavedResultsId?: number | undefined;
   workspaceId?: number | undefined;
}

export const useNewDataCredentialMutator = (callbacks?: {
   onSettledCallback?: (
      data: DataCredential | undefined,
      error: unknown,
      variables: NewDataCredentialParameters,
      context: unknown
   ) => void;
   onSuccess?: (
      newCredential: DataCredential | undefined,
      variables: NewDataCredentialParameters,
      context: unknown
   ) => void;
}) => {
   const queryClient = useQueryClient();
   const dataCredentialService = useInjection<DataCredentialService>(TYPES.dataCredentialService);

   return useMutation({
      mutationFn: async (variables: NewDataCredentialParameters) => {
         return dataCredentialService.post(variables.newCredential);
      },
      async onSuccess(newCredential, variables, context) {
         if (newCredential) {
            const promiseList: Promise<any>[] = [
               queryClient.invalidateQueries(getQueryKey({ type: QueryKeyType.LIST })),
               queryClient.invalidateQueries([QueryKey.DataConnection, QueryKeyType.LIST]),
            ];

            if (variables.workspaceId !== undefined) {
               promiseList.push(
                  queryClient.invalidateQueries(
                     getWorkspaceQueryKey({
                        type: QueryKeyType.GET,
                        id: variables.workspaceId,
                     })
                  )
               );
            }

            if (variables.querySavedResultsId !== undefined) {
               promiseList.push(
                  queryClient.invalidateQueries([
                     QueryKey.QueryResult,
                     variables.querySavedResultsId,
                  ])
               );
            }

            await Promise.all(promiseList);

            if (callbacks?.onSuccess) {
               callbacks.onSuccess(newCredential, variables, context);
            }
         }
      },
      onSettled(data, error, variables, context) {
         if (callbacks?.onSettledCallback)
            callbacks.onSettledCallback(data, error, variables, context);
      },
   });
};

export const useDeleteDataCredentialMutator = () => {
   const queryClient = useQueryClient();
   const dataCredentialService = useInjection<DataCredentialService>(TYPES.dataCredentialService);

   return useMutation({
      mutationFn: async (id: number) => {
         return dataCredentialService.delete(id);
      },
      async onSuccess(data, id, context) {
         const promises = [
            queryClient.invalidateQueries(getQueryKey({ type: QueryKeyType.GET, id: id })),
            queryClient.invalidateQueries(getQueryKey({ type: QueryKeyType.LIST })),
            queryClient.invalidateQueries([QueryKey.DataConnection, QueryKeyType.LIST]),
         ];

         await Promise.all(promises);
      },
      onError: handleError,
   });
};

function getQueryKey(keyParams: {
   filters?: listOptionsDataCredential;
   id?: number;
   type: QueryKeyType;
}) {
   const queryKey: any[] = ['dataCredential', keyParams.type];
   if (keyParams.id !== undefined) {
      queryKey.push(keyParams.id);
   }

   if (keyParams.filters !== undefined) {
      queryKey.push(keyParams.filters);
   }

   return queryKey;
}
