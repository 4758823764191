import { inject, injectable } from 'inversify';
import { PaginatedResult, SchemaCache } from '../entities';
import { QueryReturn } from '../interfaces';
import { TYPES } from '../types';
import { ApiService } from './ApiService';
import { ApiServiceInterface } from './ApiServiceInterface';

const path = '/v1/schemaCache';

export interface BulkUpdateSchemaCache {
   description?: string;
   exampleData?: string;
   id: number;
}

export interface GetTableDataResponse {
   columns?: SchemaCache[];
   indexes: SchemaCache[];
   missing_cred?: boolean;
   relations?: SchemaCache[];
   result: QueryReturn;
   tableCache: SchemaCache;
}
@injectable()
export class SchemaCacheService implements ApiServiceInterface<SchemaCache> {
   private apiService: ApiService;

   constructor(@inject(TYPES.apiService) apiService: ApiService) {
      this.apiService = apiService;
   }

   async bulkUpdate(dataConnectionId: number, schemaCache: BulkUpdateSchemaCache[]): Promise<null> {
      const body = {
         dataConnectionId,
         schemaCache,
      };
      return await this.apiService.patch<null>(`${path}/bulkUpdate`, body);
   }

   async getTableData(
      tableSchemaId: number,
      workspaceId?: number,
      exploreTabId?: number,
      getIndex?: boolean
   ): Promise<GetTableDataResponse | undefined> {
      const params: Record<string, string> = {};
      params['getIndexes'] = getIndex ? '1' : '0';
      if (workspaceId) {
         params['workspaceId'] = workspaceId.toString();
      }
      if (exploreTabId) {
         params['exploreTabId'] = exploreTabId.toString();
      }

      const result = await this.apiService.get<GetTableDataResponse | undefined>(
         `${path}/${tableSchemaId}/getData`,
         params
      );

      if (!result) {
         return undefined;
      }
      result.columns?.forEach((column) => {
         column.columnIsPrimaryKey = (column.columnIsPrimaryKey as any) === 1;
      });
      result.indexes.forEach((index) => {
         index.columnIsPrimaryKey = (index.columnIsPrimaryKey as any) === 1;
         index.indexNonUnique = (index.indexNonUnique as any) === 1;
      });
      return result;
   }

   delete(id: number | string): Promise<null> {
      throw new Error('Method not implemented.');
   }
   get(id: number | string): Promise<any> {
      throw new Error('Method not implemented.');
   }
   list(params: Record<string, string>): Promise<SchemaCache[] | PaginatedResult<SchemaCache>> {
      throw new Error('Method not implemented.');
   }
   listOptions(filter?: Object): Promise<SchemaCache[] | PaginatedResult<SchemaCache>> {
      throw new Error('Method not implemented.');
   }
   patch(id: number | string, body: SchemaCache, params?: Record<string, string>): Promise<any> {
      throw new Error('Method not implemented.');
   }
   post(body: SchemaCache, params?: Record<string, string>): Promise<any> {
      throw new Error('Method not implemented.');
   }
   put(body: SchemaCache, params?: Record<string, string>): Promise<any> {
      throw new Error('Method not implemented.');
   }
}
