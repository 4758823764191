import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { Form, OverlayTrigger } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import LoadingError from '../../../../components/UI/LoadingError';
import LoadingSpinner from '../../../../components/UI/LoadingSpinner';
import { ConnectionAccessType } from '../../../../entities';
import { ADMIN_ROLES, DBMS } from '../../../../enums';
import { useGetAuthorizedExplorerQuery, useGetDataConnectionQuery } from '../../../../hooks';
import { getErrorMessage, IconInformation } from '../../../../utilities';
import { connectionName, description, host, password, port, username } from '../validators';
import { tooltipSharedConnection } from './ConnectionDetailsForm';
import HideDetailsLabel from './HideDetailsLabel';

export const mssqlDetailSchema = z.object({
   dbms: z.literal(DBMS.MSSQL),
   connectionName: connectionName,
   description: description,
   host: host,
   port: port,
   database: z.string().trim().optional(),
   sharedConnection: z.boolean().default(false),
   hideDetails: z.boolean().default(false),
   accountName: username,
   password: password,
});

export type MSSQLDetailFormData = z.infer<typeof mssqlDetailSchema>;

function MSSqlDetailForm({
   editType,
   formId,
   onSaveStateChange,
   onSubmit,
   selectedConnectionId,
}: {
   editType: 'connection' | 'credential' | 'read-only';
   formId: string;
   onSaveStateChange?: (state: 'clean' | 'dirty') => void;
   onSubmit?: (data: MSSQLDetailFormData) => void;
   selectedConnectionId?: number;
}) {
   const { register, handleSubmit, formState, reset } = useForm<MSSQLDetailFormData>({
      resolver: zodResolver(mssqlDetailSchema),
      mode: 'onTouched',
   });
   const errors = formState.errors;
   const touchedFields = formState.touchedFields;

   // State variables
   const [explorerIsAdmin, setUserIsAdmin] = useState(false);

   // Queries
   const selectedConnectionQuery = useGetDataConnectionQuery({
      id: selectedConnectionId,
      getOptions: { includeCredentials: true },
   });
   const authPersonQuery = useGetAuthorizedExplorerQuery();

   // Effects
   useEffect(() => {
      if (selectedConnectionQuery.data) {
         const formData: MSSQLDetailFormData = {
            dbms: DBMS.MSSQL,
            connectionName: selectedConnectionQuery.data.name ?? '',
            description: selectedConnectionQuery.data.description,
            host: selectedConnectionQuery.data.dbHost ?? '',
            port: parseInt(selectedConnectionQuery.data.dbPort ?? ''),
            database: selectedConnectionQuery.data.dbName,
            sharedConnection:
               selectedConnectionQuery.data.connectionAccessType === ConnectionAccessType.SHARED,
            hideDetails:
               (selectedConnectionQuery.data.hideDetails as unknown as number) === 1 ? true : false,
            accountName: selectedConnectionQuery.data.dataCredentials?.[0].accountName ?? '',
            password: 'CURRENT',
         };
         reset(formData);
      }
   }, [selectedConnectionQuery.data, reset]);

   useEffect(() => {
      if (
         authPersonQuery.data?.person.role &&
         ADMIN_ROLES.includes(authPersonQuery.data.person.role)
      ) {
         setUserIsAdmin(true);
      } else {
         setUserIsAdmin(false);
      }
   }, [authPersonQuery.data?.person.role]);

   useEffect(() => {
      const isDirtyAlt = !!Object.keys(formState.dirtyFields).length;
      if (isDirtyAlt) {
         onSaveStateChange?.('dirty');
      } else {
         onSaveStateChange?.('clean');
      }
   }, [formState, onSaveStateChange]);

   // Page functions
   const handleOnSubmit = (data: MSSQLDetailFormData) => {
      if (onSubmit) {
         onSubmit(data);
      }
   };

   // Render
   if (authPersonQuery.isLoading || selectedConnectionQuery.isLoading) return <LoadingSpinner />;
   if (authPersonQuery.isError)
      return <LoadingError message={getErrorMessage(authPersonQuery.error)} />;
   if (selectedConnectionQuery.isError)
      return <LoadingError message={getErrorMessage(selectedConnectionQuery.error)} />;

   return (
      <Form id={formId} onSubmit={handleSubmit(handleOnSubmit)}>
         <input type="hidden" {...register('dbms')} value={DBMS.MSSQL} />
         <Form.Group className="mb-3">
            <Form.Label>
               Connection Name <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
               {...register('connectionName')}
               disabled={editType !== 'connection'}
               isInvalid={touchedFields.connectionName && !!errors.connectionName}
               isValid={touchedFields.connectionName && !errors.connectionName}
               placeholder="Connection Name"
               required
            />
            <Form.Control.Feedback type="invalid">
               {errors.connectionName?.message}
            </Form.Control.Feedback>
         </Form.Group>
         <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
               {...register('description')}
               as="textarea"
               disabled={editType !== 'connection'}
               isInvalid={touchedFields.description && !!errors.description}
               isValid={touchedFields.description && !errors.description}
               placeholder="Description"
               rows={3}
            />
            <Form.Control.Feedback type="invalid">
               {errors.description?.message}
            </Form.Control.Feedback>
         </Form.Group>
         <Form.Group className="mb-3">
            <Form.Label>
               Host <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
               {...register('host')}
               disabled={editType !== 'connection'}
               isInvalid={touchedFields.host && !!errors.host}
               isValid={touchedFields.host && !errors.host}
               placeholder="Host"
               required
            />
            <Form.Control.Feedback type="invalid">{errors.host?.message}</Form.Control.Feedback>
         </Form.Group>
         <Form.Group className="mb-3">
            <Form.Label>
               Port <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
               {...register('port', { valueAsNumber: true })}
               defaultValue="1433"
               disabled={editType !== 'connection'}
               isInvalid={touchedFields.port && !!errors.port}
               isValid={touchedFields.port && !errors.port}
               placeholder="Port"
               required
            />
            <Form.Control.Feedback type="invalid">{errors.port?.message}</Form.Control.Feedback>
         </Form.Group>
         <Form.Group className="mb-3">
            <Form.Label>Database</Form.Label>
            <Form.Control
               {...register('database')}
               disabled={editType !== 'connection'}
               isInvalid={touchedFields.database && !!errors.database}
               isValid={touchedFields.database && !errors.database}
               placeholder="Database"
            />
            <Form.Control.Feedback type="invalid">{errors.database?.message}</Form.Control.Feedback>
         </Form.Group>
         <Form.Group className="mb-3">
            <Form.Label>User Name</Form.Label> <span className="text-danger">*</span>
            <Form.Control
               {...register('accountName')}
               disabled={editType === 'read-only'}
               isInvalid={touchedFields.accountName && !!errors.accountName}
               isValid={touchedFields.accountName && !errors.accountName}
               placeholder="User Name"
               required={true}
            />
            <Form.Control.Feedback type="invalid">
               {errors.accountName?.message}
            </Form.Control.Feedback>
         </Form.Group>
         <Form.Group className="mb-3">
            <Form.Label>Password</Form.Label> <span className="text-danger">*</span>
            <Form.Control
               {...register('password')}
               disabled={editType === 'read-only'}
               isInvalid={touchedFields.password && !!errors.password}
               isValid={touchedFields.password && !errors.password}
               placeholder="Password"
               required={true}
               type="password"
            />
            <Form.Control.Feedback type="invalid">{errors.password?.message}</Form.Control.Feedback>
         </Form.Group>
         {explorerIsAdmin && (
            <>
               <Form.Group>
                  <Form.Label>
                     Shared Connection
                     <OverlayTrigger overlay={tooltipSharedConnection} placement="auto">
                        <span>
                           <IconInformation size={16} />
                        </span>
                     </OverlayTrigger>
                  </Form.Label>
                  <Form.Check
                     {...register('sharedConnection')}
                     disabled={editType !== 'connection'}
                     type="switch"
                  />
               </Form.Group>
               <Form.Group className="mb-s">
                  <HideDetailsLabel />
                  <Form.Check
                     {...register('hideDetails')}
                     disabled={editType !== 'connection'}
                     type="switch"
                  />
               </Form.Group>
            </>
         )}
      </Form>
   );
}

export default MSSqlDetailForm;
