"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SchemaItemType = exports.SSHAuthenticaionMethod = exports.SSLMode = exports.ColumnNull = exports.DBMS_DATABASE_REQUIRED = exports.DBMS_HOST_REQUIRED = exports.DBMS = void 0;
var DBMS;
(function (DBMS) {
    DBMS["CHAT_GPT"] = "Chat GPT";
    DBMS["Federated"] = "Federated";
    DBMS["Hugging_Face"] = "Hugging Face";
    DBMS["MySQL"] = "MySQL";
    DBMS["Postgres"] = "Postgres";
    DBMS["Redshift"] = "Redshift";
    DBMS["Snowflake"] = "Snowflake";
    DBMS["MSSQL"] = "MSSQL";
    DBMS["Neo4j"] = "Neo4j";
    DBMS["Big_Query"] = "Big Query";
    DBMS["Databricks"] = "Databricks";
    DBMS["Oracle"] = "Oracle";
    DBMS["Trino"] = "Trino";
    DBMS["MongoDB"] = "MongoDB";
    DBMS["Python"] = "Python";
})(DBMS || (exports.DBMS = DBMS = {}));
exports.DBMS_HOST_REQUIRED = [
    DBMS.MySQL,
    DBMS.Redshift,
    DBMS.Postgres,
    DBMS.Hugging_Face,
    DBMS.MSSQL,
    DBMS.Neo4j,
    DBMS.Databricks,
    DBMS.MongoDB,
];
exports.DBMS_DATABASE_REQUIRED = [
    DBMS.Postgres,
    DBMS.Oracle,
    DBMS.MongoDB,
];
var ColumnNull;
(function (ColumnNull) {
    ColumnNull["NULL"] = "NULL";
    ColumnNull["NOT_NULL"] = "NOT NULL";
})(ColumnNull || (exports.ColumnNull = ColumnNull = {}));
var SSLMode;
(function (SSLMode) {
    SSLMode[SSLMode["OPTIONAL"] = 1] = "OPTIONAL";
    SSLMode[SSLMode["VALIDATED"] = 2] = "VALIDATED";
    // We could add support for not validating the certificate if needed.
})(SSLMode || (exports.SSLMode = SSLMode = {}));
var SSHAuthenticaionMethod;
(function (SSHAuthenticaionMethod) {
    SSHAuthenticaionMethod[SSHAuthenticaionMethod["PASSWORD"] = 1] = "PASSWORD";
    SSHAuthenticaionMethod[SSHAuthenticaionMethod["KEY_FILE"] = 2] = "KEY_FILE";
})(SSHAuthenticaionMethod || (exports.SSHAuthenticaionMethod = SSHAuthenticaionMethod = {}));
var SchemaItemType;
(function (SchemaItemType) {
    SchemaItemType["CATALOG"] = "catalog";
    SchemaItemType["SCHEMA"] = "schema";
    SchemaItemType["TABLE"] = "table";
    SchemaItemType["COLUMN"] = "column";
    SchemaItemType["INDEX"] = "index";
    SchemaItemType["STORED_PROCEDURE"] = "storedProcedure";
    SchemaItemType["TRIGGER"] = "trigger";
    SchemaItemType["RELATION"] = "relation";
})(SchemaItemType || (exports.SchemaItemType = SchemaItemType = {}));
