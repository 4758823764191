import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { DataConnection } from '../../../entities';
import { getShortDateString } from '../../../utilities';
import { ConnectionCardField } from './DataConnectionCard';

function BigQueryCard({ dataConnection }: { dataConnection: DataConnection }) {
   return (
      <Card
         as={Link}
         className="other-cards border-0 bg-secondary py-2 px-3"
         to={`/sources/${dataConnection.id}`}
      >
         <Row className="align-items-center">
            <ConnectionCardField bold label="Connection Name" value={dataConnection.name} xs={2} />
            <ConnectionCardField label="Host" value={dataConnection.dbHost} xs={4} />
            <ConnectionCardField label="Port" value={dataConnection.dbPort} xs={1} />
            <ConnectionCardField label="Type" value={dataConnection.dbms} xs={1} />
            <ConnectionCardField
               label="Added"
               value={getShortDateString(dataConnection.created)}
               xs={1}
            />
            <Col xs={2} />
         </Row>
      </Card>
   );
}

export default BigQueryCard;
