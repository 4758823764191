import { useCallback, useState } from 'react';
import {
   Button,
   Collapse,
   Modal,
   Nav,
   NavItem,
   NavLink,
   Stack,
   TabContainer,
   TabContent,
   TabPane,
} from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import { LoadingSpinner } from '../../../../components';
import CodeViewer from '../../../../components/UI/CodeViewer';
import { CollaboratorRole, ConnectionAccessType, ExploreTab } from '../../../../entities';
import { DBMS } from '../../../../enums';
import { ExploreTabContext, useExplorerWorkspaceRole, useExploreTab } from '../../../../hooks';
import { DataEditTab } from './DataEditTab';
import { SchemaColumnTab } from './SchemaColumnTab';
import { SchemaRelationsTab } from './SchemaRelationsTab';
import { SchemaIndexTab } from './SchemaIndexTab';

export const schemaEditSupported = (dbms: DBMS | undefined): boolean => {
   switch (dbms) {
      case DBMS.MySQL:
      case DBMS.MSSQL:
         return true;
      default:
         return false;
   }
};

const getReadOnly = (
   dbms: DBMS | undefined,
   connectionAccessType: ConnectionAccessType | undefined,
   role?: CollaboratorRole
): boolean => {
   if (role === CollaboratorRole.READER) {
      return true;
   }
   if (connectionAccessType === ConnectionAccessType.DEMO) {
      return true;
   }
   return !schemaEditSupported(dbms);
};

export enum TableEditTab {
   COLUMNS = 'columns',
   DATA = 'data',
   INDEXES = 'indexes',
   RELATIONS = 'relations',
}

export const ConfirmChangesModal = ({
   changeCount,
   onClose,
   onConfirm,
   query,
   running,
   show,
}: {
   changeCount?: number;
   onClose?: () => void;
   onConfirm?: () => void;
   query?: string[];
   running?: boolean;
   show: boolean;
}): JSX.Element => {
   const [showQuery, setShowQuery] = useState(false);
   const exploreTab = useExploreTab();
   return (
      <Modal
         onHide={() => {
            onClose?.();
         }}
         show={show}
         size="lg"
      >
         <Modal.Header closeButton>
            <Modal.Title className="fs-14p">Pending Data Changes</Modal.Title>
         </Modal.Header>
         <Modal.Body>
            <div className="row">
               <div className="col-12 fs-14p">
                  <div>
                     Database:&nbsp;
                     {(exploreTab?.tableSchema?.schemaName || exploreTab?.tableSchema?.tableName) &&
                        `${
                           exploreTab.tableSchema?.schemaName &&
                           `${exploreTab.tableSchema.schemaName}.`
                        }${exploreTab.tableSchema?.tableName}`}
                  </div>
                  <div>
                     Host:&nbsp;
                     {exploreTab?.tableSchema?.dataConnection?.name &&
                        `${exploreTab?.tableSchema?.dataConnection?.name}`}
                  </div>
                  <div>Changes: {changeCount && <span> ({changeCount} row(s) changed)</span>}</div>
               </div>
            </div>
            <div className="row">
               <div className="col-12 mt-5">
                  <div className="d-flex justify-content-end">
                     Are you sure you want to make these changes?
                  </div>
               </div>
            </div>
            <div className="row">
               <div className="col-12 d-flex justify-content-end mt-3">
                  <Button
                     className="btn btn-sm btn-secondary me-1"
                     onClick={() => {
                        setShowQuery(!showQuery);
                     }}
                  >
                     {showQuery ? `Hide` : `Show`} Query
                  </Button>
                  <Button
                     className="btn btn-sm btn-secondary me-1"
                     disabled={running}
                     onClick={onClose}
                  >
                     Cancel
                  </Button>
                  <Button className="btn btn-sm btn-primary" disabled={running} onClick={onConfirm}>
                     {running && <LoadingSpinner />}
                     Confirm Changes
                  </Button>
               </div>
            </div>
            <div className="row">
               <div className="col-12">
                  {query && query.length > 0 && (
                     <Collapse in={showQuery}>
                        <div className="card query-card border-1 mt-2 mb-4 p-4 fs-14p">
                           <CodeViewer query={query.join('\n')} />
                        </div>
                     </Collapse>
                  )}
               </div>
            </div>
         </Modal.Body>
      </Modal>
   );
};

const TableTabContent = ({
   exploreTab,
   onPendingUpdateChange,
   defaultTab,
}: {
   defaultTab?: TableEditTab;
   exploreTab: ExploreTab;
   onPendingUpdateChange?: (value: boolean) => void;
}): JSX.Element => {
   const [tabsPendingUpdate, _setTabsPendingUpdate] = useState<TableEditTab[]>([]);
   const [tabId, setTabId] = useState<string>(defaultTab || TableEditTab.DATA);
   const setPendingUpdate = useCallback(
      (tab: TableEditTab, update: boolean) => {
         _setTabsPendingUpdate((tabsPending) => {
            let tabs = tabsPending;
            let valueChanged = false;
            if (update && !tabsPending.includes(tab)) {
               tabs = [...tabsPending, tab];
               valueChanged = true;
            } else if (!update && tabsPending.includes(tab)) {
               tabs = tabsPending.filter((t) => t !== tab);
               valueChanged = true;
            }

            if (valueChanged) {
               //_setTabsPendingUpdate(tabs);
               if (tabs.length === 0) {
                  onPendingUpdateChange?.(false);
               } else {
                  onPendingUpdateChange?.(true);
               }
            }
            return tabs;
         });
      },
      [onPendingUpdateChange]
   );

   const test = useCallback(
      (update: boolean) => {
         setPendingUpdate(TableEditTab.DATA, update);
      },
      [setPendingUpdate]
   );

   const role = useExplorerWorkspaceRole(exploreTab.workspaceId);
   const readOnly = getReadOnly(
      exploreTab.tableSchema?.dataConnection.dbms,
      exploreTab.tableSchema?.dataConnection.connectionAccessType,
      role
   );

   if (!exploreTab.workspaceId) return <Navigate to="/" />;
   if (!exploreTab.tableSchemaId) {
      return <Navigate to={`/workspaces/${exploreTab.workspaceId}`} />;
   }

   return (
      <ExploreTabContext.Provider value={{ exploreTab }}>
         <TabContainer
            activeKey={tabId}
            onSelect={(eventKey) => setTabId(eventKey || TableEditTab.DATA)}
            transition={false}
         >
            <Stack className="h-100 overflow-hidden">
               <header className="d-flex flex-column flex-shrink-0">
                  <Nav
                     className="d-flex flex-row flex-nowrap align-items-center bg-secondary"
                     id="tableTabNav"
                     role="tablist"
                  >
                     <div className="explore-tabs explore-tabs-scrollable">
                        <NavItem
                           bsPrefix="explore-tab"
                           className={`top-explore-tabs ${tabId === 'data' ? 'active' : ''}`}
                           key="data"
                        >
                           <NavLink
                              className="position-relative override-active-pointer top-explore-tab-spacing"
                              eventKey={TableEditTab.DATA}
                              role="tab"
                           >
                              Data
                              {tabsPendingUpdate.includes(TableEditTab.DATA) && <span>*</span>}
                           </NavLink>
                        </NavItem>

                        {schemaEditSupported(exploreTab.tableSchema?.dataConnection.dbms) && (
                           <>
                              <NavItem
                                 bsPrefix="explore-tab"
                                 className={`top-explore-tabs ${
                                    tabId === 'columns' ? 'active' : ''
                                 }`}
                                 key="columns"
                              >
                                 <NavLink
                                    className="position-relative override-active-pointer top-explore-tab-spacing"
                                    eventKey={TableEditTab.COLUMNS}
                                    role="tab"
                                 >
                                    Columns
                                    {tabsPendingUpdate.includes(TableEditTab.COLUMNS) && (
                                       <span>*</span>
                                    )}
                                 </NavLink>
                              </NavItem>
                              <NavItem
                                 bsPrefix="explore-tab"
                                 className={`top-explore-tabs ${
                                    tabId === 'indexes' ? 'active' : ''
                                 }`}
                                 key="indexes"
                              >
                                 <NavLink
                                    className="position-relative override-active-pointer top-explore-tab-spacing"
                                    eventKey={TableEditTab.INDEXES}
                                    role="tab"
                                 >
                                    Indexes
                                    {tabsPendingUpdate.includes(TableEditTab.INDEXES) && (
                                       <span>*</span>
                                    )}
                                 </NavLink>
                              </NavItem>
                           </>
                        )}
                        {schemaEditSupported(exploreTab.tableSchema?.dataConnection.dbms) && (
                           <NavItem
                              bsPrefix="explore-tab"
                              className={`top-explore-tabs ${
                                 tabId === 'relations' ? 'active' : ''
                              }`}
                              key="relations"
                           >
                              <NavLink
                                 className="position-relative override-active-pointer top-explore-tab-spacing"
                                 eventKey={TableEditTab.RELATIONS}
                                 role="tab"
                              >
                                 Relations
                                 {tabsPendingUpdate.includes(TableEditTab.RELATIONS) && (
                                    <span>*</span>
                                 )}
                              </NavLink>
                           </NavItem>
                        )}
                     </div>
                  </Nav>
               </header>
               <TabContent className="flex-grow-1 overflow-hidden">
                  <TabPane className="h-100" eventKey="data">
                     <DataEditTab onStatusChange={test} readOnly={readOnly} />
                  </TabPane>

                  <TabPane className="h-100" eventKey="columns">
                     <SchemaColumnTab
                        onStatusChange={(update) => setPendingUpdate(TableEditTab.COLUMNS, update)}
                        readOnly={readOnly}
                     />
                  </TabPane>
                  <TabPane className="h-100" eventKey="indexes">
                     <SchemaIndexTab
                        onStatusChange={(update) => setPendingUpdate(TableEditTab.INDEXES, update)}
                        readOnly={readOnly}
                     />
                  </TabPane>
                  <TabPane className="h-100" eventKey="relations">
                     <SchemaRelationsTab
                        onStatusChange={(update) =>
                           setPendingUpdate(TableEditTab.RELATIONS, update)
                        }
                        readOnly={readOnly}
                     />
                  </TabPane>
               </TabContent>
            </Stack>
         </TabContainer>
      </ExploreTabContext.Provider>
   );
};

export default TableTabContent;
