import { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useGetAuthorizedExplorerQuery } from './hooks';
import { LoadingSpinner } from './components';

const Layout = (): JSX.Element => {
   const location = useLocation();
   const redirectTo = location.state?.redirectTo;
   const closeOnAuth = sessionStorage.getItem('closeOnAuth');

   useEffect(() => {
      if (closeOnAuth) {
         sessionStorage.removeItem('closeOnAuth');
         window.opener?.postMessage('authed', '*');
         window.close();
      }
   }, [closeOnAuth]);

   const query = useGetAuthorizedExplorerQuery();
   if (query.isFetching || closeOnAuth) {
      return <LoadingSpinner />;
   }

   if (!query.data?.authenticated) {
      return (
         <Navigate
            state={{
               redirectTo: `${location.pathname}${location.search}`,
            }}
            to="/signin"
         />
      );
   }

   if (redirectTo) {
      return <Navigate to={redirectTo} />;
   }

   return <Outlet />;
};

export default Layout;
