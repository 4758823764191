import { useState } from 'react';
import { Button } from 'react-bootstrap';
import LoadingError from './UI/LoadingError';
import LoadingSpinner from './UI/LoadingSpinner';
import TreeView from './UI/TreeView';
import ManageWorkspaceConnections from './ManageWorkspaceConnections';
import { useGetSchemaTree, useCurrentStep } from '../hooks';
import { StepType } from '../entities';

export const Navigator = ({ workspaceId }: { workspaceId: number }): JSX.Element => {
   const [showModal, setShowModal] = useState<'new-connection' | undefined>(undefined);
   const workspaceConnectionQuery = useGetSchemaTree({ workspaceId });
   const currentStep = useCurrentStep();

   return (
      <div>
         <div className="">
            {workspaceConnectionQuery.isLoading ? (
               <LoadingSpinner />
            ) : workspaceConnectionQuery.isError ? (
               <LoadingError />
            ) : (
               workspaceConnectionQuery.data && (
                  <TreeView
                     catalogNames={currentStep?.queryStep.type === StepType.FEDERATED}
                     expandLevels={[
                        'connectionNode',
                        'databaseNode',
                        'tablesFolder',
                        'catalogNode',
                     ]}
                     showQuickSelect={true}
                     treeData={workspaceConnectionQuery.data}
                  />
               )
            )}
         </div>
         {!workspaceConnectionQuery.data?.length && (
            <Button
               className="fs-10p my-3 px-1 py-0 opacity-75"
               onClick={() => setShowModal('new-connection')}
               size="sm"
               variant="secondary"
            >
               Attach Data Source
            </Button>
         )}
         <ManageWorkspaceConnections
            onClose={() => setShowModal(undefined)}
            show={showModal === 'new-connection'}
            workspaceId={workspaceId}
         />
      </div>
   );
};

export default Navigator;
