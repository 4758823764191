import { DBMS, SSLMode, Query, QueryReturn } from '@runql/util';

import { QueryRunOptions, QueryStep } from '../entities';
declare global {
   var runql:
      | {
           run: (queries: Query[], params: { [key: string]: unknown }) => Promise<QueryReturn[]>;
        }
      | undefined;
}

export function hasQueryLocal() {
   return global.runql !== undefined;
}

export async function runQueryLocal(steps: QueryStep[], options: QueryRunOptions) {
   if (!runql) {
      throw new Error('Local queries not supported');
   }
   const queries: Query[] = steps.map((step) => ({
      query: step.queryText!,
      // TODO get config from step.dataConnection
      config: {
         dbms: DBMS.MySQL,
         dbHost: '127.0.0.1',
         dbPort: '3306',
         dbName: 'runql',
         username: 'root',
         password: 'x',
         sslMode: SSLMode.OPTIONAL,
      },
   }));
   return await runql.run(queries, options.params ?? {});
}
