import { useCallback, useMemo, useRef, useState } from 'react';
import { Alert, Button, Stack } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { LoadingSpinner, ResultTable, ResultTableRef, RqlAction } from '../../../../components';
import LoadingError from '../../../../components/UI/LoadingError';
import { DBMS } from '../../../../enums';
import {
   useExploreTab,
   useGetTableDataQuery,
   useRunSystemQuery,
   useUpdateSchemaMutation,
} from '../../../../hooks';
import {
   buildChangeQueries,
   ClientDataChange,
   getErrorMessage,
   handleError,
} from '../../../../utilities';
import { ConfirmChangesModal } from './TableTabContent';
import { FilterModel } from 'ag-grid-community';
import { useQueryClient } from 'react-query';
import { QueryKey } from '../../../../enums';

export function DataEditTab({
   onStatusChange,
   readOnly = false,
}: {
   onStatusChange?: (isDirty: boolean) => void;
   readOnly?: boolean;
}): JSX.Element {
   const [showConfirmModal, setShowConfirmModal] = useState(false);
   const [changeCount, setChangeCount] = useState(0);
   const [changeQueries, setChangeQueries] = useState<string[]>([]);
   const [lastRefresh, setLastRefresh] = useState<Date>(new Date());
   const formattedDate = lastRefresh.toLocaleString();
   const [pendingUpdate, _setPendingUpdate] = useState(false);
   const [pendingFilterModel, setPendingFilterModel] = useState<FilterModel | undefined>(undefined);
   const [filterModel, setFilterModel] = useState<FilterModel | undefined>(undefined);
   const [tableRefreshing, setTableRefreshing] = useState(false);
   const setPendingUpdate = useCallback(
      (isDirty: boolean) => {
         _setPendingUpdate(isDirty);
         onStatusChange?.(isDirty);
      },
      [onStatusChange]
   );
   const gridRef = useRef<ResultTableRef>(null);
   const exploreTab = useExploreTab();
   const queryClient = useQueryClient();
   const updateSchemaMutator = useUpdateSchemaMutation({
      async onSuccessCallback(data, dataConnectionId, context) {
         if (exploreTab?.tableSchemaId && exploreTab?.workspaceId) {
            await queryClient.invalidateQueries([
               QueryKey.DataForTableId,
               exploreTab.tableSchemaId,
            ]);
         }
      },
   });

   const { run, isRunning } = useRunSystemQuery();
   const { tableDataQuery, refresh } = useGetTableDataQuery({
      tableSchemaId: exploreTab?.tableSchemaId,
      workspaceId: exploreTab?.workspaceId,
      exploreTabId: exploreTab?.id,
   });

   const primaryKeyColumns = useMemo<string[]>(() => {
      const primaryKeys =
         tableDataQuery.data?.columns
            ?.filter((col) => col.columnIsPrimaryKey)
            .map((col) => col.columnName) || [];
      return primaryKeys;
   }, [tableDataQuery.data]);

   function handleSaveChanges(): void {
      if (!tableDataQuery.data?.columns || !tableDataQuery.data?.tableCache) {
         handleError('No columns or table cache found');
         return;
      }
      const result = gridRef.current?.getUpdatedRows();
      if (result) {
         const { updates, filterModel: model } = result;
         setChangeCount(updates.length);
         const clientChanges: ClientDataChange[] = [];
         updates.forEach((update) => {
            let { rqlAction, ...data } = update as { rqlAction: RqlAction };
            const keyValues: Record<string, any> = {};

            primaryKeyColumns.forEach((key) => {
               if (rqlAction.key?.[key] !== undefined) {
                  keyValues[key] = rqlAction.key[key];
               } else {
                  keyValues[key] = update[key];
               }
            });

            if (!rqlAction) {
               handleError('Error creating update');
               return;
            }

            if (rqlAction.type === 'update') {
               data =
                  rqlAction.columns?.reduce((acc: Record<string, any>, column: string) => {
                     if (column in update) {
                        acc[column] = update[column];
                     }
                     return acc;
                  }, {}) ?? {};
            }

            clientChanges.push({
               data,
               key: keyValues,
               type: rqlAction.type,
               table: tableDataQuery.data?.tableCache.tableName ?? '',
               schema: tableDataQuery.data?.tableCache.schemaName ?? '',
            });
         });
         const queries = buildChangeQueries(
            clientChanges,
            tableDataQuery.data?.columns ?? [],
            tableDataQuery.data?.tableCache.dataConnection.dbms ?? DBMS.MySQL
         );
         setChangeQueries(queries);
         setShowConfirmModal(true);
         setPendingFilterModel(model);
      } else {
         toast.warn('No changes found');
      }
   }

   async function partialSchemaRefresh(): Promise<void> {
      if (exploreTab?.tableSchema?.dataConnectionId && exploreTab?.tableSchema?.tableName) {
         try {
            // Using updateSchemaMutator from the other file
            await updateSchemaMutator.mutateAsync({
               dataConnectionId: exploreTab.tableSchema.dataConnectionId,
               tableName: exploreTab.tableSchema.tableName,
               schemaName: exploreTab.tableSchema.schemaName,
               catalogName: exploreTab.tableSchema.catalogName,
            });
            setLastRefresh(new Date());
         } catch (error) {
            handleError(getErrorMessage(error));
         }
      }
   }

   async function handleConfirmSave(): Promise<void> {
      if (changeQueries.length === 0) return;
      if (exploreTab?.tableSchema?.dataConnectionId === undefined) {
         handleError('No data connection found');
         return;
      }
      try {
         await run({
            query: changeQueries.join('\n'),
            dataConnectionId: exploreTab.tableSchema.dataConnectionId,
            exploreTabId: exploreTab.id,
            workspaceId: exploreTab.workspaceId,
         });
         await refresh();
         setLastRefresh(new Date());
         setShowConfirmModal(false);
         setPendingUpdate(false);
         setFilterModel(pendingFilterModel);
         setPendingFilterModel(undefined);
      } catch (error) {
         handleError(error);
         return;
      }

      toast.success('Changes saved successfully');
   }

   const handleCellEdit = useCallback(
      (event: { valueChanged: boolean }) => {
         if (event.valueChanged) setPendingUpdate(true);
      },
      [setPendingUpdate]
   );

   const refreshing = tableDataQuery.isLoading || tableRefreshing || updateSchemaMutator.isLoading;
   if (tableDataQuery.isError)
      return <LoadingError message={getErrorMessage(tableDataQuery.error)} />;
   if (tableDataQuery.data === undefined) return <div>No data</div>;
   return (
      <div className="h-100">
         {primaryKeyColumns.length === 0 && !readOnly && (
            <Alert variant="warning">No primary keys found, table is in read only mode</Alert>
         )}
         {tableDataQuery.data.result.error && (
            <LoadingError message={getErrorMessage(tableDataQuery.data.result.error)} />
         )}
         <ConfirmChangesModal
            changeCount={changeCount}
            onClose={() => {
               setShowConfirmModal(false);
            }}
            onConfirm={handleConfirmSave}
            query={changeQueries}
            running={isRunning}
            show={showConfirmModal}
         />
         <Stack className="h-100">
            {!readOnly && !refreshing && (
               <Stack className="m-3 justify-space-between w-100" direction="horizontal">
                  <Stack className="align-items-left" direction="horizontal" gap={2}>
                     <Button
                        className="btn btn-sm btn-secondary "
                        onClick={() => {
                           partialSchemaRefresh();
                        }}
                     >
                        Refresh
                     </Button>
                     <div
                        style={{
                           fontSize: '10px',
                           color: 'gray',
                           whiteSpace: 'nowrap',
                           overflow: 'hidden',
                           textOverflow: 'ellipsis',
                        }}
                     >
                        Last Refresh: {formattedDate}
                     </div>
                  </Stack>
                  {!readOnly && (
                     <Stack
                        className="container justify-content-end"
                        direction="horizontal"
                        gap={1}
                     >
                        <Button
                           className="btn btn-sm btn-secondary"
                           onClick={() => {
                              setPendingUpdate(true);
                              gridRef.current?.addNewRow();
                           }}
                        >
                           Add
                        </Button>
                        <Button
                           className="btn btn-sm btn-secondary"
                           onClick={() => {
                              if (gridRef.current?.markRowsAsDeleted()) setPendingUpdate(true);
                           }}
                        >
                           Delete
                        </Button>
                        <Button
                           className="btn btn-sm btn-secondary"
                           disabled={!pendingUpdate}
                           onClick={async () => {
                              setPendingUpdate(false);
                              await refresh();
                           }}
                        >
                           Cancel
                        </Button>
                        <Button
                           className="btn btn-sm btn-primary"
                           disabled={!pendingUpdate}
                           onClick={handleSaveChanges}
                        >
                           Apply Changes
                        </Button>
                     </Stack>
                  )}
               </Stack>
            )}
            {!tableDataQuery.data.missing_cred && !tableDataQuery.data.result.error && (
               <>
                  {(refreshing || updateSchemaMutator.isLoading) && <LoadingSpinner />}
                  <div className="flex-grow-1 overflow-hidden" hidden={refreshing}>
                     <ResultTable
                        allowEditing={!readOnly}
                        filterModel={filterModel}
                        keyColumns={primaryKeyColumns}
                        onCellEdit={handleCellEdit}
                        onRefreshChange={setTableRefreshing}
                        queryReturn={tableDataQuery.data.result}
                        ref={gridRef}
                     />
                  </div>
               </>
            )}
         </Stack>
      </div>
   );
}
