import { useInjection } from 'inversify-react';
import { AskRunaResponse, AskService } from '../services';
import { TYPES } from '../types';
import { useMutation } from 'react-query';

const useAskService = () => {
   return useInjection<AskService>(TYPES.askService);
};

export const useAskRuna = () => {
   const askService = useAskService();
   return useMutation({
      mutationFn: async ({
         currentQuery,
         dataConnectionId,
         previous,
         prompt,
      }: {
         currentQuery?: string;
         dataConnectionId?: number;
         previous?: AskRunaResponse[];
         prompt: string;
      }) => {
         return askService.askRuna({ prompt, previous, dataConnectionId, currentQuery });
      },
   });
};
