import { DBMS } from '../../enums';
import TreeViewNode from './TreeViewNode';

export interface TreeNode {
   catalogName?: string;
   children: Array<TreeNode>;
   dataConnectionId?: number;
   databaseName?: string;
   dbms: DBMS;
   defaultFlag: number;
   definition?: string;
   description?: string;
   exampleData?: string;
   fieldDataType?: string;
   icon: string;
   id: number;
   isDemo?: boolean;
   isGenerating?: Date;
   isView?: boolean;
   key: string;
   label: string;
   tableName?: string;
   tooltip: string;
   type: string;
   visible: boolean;
}

const TreeView = (props: {
   catalogNames?: boolean;
   expand?: boolean;
   expandLevels?: string[];
   parent?: TreeNode;
   showQuickSelect?: boolean;
   treeData: TreeNode[] | undefined;
}): JSX.Element => {
   if (!props.treeData) {
      return <></>;
   }
   return (
      <ul className="nav flex-column list-unstyled navigator">
         {props.treeData.map((node, index) => (
            <TreeViewNode
               catalogNames={props.catalogNames}
               expand={props.expand}
               expandLevels={props.expandLevels}
               key={`${node.key}_${index}`}
               node={node}
               parent={props.parent}
               showQuickSelect={props.showQuickSelect}
            />
         ))}
      </ul>
   );
};

export default TreeView;
