import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, Row, Stack } from 'react-bootstrap';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import CreatableSelect from 'react-select/creatable';
import { toast } from 'react-toastify';
import { z } from 'zod';
import { LoadingSpinner } from '../../../../components';
import LoadingError from '../../../../components/UI/LoadingError';
import { ColumnNull } from '../../../../entities';
import { DBMS } from '../../../../enums';
import {
   useExploreTab,
   useGetTableDataQuery,
   useRunSystemQuery,
   useUpdateSchemaMutation,
} from '../../../../hooks';
import { SelectOption } from '../../../../interfaces';
import {
   buildChangeQueries,
   ClientDataChange,
   ColumnModify,
   getDataTypesAsOptions,
   handleError,
   IconKey,
   MsSQLColumnModify,
   MySQLColumnModify,
} from '../../../../utilities';
import { ConfirmChangesModal } from './TableTabContent';

const mysqlColumnSchema = z.object({
   id: z.number(),
   dbms: z.literal(DBMS.MySQL),
   table: z.string(),
   columns: z.array(
      z.object({
         runqlId: z.number().optional(),
         columnName: z.string().min(1),
         columnType: z.string().min(1),
         columnNullable: z.nativeEnum(ColumnNull),
         columnDefault: z.string().optional().default(''),
         columnExtra: z.string().optional(),
         columnComment: z.string().optional(),
         columnIsPrimaryKey: z.boolean().default(false),
         markDeleted: z.boolean().default(false),
         isNew: z.boolean().default(false),
      })
   ),
});

const mssqlColumnSchema = z.object({
   id: z.number(),
   dbms: z.literal(DBMS.MSSQL),
   table: z.string(),
   columns: z.array(
      z.object({
         runqlId: z.number().optional(),
         columnName: z.string().min(1),
         columnType: z.string().min(1),
         columnNullable: z.nativeEnum(ColumnNull),
         columnDefault: z.string().optional().default(''),
         columnDescription: z.string().optional(),
         columnIsPrimaryKey: z.boolean().default(false),
         markDeleted: z.boolean().default(false),
         isNew: z.boolean().default(false),
      })
   ),
});

const columnSchema = z.discriminatedUnion('dbms', [mysqlColumnSchema, mssqlColumnSchema]);

export type ColumnFormData = z.infer<typeof columnSchema>;

export function SchemaColumnTab({
   onStatusChange,
   readOnly = false,
}: {
   onStatusChange?: (isDirty: boolean) => void;
   readOnly?: boolean;
}) {
   const [changeCount, setChangeCount] = useState(0);
   const [showConfirmModal, setShowConfirmModal] = useState(false);
   const [changeQueries, setChangeQueries] = useState<string[]>([]);
   const { run, isRunning } = useRunSystemQuery();
   const [options, setOptions] = useState<SelectOption[]>();
   const {
      handleSubmit,
      register,
      control,
      reset,
      formState: { isDirty, dirtyFields, defaultValues, isSubmitted },
   } = useForm<ColumnFormData>({
      resolver: zodResolver(columnSchema),
      mode: 'onTouched',
      defaultValues: {},
   });

   const { fields, append, update, remove } = useFieldArray({
      control,
      name: 'columns',
   });

   const previousIsDirty = useRef(isDirty);

   //Context
   const exploreTab = useExploreTab();

   // Queries
   const { tableDataQuery, modals, refresh } = useGetTableDataQuery({
      tableSchemaId: exploreTab?.tableSchemaId,
      workspaceId: exploreTab?.workspaceId,
      exploreTabId: exploreTab?.id,
   });
   const dbms = tableDataQuery.data?.tableCache.dataConnection.dbms;
   const updateSchemaMutator = useUpdateSchemaMutation({
      onSuccessCallback(data, dataConnectionId, context) {
         refresh();
      },
   });

   // Effects
   useEffect(() => {
      if (!tableDataQuery.data) return;

      // set options
      setOptions(getDataTypesAsOptions(tableDataQuery.data?.tableCache.dataConnection.dbms));

      // load form data
      const formData: ColumnFormData = {
         id: tableDataQuery.data.tableCache.id!,
         table: tableDataQuery.data.tableCache.tableName,
         dbms:
            tableDataQuery.data?.tableCache.dataConnection.dbms === DBMS.MySQL
               ? DBMS.MySQL
               : DBMS.MSSQL,
         columns:
            tableDataQuery.data.columns?.map((column) => ({
               runqlId: column.id,
               columnName: column.columnName,
               columnType: column.columnType.toUpperCase(),
               columnNullable: column.columnNullable ?? ColumnNull.NOT_NULL,
               columnDefault: column.columnDefault ?? '',
               columnExtra: column.columnExtra,
               columnComment: column.columnComment,
               columnDescription: column.columnComment,
               columnIsPrimaryKey: column.columnIsPrimaryKey ?? false,
               markDeleted: false,
               isNew: false,
            })) ?? [],
      };
      reset(formData);
   }, [reset, tableDataQuery.data]);

   useEffect(() => {
      if (previousIsDirty.current !== isDirty) {
         previousIsDirty.current = isDirty;
         onStatusChange?.(isDirty);
      }
   }, [isDirty, onStatusChange]);

   // Handlers
   function handleSave(data: ColumnFormData) {
      if (dirtyFields.columns === undefined) return;
      if (defaultValues?.table === undefined) {
         handleError('Table name is missing');
         return;
      }

      const tableChanges: ClientDataChange = {
         type: 'modify',
         catalog: tableDataQuery.data?.tableCache.catalogName,
         table: defaultValues.table,
         schema: tableDataQuery.data?.tableCache.schemaName ?? '',
         columns: dirtyFields.columns.reduce<Record<string, ColumnModify>>((acc, value, index) => {
            const column = data.columns[index];
            const originalColumn = tableDataQuery.data?.columns?.find(
               (col) => col.id === column.runqlId
            );
            if (!column) {
               handleError('Column not found');
               return acc;
            }
            const columnName = originalColumn?.columnName ?? column.columnName;
            let hasChanges = false;

            function setCommonChanges(modify: ColumnModify) {
               if (value.columnName && originalColumn?.columnName !== column.columnName) {
                  modify.rename = column.columnName;
                  hasChanges = true;
               }
               if (value.columnType && originalColumn?.columnType !== column.columnType) {
                  modify.dataType = column.columnType;
                  hasChanges = true;
               }
               if (
                  value.columnNullable &&
                  originalColumn?.columnNullable !== column.columnNullable
               ) {
                  modify.nullable = column.columnNullable === ColumnNull.NULL;
                  hasChanges = true;
               }
               if (value.columnDefault && originalColumn?.columnDefault !== column.columnDefault) {
                  modify.default = column.columnDefault;
                  hasChanges = true;
               }
               if (column.isNew) {
                  modify.isNew = true;
                  hasChanges = true;
               }
               if (column.markDeleted) {
                  modify.drop = true;
                  hasChanges = true;
               }
            }
            if (dbms === DBMS.MySQL) {
               const modify: MySQLColumnModify = {};
               setCommonChanges(modify);
               if (
                  'columnExtra' in value &&
                  value.columnExtra &&
                  'columnExtra' in column &&
                  originalColumn?.columnExtra !== column.columnExtra
               ) {
                  modify.extra = column.columnExtra;
                  hasChanges = true;
               }
               if (
                  'columnComment' in value &&
                  value.columnComment &&
                  'columnComment' in column &&
                  originalColumn?.columnComment !== column.columnComment
               ) {
                  modify.comment = column.columnComment;
                  hasChanges = true;
               }

               if (hasChanges) {
                  acc[columnName] = modify;
               }
            }
            if (dbms === DBMS.MSSQL) {
               const modify: MsSQLColumnModify = {};
               setCommonChanges(modify);

               if (
                  'columnDescription' in value &&
                  value.columnDescription &&
                  'columnDescription' in column &&
                  originalColumn?.columnComment !== column.columnDescription
               ) {
                  modify.description = column.columnDescription;
                  hasChanges = true;
               }

               if (hasChanges) {
                  acc[columnName] = modify;
               }
            }

            return acc;
         }, {}),
      };

      if (!tableDataQuery.data?.tableCache.dataConnection.dbms) {
         handleError('No DBMS found');
         return;
      }
      const queries = buildChangeQueries(
         [tableChanges],
         tableDataQuery.data?.columns ?? [],
         tableDataQuery.data?.tableCache.dataConnection.dbms
      );
      setChangeQueries(queries);
      setChangeCount(queries.length);
      setShowConfirmModal(true);
   }

   async function handleConfirmSave(): Promise<void> {
      if (changeQueries.length === 0) return;
      if (exploreTab?.tableSchema?.dataConnectionId === undefined) {
         handleError('No data connection found');
         return;
      }
      try {
         await run({
            query: changeQueries.join('\n'),
            dataConnectionId: exploreTab.tableSchema.dataConnectionId,
            exploreTabId: exploreTab.id,
            workspaceId: exploreTab.workspaceId,
            updateSchema: true,
         });
         await refresh();
         setShowConfirmModal(false);
      } catch (error) {
         handleError(error);
         return;
      }

      toast.success('Changes saved successfully');
   }

   function handleColumnRemove(index: number): void {
      if (fields[index].isNew) {
         remove(index);
      } else {
         update(index, {
            ...fields[index],
            markDeleted: !fields[index].markDeleted,
         });
      }
   }

   // Render
   if (!exploreTab) return <LoadingError />;
   if (tableDataQuery.isLoading || updateSchemaMutator.isLoading) return <LoadingSpinner />;
   if (tableDataQuery.data === undefined) return <div>No data</div>;

   return (
      <div className="container h-100">
         <ConfirmChangesModal
            changeCount={changeCount}
            onClose={() => {
               setShowConfirmModal(false);
            }}
            onConfirm={handleConfirmSave}
            query={changeQueries}
            running={isRunning}
            show={showConfirmModal}
         />
         <Form className="h-100" onSubmit={handleSubmit(handleSave)}>
            <Stack className="h-100">
               <Stack
                  className="container-fluid mt-3 mb-3 justify-content-end"
                  direction="horizontal"
                  gap={1}
               >
                  <Button
                     className="btn btn-sm btn-secondary"
                     onClick={async () => {
                        const dataConnectionId = tableDataQuery.data?.tableCache.dataConnectionId;
                        if (!dataConnectionId) {
                           handleError('No data connection found');
                           return;
                        }
                        await updateSchemaMutator.mutateAsync({
                           dataConnectionId: dataConnectionId,
                        });
                     }}
                  >
                     Refresh
                  </Button>

                  {!readOnly && (
                     <>
                        <Button
                           className="btn btn-sm btn-secondary"
                           onClick={() => {
                              append({
                                 columnDefault: '',
                                 columnExtra: '',
                                 columnIsPrimaryKey: false,
                                 columnName: '',
                                 columnNullable: ColumnNull.NOT_NULL,
                                 columnType: '',
                                 markDeleted: false,
                                 isNew: true,
                              });
                           }}
                        >
                           Add
                        </Button>
                        <Button
                           className="btn btn-sm btn-secondary"
                           disabled={!isDirty}
                           onClick={() => {
                              reset();
                           }}
                        >
                           Cancel
                        </Button>
                        <Button
                           className="btn btn-sm btn-primary"
                           disabled={!isDirty}
                           type="submit"
                        >
                           Apply Changes
                        </Button>
                     </>
                  )}
               </Stack>

               {/* Print column headers */}
               <div className="p-3 pb-0 pt-1 fs-10p">
                  <Row className="justify-content-center">
                     <Col xs={1}>{/* Icon */}</Col>
                     <Col xs={2}>
                        <span>Column</span>
                     </Col>
                     <Col xs={2}>
                        <span>Data Type</span>
                     </Col>
                     <Col xs={1}>Null?</Col>
                     <Col xs={2}>
                        <span>Default</span>
                     </Col>
                     {dbms === DBMS.MySQL && (
                        <>
                           <Col xs={1}>
                              <span>Extra</span>
                           </Col>
                           <Col xs={2}>
                              <span>Comment</span>
                           </Col>
                        </>
                     )}
                     {dbms === DBMS.MSSQL && (
                        <Col xs={2}>
                           <span>Description</span>
                        </Col>
                     )}

                     <Col xs={1}>{/* Actions */}</Col>
                  </Row>
               </div>

               <div className="flex-grow-1 overflow-auto">
                  {fields.map((column, index) => (
                     <Card
                        className={`border-1 mt-2 mb-2 p-3 shadow-sm fs-10p ${
                           column.markDeleted ? 'bg-danger text-white' : ''
                        }`}
                        key={column.runqlId}
                     >
                        <Row className="justify-content-center">
                           <Col xs={1}>{column.columnIsPrimaryKey ? <IconKey /> : <></>}</Col>
                           <Col xs={2}>
                              <Form.Control
                                 {...register(`columns.${index}.columnName`)}
                                 isInvalid={isSubmitted && column.columnName.length === 0}
                                 isValid={isSubmitted && column.columnName.length > 0}
                                 readOnly={readOnly}
                              />
                           </Col>
                           <Col xs={2}>
                              <Controller
                                 control={control}
                                 name={`columns.${index}.columnType`}
                                 render={({ field }) => (
                                    <CreatableSelect
                                       className={`runql-select ${
                                          isSubmitted && !field.value ? 'runql-select-invalid' : ''
                                       }`}
                                       classNamePrefix="runql-select"
                                       isDisabled={readOnly}
                                       onChange={(selectedOption) => {
                                          field.onChange(
                                             selectedOption ? selectedOption.value : ''
                                          );
                                       }}
                                       onCreateOption={(inputValue) => {
                                          const newOption = {
                                             value: inputValue,
                                             label: inputValue,
                                          };
                                          setOptions((prevOptions) => [
                                             ...(prevOptions ?? []),
                                             newOption,
                                          ]);
                                          field.onChange(inputValue);
                                       }}
                                       options={options}
                                       value={
                                          options?.find(
                                             (option) => option.value === field.value
                                          ) || {
                                             value: field.value,
                                             label: field.value,
                                          }
                                       }
                                    />
                                 )}
                              />
                           </Col>
                           <Col xs={1}>
                              <Form.Control
                                 as="select"
                                 {...register(`columns.${index}.columnNullable`)}
                                 disabled={readOnly}
                              >
                                 <option value={ColumnNull.NULL}>YES</option>
                                 <option value={ColumnNull.NOT_NULL}>NO</option>
                              </Form.Control>
                           </Col>
                           <Col xs={2}>
                              <Form.Control
                                 {...register(`columns.${index}.columnDefault`)}
                                 readOnly={readOnly}
                              />
                           </Col>
                           {dbms === DBMS.MySQL && (
                              <>
                                 <Col xs={1}>
                                    <Form.Control
                                       {...register(`columns.${index}.columnExtra`)}
                                       readOnly={readOnly}
                                    />
                                 </Col>
                                 <Col xs={2}>
                                    <Form.Control
                                       {...register(`columns.${index}.columnComment`)}
                                       readOnly={readOnly}
                                    />
                                 </Col>
                              </>
                           )}

                           {dbms === DBMS.MSSQL && (
                              <Col xs={2}>
                                 <Form.Control
                                    {...register(`columns.${index}.columnDescription`)}
                                    readOnly={readOnly}
                                 />
                              </Col>
                           )}

                           <Col xs={1}>
                              {!readOnly && (
                                 <Button
                                    className="btn btn-sm"
                                    onClick={() => handleColumnRemove(index)}
                                    variant="danger"
                                 >
                                    X
                                 </Button>
                              )}
                           </Col>
                        </Row>
                     </Card>
                  ))}
               </div>
            </Stack>
         </Form>
         {modals}
      </div>
   );
}
