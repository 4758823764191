import React from 'react';

export const Error50x = (): JSX.Element => {
   return (
      <>
         <div className="pt-xl-5 pt-lg-4 pt-md-3 pb-lg-5 pb-md-4 pb-3 container-fluid">
            <div className="d-flex align-items-center row">
               <div className="col-12">
                  <div className="text-center pt-5 pb-3 mt-auto">
                     <h1 className="visually-hidden">50x Error</h1>
                     <h2 className="display-5">Whoa!</h2>
                     <p className="pb-3 pb-md-0 mb-md-5">
                        Well, it seems we've ventured a bit too deep into the data catacombs, and
                        even the ancient spirits are a tad confused!
                        <br />
                        This 50x mishap is just a friendly reminder that even the bravest explorers
                        sometimes tread on uncharted territory.
                        <br />
                        Please reach out to us to let us know about this error.
                     </p>
                     <a className="btn btn-lg btn-primary shadow-primary w-sm-auto w-100" href="/">
                        Go to Workspaces
                     </a>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
};

export default Error50x;
