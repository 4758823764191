import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { InputGroup, Button, Collapse, Form, OverlayTrigger } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import LoadingError from '../../../../components/UI/LoadingError';
import LoadingSpinner from '../../../../components/UI/LoadingSpinner';
import { ConnectionAccessType, SSLMode } from '../../../../entities';
import { ADMIN_ROLES, DBMS } from '../../../../enums';
import { useGetAuthorizedExplorerQuery, useGetDataConnectionQuery } from '../../../../hooks';
import { getErrorMessage, IconInformation, IconX } from '../../../../utilities';
import { connectionName, description, host, password, port, username } from '../validators';
import { tooltipSharedConnection } from './ConnectionDetailsForm';
import HideDetailsLabel from './HideDetailsLabel';

const mysqlNonSSHSchema = z.object({
   dbms: z.literal(DBMS.MySQL),
   connectionName: connectionName,
   description: description,
   host: host,
   port: port,
   database: z.string().trim().optional(),
   sharedConnection: z.boolean().default(false),
   hideDetails: z.boolean().default(false),
   accountName: username,
   password: password,
   sslCaCert: z.any().optional(),
   sslCaCertId: z.number().optional(),
   useSSH: z.literal(false),
   useSSL: z.boolean().default(false),
});

const mysqlSSHSchema = z.object({
   dbms: z.literal(DBMS.MySQL),
   connectionName: connectionName,
   description: description,
   host: host,
   port: port,
   database: z.string().trim().optional(),
   sharedConnection: z.boolean().default(false),
   hideDetails: z.boolean().default(false),
   accountName: username,
   password: password,
   useSSH: z.literal(true),
   sshHost: host,
   sshPort: port,
   sshUsername: username,
   sshPassword: password,
   sslCaCert: z.any().optional(),
   sslCaCertId: z.number().optional(),
   useSSL: z.boolean().default(false),
});

export const mysqlDetailSchema = z.discriminatedUnion('useSSH', [
   mysqlNonSSHSchema,
   mysqlSSHSchema,
]);

export type MySQLDetailFormData = z.infer<typeof mysqlDetailSchema>;

function MySqlDetailForm({
   editType,
   formId,
   onSaveStateChange,
   onSubmit,
   selectedConnectionId,
}: {
   editType: 'connection' | 'credential' | 'read-only';
   formId: string;
   onSaveStateChange?: (state: 'clean' | 'dirty') => void;
   onSubmit?: (data: MySQLDetailFormData) => void;
   selectedConnectionId?: number;
}) {
   // register form
   const { handleSubmit, register, formState, watch, setValue, clearErrors, reset } =
      useForm<MySQLDetailFormData>({
         resolver: zodResolver(mysqlDetailSchema),
         mode: 'onTouched',
         defaultValues: {
            useSSH: false,
         },
      });

   // State variables
   const [explorerIsAdmin, setExplorerIsAdmin] = useState(false);

   // Queries
   const selectedConnectionQuery = useGetDataConnectionQuery({
      id: selectedConnectionId,
      getOptions: { includeCredentials: true },
   });
   const authPersonQuery = useGetAuthorizedExplorerQuery();

   // Effects
   useEffect(() => {
      //User is adding credentials, load parent connection data
      if (selectedConnectionQuery.data) {
         const formData: MySQLDetailFormData = {
            connectionName: selectedConnectionQuery.data.name ?? '',
            password: 'CURRENT',
            dbms: DBMS.MySQL,
            description: selectedConnectionQuery.data.description ?? '',
            host: selectedConnectionQuery.data.dbHost ?? '',
            port: parseInt(selectedConnectionQuery.data.dbPort ?? ''),
            database: selectedConnectionQuery.data.dbName ?? '',
            useSSH: (selectedConnectionQuery.data.useSSH as unknown as number) === 1 ? true : false,
            useSSL: selectedConnectionQuery.data.sslMode === SSLMode.VALIDATED,
            sslCaCertId: selectedConnectionQuery.data.sslCaCertId ?? undefined,
            sshHost: selectedConnectionQuery.data.sshHost ?? '',
            sshPort: parseInt(selectedConnectionQuery.data.sshPort ?? ''),
            sshUsername: selectedConnectionQuery.data.dataCredentials?.[0].sshUsername ?? '',
            sshPassword: 'CURRENT',
            sharedConnection:
               selectedConnectionQuery.data.connectionAccessType === ConnectionAccessType.SHARED,
            hideDetails:
               (selectedConnectionQuery.data.hideDetails as unknown as number) === 1 ? true : false,
            accountName: selectedConnectionQuery.data.dataCredentials?.[0].accountName ?? '',
         };
         reset(formData);
      }
   }, [reset, selectedConnectionQuery.data]);

   useEffect(() => {
      if (
         authPersonQuery.data?.person.role &&
         ADMIN_ROLES.includes(authPersonQuery.data.person.role)
      ) {
         setExplorerIsAdmin(true);
      } else {
         setExplorerIsAdmin(false);
      }
   }, [authPersonQuery.data?.person.role]);

   useEffect(() => {
      const isDirtyAlt = !!Object.keys(formState.dirtyFields).length;
      if (isDirtyAlt) {
         onSaveStateChange?.('dirty');
      } else {
         onSaveStateChange?.('clean');
      }
   }, [formState, onSaveStateChange]);

   const useSSH = !!watch('useSSH', false);
   const useSSL = !!watch('useSSL', false);
   const sslCaCertId = watch('sslCaCertId', undefined);

   // Page functions
   const handleOnSubmit = (data: MySQLDetailFormData) => {
      onSubmit?.(data);
   };

   // Render
   if (authPersonQuery.isLoading || selectedConnectionQuery.isLoading) return <LoadingSpinner />;
   if (authPersonQuery.isError)
      return <LoadingError message={getErrorMessage(authPersonQuery.error)} />;
   if (selectedConnectionQuery.isError)
      return <LoadingError message={getErrorMessage(selectedConnectionQuery.error)} />;

   const touchedFields = formState.touchedFields;
   const errors = formState.errors;
   return (
      <Form id={formId} onSubmit={handleSubmit(handleOnSubmit)}>
         <input type="hidden" {...register('dbms')} value={DBMS.MySQL} />
         <Form.Group className="mb-3">
            <Form.Label>
               Connection Name <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
               {...register('connectionName')}
               disabled={editType !== 'connection'}
               isInvalid={touchedFields.connectionName && !!errors.connectionName}
               isValid={touchedFields.connectionName && !errors.connectionName}
               placeholder="Connection Name"
               required
            />
            <Form.Control.Feedback type="invalid">
               {errors.connectionName?.message}
            </Form.Control.Feedback>
         </Form.Group>
         <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
               {...register('description')}
               as="textarea"
               disabled={editType !== 'connection'}
               isInvalid={touchedFields.description && !!errors.description}
               isValid={touchedFields.description && !errors.description}
               placeholder="Description"
               rows={3}
            />
            <Form.Control.Feedback type="invalid">
               {errors.description?.message}
            </Form.Control.Feedback>
         </Form.Group>
         <Form.Group className="mb-3">
            <Form.Label>
               Host <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
               {...register('host')}
               disabled={editType !== 'connection'}
               isInvalid={touchedFields.host && !!errors.host}
               isValid={touchedFields.host && !errors.host}
               placeholder="Host"
               required
            />
            <Form.Control.Feedback type="invalid">{errors.host?.message}</Form.Control.Feedback>
         </Form.Group>
         <Form.Group className="mb-3">
            <Form.Label>
               Port <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
               {...register('port', { valueAsNumber: true })}
               defaultValue="3306"
               disabled={editType !== 'connection'}
               isInvalid={touchedFields.port && !!errors.port}
               isValid={touchedFields.port && !errors.port}
               placeholder="Port"
               required
            />
            <Form.Control.Feedback type="invalid">{errors.port?.message}</Form.Control.Feedback>
         </Form.Group>
         <Form.Group className="mb-3">
            <Form.Label>Database</Form.Label>
            <Form.Control
               {...register('database')}
               disabled={editType !== 'connection'}
               isInvalid={touchedFields.database && !!errors.database}
               isValid={touchedFields.database && !errors.database}
               placeholder="Database"
            />
            <Form.Control.Feedback type="invalid">{errors.database?.message}</Form.Control.Feedback>
         </Form.Group>
         <Form.Group className="mb-3">
            <Form.Label>User Name</Form.Label> <span className="text-danger">*</span>
            <Form.Control
               {...register('accountName')}
               disabled={editType === 'read-only'}
               isInvalid={touchedFields.accountName && !!errors.accountName}
               isValid={touchedFields.accountName && !errors.accountName}
               placeholder="User Name"
               required={true}
            />
            <Form.Control.Feedback type="invalid">
               {errors.accountName?.message}
            </Form.Control.Feedback>
         </Form.Group>
         <Form.Group className="mb-3">
            <Form.Label>Password</Form.Label> <span className="text-danger">*</span>
            <Form.Control
               {...register('password')}
               disabled={editType === 'read-only'}
               isInvalid={touchedFields.password && !!errors.password}
               isValid={touchedFields.password && !errors.password}
               placeholder="Password"
               required={true}
               type="password"
            />
            <Form.Control.Feedback type="invalid">{errors.password?.message}</Form.Control.Feedback>
         </Form.Group>
         <Form.Group className="mb-3">
            <Form.Label>SSL Mode</Form.Label>
            <Form.Select
               disabled={editType !== 'connection'}
               {...register('useSSL', {
                  setValueAs: (v) => v === true || v === 'true',
               })}
            >
               <option value="false">Use SSL if available</option>
               <option value="true">Require SSL and validate certificates</option>
            </Form.Select>
         </Form.Group>
         <Collapse in={useSSL}>
            <Form.Group className="mb-3">
               <Form.Label>SSL CA Certificate</Form.Label>
               {sslCaCertId ? (
                  <InputGroup className="mb-3">
                     <Form.Control placeholder="Uploaded file" readOnly type="text" />
                     <Button
                        onClick={() => setValue('sslCaCertId', undefined, { shouldDirty: true })}
                        variant="secondary"
                     >
                        <IconX />
                     </Button>
                  </InputGroup>
               ) : (
                  <Form.Control {...register('sslCaCert')} type="file" />
               )}
            </Form.Group>
         </Collapse>
         <Form.Group className="mb-3">
            <Form.Label>Use SSH</Form.Label>
            <Form.Check
               {...register('useSSH', {
                  onChange: () => {
                     if (editType !== 'connection') {
                        // disabling the control when using isTemplate breaks the discriminatedUnion as the value of a disabled checkbox isn't returned
                        // prevents the checkbox value from changing when a template is loaded
                        setValue('useSSH', selectedConnectionQuery.data?.useSSH ?? false);
                     }
                     clearErrors('sshHost');
                     clearErrors('sshPort');
                     clearErrors('sshUsername');
                     clearErrors('sshPassword');
                  },
               })}
               type="switch"
            />
            <Form.Control.Feedback type="invalid">{errors.useSSH?.message}</Form.Control.Feedback>
         </Form.Group>
         <Collapse in={useSSH}>
            <div>
               <Form.Group className="mb-3">
                  <Form.Label>SSH Host</Form.Label>
                  <Form.Control
                     {...register('sshHost')}
                     disabled={editType !== 'connection'}
                     isInvalid={
                        'sshHost' in touchedFields &&
                        touchedFields.sshHost &&
                        'sshHost' in errors &&
                        !!errors.sshHost
                     }
                     isValid={
                        'sshHost' in touchedFields &&
                        touchedFields.useSSH &&
                        'sshHost' in errors &&
                        !errors.sshHost
                     }
                     placeholder="SSH Host"
                     required={useSSH}
                  />
                  <Form.Control.Feedback type="invalid">
                     {'sshHost' in errors && errors.sshHost?.message}
                  </Form.Control.Feedback>
               </Form.Group>
               <Form.Group className="mb-3">
                  <Form.Label>SSH Port</Form.Label>
                  <Form.Control
                     {...register('sshPort')}
                     disabled={editType !== 'connection'}
                     isInvalid={
                        'sshPort' in touchedFields &&
                        touchedFields.sshPort &&
                        'sshPort' in errors &&
                        !!errors.sshPort
                     }
                     isValid={
                        'sshPort' in touchedFields &&
                        touchedFields.sshPort &&
                        'sshPort' in errors &&
                        !errors.sshPort
                     }
                     placeholder="SSH Port"
                     required={useSSH}
                  />
                  <Form.Control.Feedback type="invalid">
                     {'sshPort' in errors && errors.sshPort?.message}
                  </Form.Control.Feedback>
               </Form.Group>
               <Form.Group className="mb-3">
                  <Form.Label>SSH Username</Form.Label>
                  <Form.Control
                     {...register('sshUsername')}
                     disabled={editType === 'read-only'}
                     isInvalid={
                        'sshUsername' in touchedFields &&
                        touchedFields.sshUsername &&
                        'sshUsername' in errors &&
                        !!errors.sshUsername
                     }
                     isValid={
                        'sshUsername' in touchedFields &&
                        touchedFields.sshUsername &&
                        'sshUsername' in errors &&
                        !errors.sshUsername
                     }
                     placeholder="SSH Username"
                     required={useSSH}
                  />
               </Form.Group>
               <Form.Control.Feedback type="invalid">
                  {'sshUsername' in errors && errors.sshUsername?.message}
               </Form.Control.Feedback>
               <Form.Group className="mb-3">
                  <Form.Label>SSH Password</Form.Label>
                  <Form.Control
                     {...register('sshPassword')}
                     disabled={editType === 'read-only'}
                     isInvalid={
                        'sshPassword' in touchedFields &&
                        touchedFields.sshPassword &&
                        'sshPassword' in errors &&
                        !!errors.sshPassword
                     }
                     isValid={
                        'sshPassword' in touchedFields &&
                        touchedFields.sshPassword &&
                        'sshPassword' in errors &&
                        !!errors.sshPassword
                     }
                     placeholder="SSH Password"
                     required={useSSH}
                     type="password"
                  />
                  <Form.Control.Feedback type="invalid">
                     {'sshPassword' in errors && errors.sshPassword?.message}
                  </Form.Control.Feedback>
               </Form.Group>
            </div>
         </Collapse>
         {explorerIsAdmin && (
            <>
               <Form.Group>
                  <Form.Label>
                     Shared Connection
                     <OverlayTrigger overlay={tooltipSharedConnection} placement="auto">
                        <span>
                           <IconInformation size={16} />
                        </span>
                     </OverlayTrigger>
                  </Form.Label>
                  <Form.Check
                     {...register('sharedConnection')}
                     disabled={editType !== 'connection'}
                     type="switch"
                  />
               </Form.Group>
               <Form.Group className="mb-s">
                  <HideDetailsLabel />
                  <Form.Check
                     {...register('hideDetails')}
                     disabled={editType !== 'connection'}
                     type="switch"
                  />
               </Form.Group>
            </>
         )}
      </Form>
   );
}

export default MySqlDetailForm;
