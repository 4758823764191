import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { ADMIN_ROLES, DBMS } from '../../../../enums';
import { useGetAuthorizedExplorerQuery, useGetDataConnectionQuery } from '../../../../hooks';
import { connectionName, description, host, password, port, username } from '../validators';
import { ConnectionAccessType } from '../../../../entities';
import { LoadingSpinner } from '../../../../components';
import LoadingError from '../../../../components/UI/LoadingError';
import { getErrorMessage, IconInformation } from '../../../../utilities';
import { Form, OverlayTrigger } from 'react-bootstrap';
import { tooltipSharedConnection } from './ConnectionDetailsForm';
import HideDetailsLabel from './HideDetailsLabel';

const trinoSchema = z.object({
   dbms: z.literal(DBMS.Trino),
   connectionName: connectionName,
   description: description,
   host: host,
   port: port,
   database: z.string().trim().optional(),
   accountName: username,
   password: password,
   sharedConnection: z.boolean(),
   hideDetails: z.boolean(),
});

export type TrinoDetailFormData = z.infer<typeof trinoSchema>;

function TrinoDetailForm({
   editType,
   formId,
   onSaveStateChange,
   onSubmit,
   selectedConnectionId,
}: {
   editType: 'connection' | 'credential' | 'read-only';
   formId: string;
   onSaveStateChange?: (state: 'clean' | 'dirty') => void;
   onSubmit?: (data: TrinoDetailFormData) => void;
   selectedConnectionId?: number;
}) {
   // register form
   const {
      handleSubmit,
      register,
      formState: { errors, touchedFields, dirtyFields },
      reset,
   } = useForm<TrinoDetailFormData>({
      resolver: zodResolver(trinoSchema),
      mode: 'onTouched',
      defaultValues: {
         dbms: DBMS.Trino,
         connectionName: '',
         description: '',
         host: '',
         port: 8080,
         database: '',
         accountName: '',
         password: '',
         sharedConnection: false,
         hideDetails: false,
      },
   });

   // State variables
   const [explorerIsAdmin, setExplorerIsAdmin] = useState<boolean>(false);

   // Queries
   const selectedConnectionQuery = useGetDataConnectionQuery({
      id: selectedConnectionId,
      getOptions: { includeCredentials: true },
   });
   const authPersonQuery = useGetAuthorizedExplorerQuery();

   // Effects
   useEffect(() => {
      if (selectedConnectionQuery.data) {
         const formData: TrinoDetailFormData = {
            dbms: DBMS.Trino,
            connectionName: selectedConnectionQuery.data.name ?? '',
            description: selectedConnectionQuery.data.description ?? '',
            host: selectedConnectionQuery.data.dbHost ?? '',
            port: parseInt(selectedConnectionQuery.data.dbPort ?? '8080'),
            database: selectedConnectionQuery.data.dbName ?? '',
            sharedConnection:
               selectedConnectionQuery.data.connectionAccessType === ConnectionAccessType.SHARED,
            hideDetails:
               (selectedConnectionQuery.data.hideDetails as unknown as number) === 1 ? true : false,
            accountName: selectedConnectionQuery.data.dataCredentials?.[0].accountName ?? '',
            password: 'CURRENT',
         };

         reset(formData);
      }
   }, [reset, selectedConnectionQuery.data]);

   useEffect(() => {
      if (
         authPersonQuery.data?.person.role &&
         ADMIN_ROLES.includes(authPersonQuery.data.person.role)
      ) {
         setExplorerIsAdmin(true);
      } else {
         setExplorerIsAdmin(false);
      }
   }, [authPersonQuery.data]);

   useEffect(() => {
      const isDirtyAlt = !!Object.keys(dirtyFields).length;
      if (isDirtyAlt) {
         onSaveStateChange?.('dirty');
      } else {
         onSaveStateChange?.('clean');
      }
   }, [dirtyFields, onSaveStateChange]);

   // Event handlers
   const onSubmitHandler = (data: TrinoDetailFormData) => {
      onSubmit?.(data);
   };

   // Render
   if (selectedConnectionQuery.isLoading || authPersonQuery.isLoading) return <LoadingSpinner />;
   if (authPersonQuery.isError)
      return <LoadingError message={getErrorMessage(authPersonQuery.error)} />;
   if (selectedConnectionQuery.isError)
      return <LoadingError message={getErrorMessage(selectedConnectionQuery.error)} />;

   return (
      <Form id={formId} onSubmit={handleSubmit(onSubmitHandler)}>
         <Form.Group className="mb-3" controlId="connectionName">
            <Form.Label>
               Connection Name <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
               {...register('connectionName')}
               disabled={editType !== 'connection'}
               isInvalid={touchedFields.connectionName && !!errors.connectionName}
               isValid={touchedFields.connectionName && !errors.connectionName}
               placeholder="Connection Name"
               required
            />
            <Form.Control.Feedback type="invalid">
               {errors.connectionName?.message}
            </Form.Control.Feedback>
         </Form.Group>
         <Form.Group className="mb-3" controlId="description">
            <Form.Label>Description</Form.Label>
            <Form.Control
               {...register('description')}
               as="textarea"
               disabled={editType !== 'connection'}
               isInvalid={touchedFields.description && !!errors.description}
               isValid={touchedFields.description && !errors.description}
               placeholder="Description"
               rows={3}
            />
            <Form.Control.Feedback type="invalid">
               {errors.description?.message}
            </Form.Control.Feedback>
         </Form.Group>
         <Form.Group className="mb-3" controlId="host">
            <Form.Label>
               Host <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
               {...register('host')}
               disabled={editType !== 'connection'}
               isInvalid={touchedFields.host && !!errors.host}
               isValid={touchedFields.host && !errors.host}
               placeholder="Host"
               required
            />
            <Form.Control.Feedback type="invalid">{errors.host?.message}</Form.Control.Feedback>
         </Form.Group>
         <Form.Group className="mb-3" controlId="port">
            <Form.Label>
               Port <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
               {...register('port', { valueAsNumber: true })}
               defaultValue="5432"
               disabled={editType !== 'connection'}
               isInvalid={touchedFields.port && !!errors.port}
               isValid={touchedFields.port && !errors.port}
               placeholder="Port"
               required
            />
            <Form.Control.Feedback type="invalid">{errors.port?.message}</Form.Control.Feedback>
         </Form.Group>
         <Form.Group className="mb-3" controlId="database">
            <Form.Label>Database</Form.Label>
            <Form.Control
               {...register('database')}
               disabled={editType !== 'connection'}
               isInvalid={touchedFields.database && !!errors.database}
               isValid={touchedFields.database && !errors.database}
               placeholder="Database"
            />
            <Form.Control.Feedback type="invalid">{errors.database?.message}</Form.Control.Feedback>
         </Form.Group>
         <Form.Group className="mb-3" controlId="accountName">
            <Form.Label>
               User Name <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
               {...register('accountName')}
               disabled={editType === 'read-only'}
               isInvalid={touchedFields.accountName && !!errors.accountName}
               isValid={touchedFields.accountName && !errors.accountName}
               placeholder="User Name"
               required={true}
            />
            <Form.Control.Feedback type="invalid">
               {errors.accountName?.message}
            </Form.Control.Feedback>
         </Form.Group>
         <Form.Group className="mb-3" controlId="password">
            <Form.Label>
               Password <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
               {...register('password')}
               disabled={editType === 'read-only'}
               isInvalid={touchedFields.password && !!errors.password}
               isValid={touchedFields.password && !errors.password}
               placeholder="Password"
               required={true}
               type="password"
            />
            <Form.Control.Feedback type="invalid">{errors.password?.message}</Form.Control.Feedback>
         </Form.Group>
         {explorerIsAdmin && (
            <>
               <Form.Group>
                  <Form.Label>
                     Shared Connection
                     <OverlayTrigger overlay={tooltipSharedConnection} placement="auto">
                        <span>
                           <IconInformation size={16} />
                        </span>
                     </OverlayTrigger>
                  </Form.Label>
                  <Form.Check
                     {...register('sharedConnection')}
                     disabled={editType !== 'connection'}
                     type="switch"
                  />
               </Form.Group>
               <Form.Group className="mb-s">
                  <HideDetailsLabel />
                  <Form.Check
                     {...register('hideDetails')}
                     disabled={editType !== 'connection'}
                     type="switch"
                  />
               </Form.Group>
            </>
         )}
      </Form>
   );
}

export default TrinoDetailForm;
