import React, { useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import ConnectionDetailsForm, {
   connectionDetailsFormId,
} from './ConnectionDetailForm/ConnectionDetailsForm';
import BaseConnectionForm, {
   BaseConnectionFormData,
   baseConnectionFormId,
} from './BaseConnectionForm';
import { DBMS } from '../../../enums';
import { DataConnection, DataCredential } from '../../../entities';
import {
   useNewDataConnectionMutator,
   useNewDataCredentialMutator,
   useTestDataConnectionMutator,
} from '../../../hooks';
import { assertNever, getErrorMessage, handleError } from '../../../utilities';
import SchemaSelectForm, { schemaSelectFormId } from './SchemaSelectForm';

enum ConnWizardPages {
   BASE_CONNECTION,
   CONNECTION_DETAILS,
   SCHEMA_SELECT,
}

function AddConnectionWizard({ onClose, show }: { onClose: () => void; show: boolean }) {
   // State variables
   const [wizardPage, setWizardPage] = useState(ConnWizardPages.BASE_CONNECTION);
   const [activeFormId, setActiveFormId] = useState(baseConnectionFormId);
   const [selectedConnection, setSelectedConnection] = useState<number | undefined>();
   const [selectedDBMS, setSelectedDBMS] = useState<DBMS | undefined>();
   const [formMode, setFormMode] = useState<'create' | 'test'>('create');
   const [serverResult, setServerResult] = useState<
      { message?: string; success: Boolean } | undefined
   >(undefined);

   //Effects
   React.useEffect(() => {
      if (show === true) {
         // reset wizard
         setPage(ConnWizardPages.BASE_CONNECTION);
         setSelectedDBMS(undefined);
         setSelectedConnection(undefined);
         setServerResult(undefined);
      }
   }, [show]);

   //Queries
   const newDataConnectionMutation = useNewDataConnectionMutator({
      onSettledCallback(data, error, variables, context) {
         if (!error && data) {
            setSelectedConnection(data.id);
            setPage(ConnWizardPages.SCHEMA_SELECT);
         } else {
            handleError(error);
         }
      },
   });
   const newCredentialMutation = useNewDataCredentialMutator();
   const testConnectionMutation = useTestDataConnectionMutator({
      onSuccessCallback(data, variables, context) {
         setServerResult(data);
      },
   });

   // Page functions
   const setPage = (page: ConnWizardPages) => {
      setServerResult(undefined);
      setWizardPage(page);
      switch (page) {
         case ConnWizardPages.BASE_CONNECTION:
            setActiveFormId(baseConnectionFormId);
            break;
         case ConnWizardPages.CONNECTION_DETAILS:
            setActiveFormId(connectionDetailsFormId);
            break;
         case ConnWizardPages.SCHEMA_SELECT:
            setActiveFormId(schemaSelectFormId);
            break;
         default:
            assertNever(page);
      }
   };

   const resetWizard = () => {
      setPage(ConnWizardPages.BASE_CONNECTION);
      setSelectedDBMS(undefined);
      setSelectedConnection(undefined);
      setServerResult(undefined);
   };

   function handleBaseConnectionSubmit(data: BaseConnectionFormData): void {
      setSelectedConnection(data.selectedConnection);
      setSelectedDBMS(data.selectedDBMS);
      setPage(ConnWizardPages.CONNECTION_DETAILS);
   }

   const createConnection = async (
      newConnection: DataConnection | undefined,
      newCredential: DataCredential | undefined
   ) => {
      try {
         if (selectedConnection) {
            if (!newCredential) {
               handleError('Missing credential');
               return;
            }

            // save credential to selected connection
            newCredential.dataConnectionId = selectedConnection;
            await newCredentialMutation.mutateAsync({ newCredential });
            onClose();
            resetWizard();
         }

         if (!selectedConnection) {
            // save new data connection
            if (!newConnection) {
               handleError('Missing connection');
               return;
            }
            const connection = await newDataConnectionMutation.mutateAsync(newConnection);
            if (connection?.schemaGenError) {
               setServerResult({
                  success: false,
                  message: `Connection created. Error fetching schema: ${connection.schemaGenError}`,
               });
            }
         }
      } catch (err) {
         setServerResult({ success: false, message: getErrorMessage(err) });
      }
   };

   const testConnection = async (newConnection: DataConnection) => {
      try {
         await testConnectionMutation.mutateAsync(newConnection);
      } catch (err) {
         setServerResult({ success: false, message: 'Could not test connection' });
      }
   };

   async function handleConnDetailsSubmit(
      newConnection: DataConnection | undefined,
      newCredential: DataCredential | undefined
   ) {
      if (formMode === 'create') createConnection(newConnection, newCredential);
      if (formMode === 'test' && newConnection) testConnection(newConnection);
   }

   const handleBackClick = () => {
      switch (wizardPage) {
         case ConnWizardPages.CONNECTION_DETAILS:
            setPage(ConnWizardPages.BASE_CONNECTION);
            break;
      }
   };

   return (
      <Modal centered={true} onHide={onClose} show={show} size="lg">
         <Modal.Body as="span" className="fs-s workspaceDescription">
            {wizardPage === ConnWizardPages.BASE_CONNECTION && (
               <BaseConnectionForm
                  defaultValues={{
                     selectedDBMS: selectedDBMS,
                     selectedConnection: selectedConnection,
                  }}
                  onSubmit={handleBaseConnectionSubmit}
               />
            )}
            {wizardPage === ConnWizardPages.CONNECTION_DETAILS && (
               <ConnectionDetailsForm
                  onSubmit={handleConnDetailsSubmit}
                  selectedConnectionId={selectedConnection}
                  selectedDBMS={selectedDBMS}
               />
            )}
            {wizardPage === ConnWizardPages.SCHEMA_SELECT && selectedConnection && (
               <SchemaSelectForm
                  dataConnectionId={selectedConnection}
                  onSubmit={() => {
                     onClose();
                  }}
               />
            )}
         </Modal.Body>
         <Modal.Footer>
            {wizardPage === ConnWizardPages.BASE_CONNECTION && (
               <Button className="btn btn-sm btn-secondary" onClick={onClose} variant="secondary">
                  Cancel
               </Button>
            )}

            {[ConnWizardPages.CONNECTION_DETAILS].includes(wizardPage) && (
               <Button
                  className="btn btn-sm btn-secondary"
                  onClick={handleBackClick}
                  variant="secondary"
               >
                  Back
               </Button>
            )}
            {wizardPage === ConnWizardPages.CONNECTION_DETAILS && (
               <Button
                  disabled={testConnectionMutation.isLoading}
                  form={activeFormId}
                  onClick={() => setFormMode('test')}
                  size="sm"
                  type="submit"
                  variant="secondary"
               >
                  {testConnectionMutation.isLoading ? <Spinner size="sm" /> : <>Test Connection</>}
               </Button>
            )}
            {[
               ConnWizardPages.BASE_CONNECTION,
               ConnWizardPages.CONNECTION_DETAILS,
               ConnWizardPages.SCHEMA_SELECT,
            ].includes(wizardPage) && (
               <Button
                  className="btn btn-sm btn-primary"
                  disabled={newDataConnectionMutation.isLoading || newCredentialMutation.isLoading}
                  form={activeFormId}
                  onClick={() => setFormMode('create')}
                  type="submit"
                  variant="primary"
               >
                  {newDataConnectionMutation.isLoading || newCredentialMutation.isLoading ? (
                     <Spinner />
                  ) : wizardPage === ConnWizardPages.CONNECTION_DETAILS ? (
                     'Create'
                  ) : wizardPage === ConnWizardPages.SCHEMA_SELECT ? (
                     'Finish'
                  ) : (
                     'Next'
                  )}
               </Button>
            )}
            <div className="col-12">
               {testConnectionMutation.isSuccess && serverResult !== undefined ? (
                  serverResult.success ? (
                     <span className="text-success fs-10p d-flex justify-content-end">
                        Connection Successful
                     </span>
                  ) : (
                     <span>
                        {serverResult?.message !== undefined ? (
                           <div>
                              <span className="text-danger fs-10p d-flex justify-content-end">
                                 {serverResult?.message}
                              </span>
                           </div>
                        ) : (
                           'Unknown error during test'
                        )}
                     </span>
                  )
               ) : (
                  <></>
               )}
            </div>
         </Modal.Footer>
      </Modal>
   );
}

export default AddConnectionWizard;
