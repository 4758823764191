import React from 'react';
import { Tooltip, Form, OverlayTrigger } from 'react-bootstrap';
import { IconInformation } from '../../../../utilities';

const tooltipHideDetails = (
   <Tooltip id="tooltipHideDetails">
      The explorer role can use this connection, but cannot see its details such as the host and
      port.
   </Tooltip>
);

function HideDetailsLabel() {
   return (
      <Form.Label>
         Hide Connection Details
         <OverlayTrigger overlay={tooltipHideDetails} placement="auto">
            <span>
               <IconInformation size={16} />
            </span>
         </OverlayTrigger>
      </Form.Label>
   );
}

export default HideDetailsLabel;
