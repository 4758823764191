import { Tooltip } from 'react-bootstrap';
import LoadingError from '../../../../components/UI/LoadingError';
import LoadingSpinner from '../../../../components/UI/LoadingSpinner';
import {
   ConnectionAccessType,
   ConnectionLanguage,
   DataConnection,
   DataCredential,
   getAllowableEditType,
   SSHAuthenticaionMethod,
   SSLMode,
} from '../../../../entities';
import { DBMS } from '../../../../enums';
import { useGetAuthorizedExplorerQuery, useGetDataConnectionQuery } from '../../../../hooks';
import { assertNever, getErrorMessage } from '../../../../utilities';
import BigQueryDetailForm, { BigQueryDetailsFormData } from './BigQueryDetailForm';
import DatabricksDetailForm, { DatabricksDetailFormData } from './DatabricksDetailForm';
import MongoDetailForm, { MongoDetailFormData } from './MongoDetailForm';
import MSSqlDetailForm, { MSSQLDetailFormData } from './MSSqlDetailForm';
import MySqlDetailForm, { MySQLDetailFormData } from './MySqlDetailForm';
import Neo4jDetailsForm, { Neo4jDetailFormData } from './Neo4jDetailsForm';
import OracleDetailForm, { OracleDetailFormData } from './OracleDetailForm';
import PostgreSqlDetailForm, { PostgreSqlDetailFormData } from './PostgreSqlDetailForm';
import RedshiftDetailForm, { RedshiftDetailFormData } from './RedshiftDetailForm';
import SnowflakeDetailForm, { SnowflakeDetailFormData } from './SnowflakeDetailForm';
import TrinoDetailForm, { TrinoDetailFormData } from './TrinoDetailForm';

export const connectionDetailsFormId = 'connectionDetailsForm';

export const tooltipSharedConnection = (
   <Tooltip id="tooltipSharedConnection">
      Use this for connections that are shared with one set of credentials. This connection cannot
      be edited by others and the credentials for this connection cannot be viewed by others.
   </Tooltip>
);

function ConnectionDetailsForm({
   onSaveStateChange,
   onSubmit,
   selectedConnectionId,
   selectedDBMS,
}: {
   onSaveStateChange?: (state: 'clean' | 'dirty') => void;
   onSubmit?: (
      newConnection: DataConnection | undefined,
      newCredential: DataCredential | undefined
   ) => void;
   selectedConnectionId?: number;
   selectedDBMS?: DBMS;
}) {
   const setCurrentFormState = (state: 'clean' | 'dirty') => {
      onSaveStateChange?.(state);
   };

   // Queries
   const explorerQuery = useGetAuthorizedExplorerQuery();
   const selectedConnectionQuery = useGetDataConnectionQuery({ id: selectedConnectionId });

   const createCredentialObject = async (data: {
      accountName?: string;
      keyFile?: FileList;
      password?: string;
      sshPassword?: string;
      sshUsername?: string;
      useSSH?: boolean;
   }) => {
      const credential: DataCredential = {};
      credential.accountName = data.accountName;
      credential.accountPassword = data.password === 'CURRENT' ? undefined : data.password;
      if (data.useSSH === true) {
         credential.sshUsername = data.sshUsername;
         credential.sshPassword = data.sshPassword === 'CURRENT' ? undefined : data.sshPassword;
      }
      if (data.keyFile && data.keyFile.length > 0) {
         const file = data.keyFile[0];
         credential.accessKeyFile = await file.text();
      }
      credential.dataConnectionId = selectedConnectionId;
      return credential;
   };

   const createDataConnectionObject = (
      data: {
         authSource?: string;
         connectionName: string;
         database?: string;
         description?: string;
         hideDetails?: boolean;
         host?: string;
         language?: ConnectionLanguage;
         port?: number;
         sharedConnection?: boolean;
         snowflakeAccount?: string;
         sshHost?: string;
         sshPort?: number;
         sslCaCert?: FileList;
         sslClientCert?: FileList;
         sslClientKey?: FileList;
         useSSH?: boolean;
         useSSL?: boolean;
         warehouse?: string;
      },
      newCredential?: DataCredential
   ) => {
      const connection: DataConnection = {};
      if (newCredential) {
         connection.dataCredentials = [newCredential];
      } else {
         connection.dataCredentials = [];
      }

      connection.id = selectedConnectionId;
      connection.name = data.connectionName;
      connection.dbms = selectedDBMS;
      connection.description = data.description;
      connection.dbHost = data.host;
      connection.dbPort = data.port?.toString();
      connection.connectionAccessType = data.sharedConnection
         ? ConnectionAccessType.SHARED
         : ConnectionAccessType.CONTROLLED;
      connection.hideDetails = data.hideDetails;
      connection.dbName = data.database;
      connection.snowflakeAccount = data.snowflakeAccount;
      connection.snowflakeWarehouse = data.warehouse;
      connection.authSource = data.authSource;
      connection.language = data.language;

      connection.sslMode = data.useSSL ? SSLMode.VALIDATED : SSLMode.OPTIONAL;
      if (data.sslCaCert) {
         connection.sslCaCert = data.sslCaCert[0];
      }
      if (data.sslClientCert) {
         connection.sslClientCert = data.sslClientCert[0];
      }
      if (data.sslClientKey) {
         connection.sslClientKey = data.sslClientKey[0];
      }

      connection.useSSH = data.useSSH;
      if (data.useSSH === true) {
         connection.sshAuthMethod = SSHAuthenticaionMethod.PASSWORD;
         connection.sshHost = data.sshHost;
         connection.sshPort = data.sshPort?.toString();
      }

      return connection;
   };

   //Page Functions
   async function handleSubmit(
      data:
         | MySQLDetailFormData
         | MSSQLDetailFormData
         | BigQueryDetailsFormData
         | DatabricksDetailFormData
         | Neo4jDetailFormData
         | PostgreSqlDetailFormData
         | RedshiftDetailFormData
         | SnowflakeDetailFormData
         | OracleDetailFormData
         | TrinoDetailFormData
         | MongoDetailFormData
   ): Promise<void> {
      if (onSubmit) {
         let credential: DataCredential | undefined = undefined;
         let connection: DataConnection | undefined = undefined;

         if (data.dbms === DBMS.Databricks) {
            credential = await createCredentialObject({
               password: data.authToken === 'CURRENT' ? undefined : data.authToken,
               accountName: '',
               ...data,
            });
            connection = createDataConnectionObject({ database: data.path, ...data }, credential);
         } else if (data.dbms === DBMS.Big_Query) {
            credential = await createCredentialObject(data);
            connection = createDataConnectionObject(
               { database: data.projectId, ...data },
               credential
            );
         } else {
            credential = await createCredentialObject(data);
            connection = createDataConnectionObject(data, credential);
         }
         onSubmit(connection, credential);
      }
   }

   //Render
   if (selectedConnectionQuery.isLoading || explorerQuery.isLoading) {
      return <LoadingSpinner />;
   }
   if (selectedConnectionQuery.isError) {
      return <LoadingError message={getErrorMessage(selectedConnectionQuery.error)} />;
   }
   if (explorerQuery.isError) {
      return <LoadingError message={getErrorMessage(explorerQuery.error)} />;
   }

   const showDBMSForm = selectedDBMS ?? selectedConnectionQuery.data?.dbms;
   const editType = getAllowableEditType(selectedConnectionQuery.data, explorerQuery.data?.person);

   switch (showDBMSForm) {
      case DBMS.MSSQL:
         return (
            <MSSqlDetailForm
               editType={editType}
               formId={connectionDetailsFormId}
               onSaveStateChange={setCurrentFormState}
               onSubmit={handleSubmit}
               selectedConnectionId={selectedConnectionId}
            />
         );
      case DBMS.Big_Query:
         return (
            <BigQueryDetailForm
               editType={editType}
               formId={connectionDetailsFormId}
               onSaveStateChange={setCurrentFormState}
               onSubmit={handleSubmit}
               selectedConnectionId={selectedConnectionId}
            />
         );
      case DBMS.MySQL:
         return (
            <MySqlDetailForm
               editType={editType}
               formId={connectionDetailsFormId}
               onSaveStateChange={setCurrentFormState}
               onSubmit={handleSubmit}
               selectedConnectionId={selectedConnectionId}
            />
         );
      case DBMS.Databricks:
         return (
            <DatabricksDetailForm
               editType={editType}
               formId={connectionDetailsFormId}
               onSaveStateChange={setCurrentFormState}
               onSubmit={handleSubmit}
               selectedConnectionId={selectedConnectionId}
            />
         );
      case DBMS.Neo4j:
         return (
            <Neo4jDetailsForm
               editType={editType}
               formId={connectionDetailsFormId}
               onSaveStateChange={setCurrentFormState}
               onSubmit={handleSubmit}
               selectedConnectionId={selectedConnectionId}
            />
         );
      case DBMS.Postgres:
         return (
            <PostgreSqlDetailForm
               editType={editType}
               formId={connectionDetailsFormId}
               onSaveStateChange={setCurrentFormState}
               onSubmit={handleSubmit}
               selectedConnectionId={selectedConnectionId}
            />
         );
      case DBMS.Redshift:
         return (
            <RedshiftDetailForm
               editType={editType}
               formId={connectionDetailsFormId}
               onSaveStateChange={setCurrentFormState}
               onSubmit={handleSubmit}
               selectedConnectionId={selectedConnectionId}
            />
         );
      case DBMS.Snowflake:
         return (
            <SnowflakeDetailForm
               editType={editType}
               formId={connectionDetailsFormId}
               onSaveStateChange={setCurrentFormState}
               onSubmit={handleSubmit}
               selectedConnectionId={selectedConnectionId}
            />
         );
      case DBMS.Oracle:
         return (
            <OracleDetailForm
               editType={editType}
               formId={connectionDetailsFormId}
               onSaveStateChange={setCurrentFormState}
               onSubmit={handleSubmit}
               selectedConnectionId={selectedConnectionId}
            />
         );
      case DBMS.Trino:
         return (
            <TrinoDetailForm
               editType={editType}
               formId={connectionDetailsFormId}
               onSaveStateChange={setCurrentFormState}
               onSubmit={handleSubmit}
               selectedConnectionId={selectedConnectionId}
            />
         );
      case DBMS.MongoDB:
         return (
            <MongoDetailForm
               editType={editType}
               formId={connectionDetailsFormId}
               onSaveStateChange={setCurrentFormState}
               onSubmit={handleSubmit}
               selectedConnectionId={selectedConnectionId}
            />
         );
      case DBMS.CHAT_GPT:
      case DBMS.Hugging_Face:
      case DBMS.Federated:
      case DBMS.Python:
      case undefined:
         return <></>;
      default:
         assertNever(showDBMSForm);
   }
}

export default ConnectionDetailsForm;
