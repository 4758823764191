import { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { NewInvite } from '../entities';
import { PersonRole, ROLE_NAMES } from '../enums';
import { useCreateInviteMutator, useGetAuthorizedExplorerQuery } from '../hooks';
import { handleError } from '../utilities';

const AddAccountModal = (props: {
   admin: boolean;
   onClose(): void;
   onSubmitSuccess(): void;
   show: boolean;
}): JSX.Element => {
   //State variables
   const [firstName, setFirstName] = useState<string>('');
   const [lastName, setLastName] = useState<string>('');
   const [email, setEmail] = useState<string>('');
   const [role, setRole] = useState('ORG_EXPLORER');

   //Queries
   const authedExplorerQuery = useGetAuthorizedExplorerQuery();
   const authedExplorer = authedExplorerQuery.data;

   //Mutators
   const newInviteMutator = useCreateInviteMutator({
      onSuccessCallback(newInvite, variables, context) {
         resetForm();
         props.onSubmitSuccess();
      },
   });

   //Page Functions
   const resetForm = () => {
      setEmail('');
      setRole('ORG_EXPLORER');
   };

   const formSubmit = async () => {
      //TODO: form validation
      const newInvite: NewInvite = {
         email: email,
         firstName: firstName.length > 0 ? firstName : undefined,
         lastName: lastName.length > 0 ? lastName : undefined,
         role: PersonRole[role as keyof typeof PersonRole],
      };

      try {
         await newInviteMutator.mutateAsync(newInvite);
         toast.success(`An invite has been sent to ${email}`);
      } catch (err) {
         handleError(err);
      }
   };

   return (
      <Form>
         <Modal centered={true} onHide={() => props.onClose()} show={props.show}>
            <Modal.Header className="border-0" closeButton={true}>
               Add Account
            </Modal.Header>
            <Modal.Body as="span" className="fs-s workspaceDescription">
               <Form.Group className="mb-3">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                     onChange={(event) => setFirstName(event.currentTarget.value)}
                     placeholder="First Name"
                     required={false}
                     value={firstName}
                  />
               </Form.Group>
               <Form.Group className="mb-3">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                     onChange={(event) => setLastName(event.currentTarget.value)}
                     placeholder="Last Name"
                     required={false}
                     value={lastName}
                  />
               </Form.Group>
               <Form.Group className="mb-3">
                  <Form.Label>
                     Email <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                     onChange={(event) => setEmail(event.currentTarget.value)}
                     placeholder="Email"
                     required={true}
                     value={email}
                  />
               </Form.Group>
               {authedExplorer?.person.role === PersonRole.ORG_ADMIN ? (
                  <Form.Group className="mb-3">
                     <Form.Label>
                        Role <span className="text-danger">*</span>
                     </Form.Label>
                     <Form.Select
                        aria-label="Role Select"
                        onChange={(event) => {
                           setRole(event.target.value);
                        }}
                        value={role}
                     >
                        {Object.entries(PersonRole)
                           .filter((role) => props.admin)
                           .map((role) => (
                              <option key={role[0]} value={role[0]}>
                                 {ROLE_NAMES[role[1]]}
                              </option>
                           ))}
                     </Form.Select>
                  </Form.Group>
               ) : (
                  <></>
               )}
            </Modal.Body>
            <Modal.Footer>
               <Button
                  className="btn btn-sm btn-secondary"
                  onClick={() => {
                     props.onClose();
                     resetForm();
                  }}
                  variant="secondary"
               >
                  Cancel
               </Button>
               <Button
                  className="btn btn-sm btn-primary"
                  disabled={email.length === 0}
                  onClick={formSubmit}
                  variant="primary"
               >
                  Create
               </Button>
            </Modal.Footer>
         </Modal>
      </Form>
   );
};

export default AddAccountModal;
