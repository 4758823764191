import { inject, injectable } from 'inversify';
import { DataCredential } from '../entities/DataCredentials';
import { TYPES } from '../types';
import { ApiService } from './ApiService';
import { ApiServiceInterface } from './ApiServiceInterface';

const path = '/v1/dataCredential';

export interface listOptionsDataCredential {
   includeConnection?: boolean;
   personId?: number;
}

@injectable()
export class DataCredentialService implements ApiServiceInterface<DataCredential> {
   private apiService: ApiService;

   constructor(@inject(TYPES.apiService) apiService: ApiService) {
      this.apiService = apiService;
   }

   async delete(id: string | number): Promise<null> {
      return await this.apiService.delete(`${path}/${id}`);
   }

   get(id: string | number): Promise<DataCredential | undefined> {
      throw new Error('Method not implemented.');
   }

   async list(params: Record<string, string>): Promise<DataCredential[]> {
      const result = await this.apiService.get<DataCredential[]>(path, params);
      if (!result) {
         return [];
      }

      return result;
   }

   listOptions(filter?: listOptionsDataCredential): Promise<DataCredential[]> {
      const params: Record<string, string> = {};

      if (filter?.personId) {
         if (filter.personId > 0) {
            params['personId'] = filter.personId.toString();
         } else {
            throw new Error('personId must be positive if defined');
         }
      }

      if (filter?.includeConnection !== undefined) {
         params['includeConnection'] = filter.includeConnection.toString();
      }

      return this.list(params);
   }

   patch(
      id: string | number,
      body: DataCredential,
      params?: Record<string, string> | undefined
   ): Promise<DataCredential | undefined> {
      throw new Error('Method not implemented.');
   }

   async post(
      body: DataCredential,
      params?: Record<string, string> | undefined
   ): Promise<DataCredential | undefined> {
      const result = await this.apiService.post<DataCredential>(`${path}`, body, params);
      if (!result) {
         return undefined;
      }

      return result;
   }

   async put(
      _body: DataCredential,
      _params?: Record<string, string> | undefined
   ): Promise<DataCredential | undefined> {
      throw new Error('Method not implemented.');
   }
}
