import { useContext, createContext } from 'react';

import { ExploreTab } from '../entities';

export type ExploreTabContextType = {
   exploreTab?: ExploreTab | undefined;
};

export const ExploreTabContext = createContext<ExploreTabContextType>({});

export const useExploreTab = (): ExploreTab | undefined => {
   return useContext(ExploreTabContext).exploreTab;
};

export const useExploreTabContext = (): ExploreTabContextType => {
   return useContext(ExploreTabContext);
};
