import { QueryBuilder } from './QueryBuilder';

export class BigQueryQueryBuilder extends QueryBuilder {
   public beginTransaction(): string {
      return '';
   }

   public endTransaction(): string {
      return '';
   }
}
