// must match SERVICE_PLANS in api/src/plan.ts
export const planNames = ['Free', 'Professional', 'Business', 'Enterprise'];

export interface ServicePlan {
   id: number;
   maxWorkflowSteps: number | undefined;
   maxWorkflows: number | undefined;
   name: string;
}

export const SERVICE_PLANS: ServicePlan[] = [
   {
      id: 0,
      name: 'Free',
      maxWorkflows: 2,
      maxWorkflowSteps: 2,
   },
   {
      id: 1,
      name: 'Professional',
      maxWorkflows: undefined,
      maxWorkflowSteps: undefined,
   },
   {
      id: 2,
      name: 'Business',
      maxWorkflows: undefined,
      maxWorkflowSteps: undefined,
   },
   {
      id: 3,
      name: 'Enterprise',
      maxWorkflows: undefined,
      maxWorkflowSteps: undefined,
   },
];
