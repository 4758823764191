import { memo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Button, Form, Modal } from 'react-bootstrap';
import { DataCredential } from '../entities';
import { QueryKeyType } from '../enums';
import { getWorkspaceConnectionQueryKey, useNewDataCredentialMutator } from '../hooks/entities';

export const DataCredentialsModal = memo(
   (props: {
      connectionId: number | undefined;
      connectionName?: string;
      handleClose?(): void;
      querySavedResultsId?: number;
      show: boolean;
      workspaceId?: number;
   }): JSX.Element => {
      const [accountName, setAccountName] = useState('');
      const [password, setPassword] = useState('');

      // Hooks
      const queryClient = useQueryClient();
      const newCredentialMutator = useNewDataCredentialMutator({
         onSuccess(_newCredential, _variables, _context) {
            queryClient.invalidateQueries(
               getWorkspaceConnectionQueryKey({ type: QueryKeyType.LIST })
            );
         },
      });

      // Page functions
      const saveNewCredential = async () => {
         const newCredential: DataCredential = {};
         newCredential.accountName = accountName;
         newCredential.accountPassword = password;
         newCredential.dataConnectionId = props.connectionId;
         await newCredentialMutator.mutateAsync({
            newCredential: newCredential,
            workspaceId: props.workspaceId,
            querySavedResultsId: props.querySavedResultsId,
         });
         props.handleClose && props.handleClose();
      };

      if (props.connectionId === undefined) {
         return <></>;
      }

      return (
         <Modal
            centered
            onHide={() => {
               props.handleClose && props.handleClose();
               setAccountName('');
               setPassword('');
            }}
            show={props.show}
         >
            <Modal.Header className="border-0" closeButton>
               <Modal.Title as="span" className="fs-4 workspaceName">
                  {props.connectionName ?? 'Data source'} credentials required
               </Modal.Title>
            </Modal.Header>
            <Modal.Body as="span" className="fs-s workspaceDescription">
               <Form>
                  <Form.Group className="mb-3">
                     <Form.Label>Account name</Form.Label>
                     <Form.Control
                        onChange={(event) => setAccountName(event.currentTarget.value)}
                        placeholder="Account name"
                        required
                        type="text"
                        value={accountName}
                     />
                  </Form.Group>
                  <Form.Group className="mb-3">
                     <Form.Label>Password</Form.Label>
                     <Form.Control
                        onChange={(event) => setPassword(event.currentTarget.value)}
                        placeholder="Password"
                        type="password"
                        value={password}
                     />
                  </Form.Group>
               </Form>
            </Modal.Body>
            <Modal.Footer className="border-0">
               <Button
                  className="btn btn-sm btn-secondary"
                  onClick={() => {
                     props.handleClose && props.handleClose();
                     setAccountName('');
                     setPassword('');
                  }}
                  variant="secondary"
               >
                  Cancel
               </Button>
               <Button
                  className="btn btn-sm btn-primary"
                  disabled={accountName.length === 0 && password.length === 0}
                  onClick={saveNewCredential}
                  variant="primary"
               >
                  Save
               </Button>
            </Modal.Footer>
         </Modal>
      );
   }
);

export default DataCredentialsModal;
