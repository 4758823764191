import { useInjection } from 'inversify-react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { TreeNode } from '../../components';
import { DataConnection, SchemaCache } from '../../entities';
import { QueryKey, QueryKeyType } from '../../enums';
import {
   DataConnectionGetOptions,
   DataConnectionListOptions,
   DataConnectionSchemaGetOptions,
   DataConnectionService,
   UpdateSchemaVisibilityPayload,
} from '../../services';
import { TYPES } from '../../types';
import { handleError, notUndefined } from '../../utilities';

const useDataConnectionService = () => {
   return useInjection<DataConnectionService>(TYPES.dataConnectionService);
};

export const useListDataConnectionsQuery = (
   filters?: DataConnectionListOptions,
   callbacks?: {
      errorCallback?: (error: unknown) => void;
      successCallback?: (data: DataConnection[]) => void;
   },
   enabled?: boolean
) => {
   const dataConnectionService = useDataConnectionService();
   return useQuery<DataConnection[]>(
      getDataConnectionQueryKey({ type: QueryKeyType.LIST, filters: filters }),
      () => dataConnectionService.listOptions(filters),
      {
         enabled: enabled,
         keepPreviousData: true,
         refetchOnWindowFocus: false,
         refetchOnMount: true,
         retry: false,
         onSuccess: callbacks?.successCallback,
         onError: callbacks?.errorCallback,
      }
   );
};

export const useGetDataConnectionQuery = ({
   getOptions,
   id,
   queryOptions,
   callbacks,
}: {
   callbacks?: { onSuccess?: (data: DataConnection | undefined) => void };
   getOptions?: DataConnectionGetOptions;
   id: number | undefined;
   queryOptions?: {
      enabled?: boolean;
   };
}) => {
   const dataConnectionService = useInjection<DataConnectionService>(TYPES.dataConnectionService);
   return useQuery<DataConnection | undefined>(
      getDataConnectionQueryKey({ type: QueryKeyType.GET, id: id, filters: getOptions }),
      () => dataConnectionService.getOptions(id, getOptions),
      {
         keepPreviousData: true,
         refetchOnWindowFocus: false,
         refetchOnMount: true,
         retry: false,
         staleTime: 10,
         enabled: !!id && (queryOptions?.enabled ?? true),
         onError(err) {
            handleError(err);
         },
         onSuccess: callbacks?.onSuccess,
      }
   );
};

export const useGetDataConnectionSchema = (
   dataConnectionId: number | undefined,
   getOptions?: DataConnectionSchemaGetOptions
) => {
   const service = useDataConnectionService();
   return useQuery<SchemaCache[]>(
      [QueryKey.SchemaForDataSource, dataConnectionId],
      () => service.getSchemaCache(dataConnectionId, getOptions),
      {
         keepPreviousData: true,
         refetchOnWindowFocus: false,
         refetchOnMount: true,
         retry: false,
      }
   );
};

export const useGetDataConnectionSchemaTree = ({
   dataConnectionId,
   callbacks,
   refetchInterval,
   viewAll,
}: {
   callbacks?: {
      onSuccess: (data: TreeNode[]) => void;
   };
   dataConnectionId: number | undefined;
   refetchInterval?: number;
   viewAll?: boolean;
}) => {
   const service = useDataConnectionService();
   return useQuery<TreeNode[]>(
      [QueryKey.SchemaTreeForDataSource, dataConnectionId],
      () => service.getTreeSchema(dataConnectionId, viewAll),
      {
         keepPreviousData: true,
         refetchOnWindowFocus: false,
         refetchOnMount: true,
         refetchInterval: refetchInterval ? refetchInterval : false,
         retry: false,
         onSuccess: callbacks?.onSuccess,
      }
   );
};

export const useDataConnection = (id: number | undefined) => {
   const query = useGetDataConnectionQuery({ id: id });
   return query.data;
};

export const useDbms = (dataConnectionId: number | undefined) => {
   const dataConnection = useDataConnection(dataConnectionId);
   return dataConnection?.dbms;
};

export const useNewDataConnectionMutator = (callbacks?: {
   onSettledCallback?: (
      data: DataConnection | undefined,
      error: unknown,
      variables: DataConnection,
      context: unknown
   ) => void;
}) => {
   const queryClient = useQueryClient();
   const dataConnectionService = useDataConnectionService();
   return useMutation({
      mutationFn: async (newConnection: DataConnection) => {
         return await dataConnectionService.post(newConnection);
      },
      onSuccess: async (newConnection) => {
         await queryClient.invalidateQueries(
            getDataConnectionQueryKey({ type: QueryKeyType.LIST })
         );
      },
      onSettled(data, error, variables, context) {
         if (callbacks?.onSettledCallback) {
            callbacks.onSettledCallback(data, error, variables, context);
         }
      },
   });
};

export const useUpdateDataConnectionMutator = (callbacks?: {
   onSuccessCallback?: (
      data: DataConnection | undefined,
      variables: DataConnection,
      context: unknown
   ) => void;
}) => {
   const queryClient = useQueryClient();
   const dataConnectionService = useDataConnectionService();
   return useMutation({
      mutationFn: async (updatedConnection: DataConnection) => {
         return dataConnectionService.patch(notUndefined(updatedConnection.id), updatedConnection);
      },
      async onSuccess(data, variables, context) {
         if (callbacks?.onSuccessCallback) callbacks.onSuccessCallback(data, variables, context);
         if (data) {
            const promises = [
               queryClient.invalidateQueries(
                  getDataConnectionQueryKey({ type: QueryKeyType.GET, id: notUndefined(data.id) })
               ),
               queryClient.invalidateQueries(
                  getDataConnectionQueryKey({ type: QueryKeyType.LIST })
               ),
            ];

            await Promise.all(promises);
         }
      },
      onError: handleError,
   });
};

export const useUpdateSchemaVisibilityMutator = () => {
   const queryClient = useQueryClient();
   const dataConnectionService = useDataConnectionService();
   return useMutation({
      mutationFn: async (vars: {
         body: UpdateSchemaVisibilityPayload;
         dataConnectionId: number;
      }) => {
         return dataConnectionService.updateSchemaVisibility(vars.dataConnectionId, vars.body);
      },
      async onSuccess(data, variables, context) {
         // Invalid caches
         queryClient.invalidateQueries([
            QueryKey.SchemaTreeForDataSource,
            variables.dataConnectionId,
         ]);
      },
      onError: handleError,
   });
};

export const useDeleteDataConnectionMutator = () => {
   const queryClient = useQueryClient();
   const dataConnectionService = useDataConnectionService();
   return useMutation({
      mutationFn: async (id: number) => {
         return dataConnectionService.delete(id);
      },
      async onSuccess(data, id, context) {
         const promises = [
            queryClient.invalidateQueries(
               getDataConnectionQueryKey({ type: QueryKeyType.GET, id: id })
            ),
            queryClient.invalidateQueries(getDataConnectionQueryKey({ type: QueryKeyType.LIST })),
         ];
         await Promise.all(promises);
      },
      onError: handleError,
   });
};

export const useTestDataConnectionMutator = (callbacks?: {
   onSuccessCallback?: (
      data: { message?: string; success: Boolean },
      variables: DataConnection,
      context: unknown
   ) => void;
}) => {
   const dataConnectionService = useDataConnectionService();
   return useMutation({
      mutationFn: async (connection: DataConnection) => {
         return dataConnectionService.testConnection(connection);
      },
      onSuccess(data, variables, context) {
         if (callbacks?.onSuccessCallback) callbacks.onSuccessCallback(data, variables, context);
      },
   });
};

export const useReTestDataConnectionMutator = ({
   callbacks = {},
}: {
   callbacks?: {
      onSuccess?: (
         data: {
            message?: string | undefined;
            success: Boolean;
         },
         variables: number | DataConnection,
         context: unknown
      ) => void;
   };
}) => {
   const dataConnectionService = useDataConnectionService();
   return useMutation({
      mutationFn: async (connection: DataConnection) => {
         return dataConnectionService.retestConnection(connection);
      },
      onSuccess: callbacks?.onSuccess,
   });
};

export const useUpdateSchemaMutation = (callbacks?: {
   onErrorCallback?: (
      error: unknown,
      variables: {
         catalogName?: string;
         dataConnectionId: number;
         schemaName?: string;
         tableName?: string;
      },
      context: unknown
   ) => void;
   onSuccessCallback?: (
      data: TreeNode[] | undefined,
      variables: {
         catalogName?: string;
         dataConnectionId: number;
         schemaName?: string;
         tableName?: string;
      },
      context: unknown
   ) => void;
}) => {
   const queryClient = useQueryClient();
   const dataConnectionService = useInjection<DataConnectionService>(TYPES.dataConnectionService);

   return useMutation<
      TreeNode[] | undefined, // Return type of mutation function
      unknown, // Error type
      { catalogName?: string; dataConnectionId: number; schemaName?: string; tableName?: string } // Variables type (with optional catalogName, tableName, and schemaName)
   >({
      mutationFn: async ({ catalogName, dataConnectionId, tableName, schemaName }) => {
         return dataConnectionService.updateSchema(
            dataConnectionId,
            catalogName,
            tableName,
            schemaName
         );
      },
      onSuccess: async (data, variables, context) => {
         if (callbacks?.onSuccessCallback) {
            callbacks.onSuccessCallback(data, variables, context);
         }
         if (data !== undefined) {
            await Promise.all([
               queryClient.invalidateQueries([
                  QueryKey.SchemaForDataSource,
                  variables.dataConnectionId,
               ]),
               queryClient.invalidateQueries([
                  QueryKey.SchemaTreeForDataSource,
                  variables.dataConnectionId,
               ]),
            ]);
         }
      },
      onError: (error, variables, context) => {
         if (callbacks?.onErrorCallback) {
            callbacks.onErrorCallback(error, variables, context);
         }
      },
   });
};

export interface RunDataConnectionQueryBody {
   exploreTabId?: number | undefined;
   queries: {
      query: string;
      querySavedId?: number | undefined;
   }[];
   workspaceId?: number | undefined;
}

export function getDataConnectionQueryKey(keyParams: {
   filters?: DataConnectionListOptions | DataConnectionGetOptions;
   id?: number;
   type: QueryKeyType;
}): any[] {
   const queryKey: any[] = [QueryKey.DataConnection, keyParams.type];
   if (keyParams.id !== undefined) {
      queryKey.push(keyParams.id);
   }

   if (keyParams.filters !== undefined) {
      queryKey.push(keyParams.filters);
   }

   return queryKey;
}
