import { Link } from 'react-router-dom';
import { Col, Row, Card } from 'react-bootstrap';
import { Workspace } from '../../entities';
import { getShortDateString, notUndefined } from '../../utilities';
import CollaboratorIcon from '../../components/UI/CollaboratorIcon';
import { useQuery } from 'react-query';
import { QueryKey } from '../../enums';
import { QueryLogService } from '../../services';
import { useInjection } from 'inversify-react';
import { TYPES } from '../../types';

const WorkspaceCard = ({ workspace }: { workspace: Workspace }): JSX.Element => {
   const logService = useInjection<QueryLogService>(TYPES.querylogService);
   const userHasRunQueriesHere = useQuery(
      [QueryKey.QueryLog, 'has run', workspace.id],
      async () => {
         return await logService.hasRun(workspace.id ?? -1);
      },
      {
         retry: false,
      }
   );
   return (
      <Link
         className="text-decoration-none"
         to={`/workspaces/${notUndefined(workspace.id).toString()}`}
      >
         <Card className="other-cards card border-0 bg-secondary py-2 px-3 mb-2">
            <Row className="justify-content-center">
               <Col xs={4}>
                  <span className="fs-14p fw-semibold">
                     {workspace.name}
                     {userHasRunQueriesHere.isSuccess && !userHasRunQueriesHere.data && ' [NEW]'}
                  </span>
               </Col>
               <Col xs={3}>
                  {workspace.dataSources
                     ?.filter((c) => c.type === 'database')
                     ?.map((c) => (
                        <span
                           className={`badge bg-secondary queryDatabaseSource me-1 fs-10p`}
                           key={c.value}
                        >
                           {c.value}
                        </span>
                     ))}
               </Col>
               <Col xs={1}>
                  <span className="fs-10p text-body">{workspace.savedQueryCount}</span>
               </Col>
               <Col className="img-rounded-small d-flex align-items-center flex-wrap" xs={2}>
                  {workspace.workspaceCollaborators?.map((collaborator) => (
                     <CollaboratorIcon
                        firstName={
                           collaborator.person?.firstName ?? collaborator.person?.email ?? ''
                        }
                        key={collaborator.id}
                        lastName={collaborator.person?.lastName ?? ''}
                     />
                  ))}
               </Col>
               <Col xs={2}>
                  <span className="fs-10p text-body">{getShortDateString(workspace.created)}</span>
               </Col>
            </Row>
            <Row className="justify-content-center">
               <Col className="workspace-card-description" xs={12}>
                  <span className="fs-12p text-body">{workspace.description}</span>
               </Col>
            </Row>
         </Card>
      </Link>
   );
};

export default WorkspaceCard;
