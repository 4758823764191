import { useState } from 'react';
import { useInjection } from 'inversify-react';
import { useQuery } from 'react-query';
import { Navigate, useParams } from 'react-router-dom';

import { ErrorPage, LoadingSpinner, Page } from '../../components';
import { Workspace, WorkspaceStatus } from '../../entities';
import { QueryKey } from '../../enums';
import { WorkspaceContextProvider, CurrentStep } from '../../hooks';
import { WorkspaceService } from '../../services';
import { TYPES } from '../../types';
import { handleError } from '../../utilities';
import Explore from './Explore/Explore';

export enum historyTabs {
   QueryLog = 'query-log',
   Stats = 'stats',
   WorkspaceLog = 'workspace-log',
}

export const WorkspacePage = (): JSX.Element => {
   const workspaceService = useInjection<WorkspaceService>(TYPES.workspaceService);
   const params = useParams();
   const workspaceId = Number(params.workspaceId);
   const [currentStep, setCurrentStep] = useState<CurrentStep>();

   const workspaceFilters = {
      savedQueryCount: true,
      includeOrgPeople: true,
      connectionDetails: true,
      includeCollaborators: true,
   };
   const workspaceGetKey = [QueryKey.Workspace, 'get', workspaceId, workspaceFilters];
   const workspaceQuery = useQuery<Workspace | undefined>(
      workspaceGetKey,
      () =>
         Number.isNaN(workspaceId)
            ? undefined
            : workspaceService.getOptions(workspaceId, workspaceFilters),
      {
         keepPreviousData: true,
         refetchOnWindowFocus: false,
         refetchOnMount: true,
         refetchOnReconnect: false,
         retry: false,
         onError(err) {
            handleError(err);
         },
      }
   );

   if (workspaceId === undefined || Number.isNaN(workspaceId)) {
      return <Navigate replace to="/" />;
   } else if (workspaceQuery.isLoading) {
      return <LoadingSpinner />;
   } else if (
      WorkspaceStatus.DELETED ||
      workspaceQuery.isError ||
      workspaceQuery.data === undefined
   ) {
      return <ErrorPage />;
   } else {
      return (
         <WorkspaceContextProvider
            value={{ currentStep, setCurrentStep, workspace: workspaceQuery.data }}
         >
            <Page bodyPadding={false}>
               <Explore workspaceId={workspaceId} />
            </Page>
         </WorkspaceContextProvider>
      );
   }
};
export default WorkspacePage;
